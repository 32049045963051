<!--
Versions history
15/01/2021
    - added onKeyUp event, reset value if it invalid date
05/01/2021
    - fixed manual input
03/12/2020
    - added 'blur' event to the MaskedInput instaed of 'input'

Description
 this component sends either object with start/end dates properties or a string if value has been typed in manually

 TODO: will be more convenient if it will emit events with object in all cases
-->
<template>
  <div>
    <date-range-picker
      :time-picker="showTime"
      :min-date="minDate"
      :max-date="maxDate"
      :opens="dateRange.options.opens"
      :locale-data="dateRange.options.locale"
      :single-date-picker="singleDatePicker"
      :auto-apply="dateRange.options.autoApply"
      :show-dropdowns="true"
      :ranges="ranges"
      :class="[singleDatePicker ? 'picker-single' : 'picker-multiple']"
      :linked-calendars="dateRange.options.linkedCalendars"
      @update="update"
      @toggle="onToggle"
      @select="onSelect"
      v-model="selected.period"
    >
      <!-- @input="onInput($event,$event,true)" -->
      <masked-input
        v-if="!singleDatePicker"
        :mask="mask"
        placeholder="yyyy-mm-dd - yyyy-mm-dd"
        ref="picker-input"
        slot="input"
        class="form-control picker-input-multiple"
        v-model="dateRange.input.value"
        @blur="onInputBlur"
        @key-up="onKeyUp"
      />
      <masked-input
        v-if="singleDatePicker"
        :mask="mask"
        :placeholder="format"
        ref="picker-input"
        slot="input"
        class="form-control picker-input-single"
        v-model="dateRange.input.value"
        @blur="onInputBlur"
        @key-up="onKeyUp"
      />
    </date-range-picker>
  </div>
</template>

<script>
import moment from 'moment'

//import MaskedInput from "vue-masked-input";
import MaskedInput from '@/components/MaskedInput'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker,
    MaskedInput
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    defaultRange: {
      type: String,
      default: 'This quarter'
      //default: 'This year'
      //see getRange for all periods
    },
    showTime: {
      type: Boolean,
      default: false
    },

    allowEmpty: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    singleDatePicker: {
      type: Boolean,
      default: false
    },
    ranges: {
      type: [Boolean, Object],
      default () {
        return {
          Today: [moment(), moment()],
          'Next week': [
            moment()
              .add(1, 'week')
              .startOf('isoWeek'),
            moment()
              .add(1, 'week')
              .endOf('isoWeek')
          ],
          'This week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
          'Last week': [
            moment()
              .subtract(1, 'week')
              .startOf('isoWeek'),
            moment()
              .subtract(1, 'week')
              .endOf('isoWeek')
          ],
          'This month': [moment().startOf('month'), moment().endOf('month')],
          'Last month': [
            moment()
              .subtract(1, 'month')
              .startOf('month'),
            moment()
              .subtract(1, 'month')
              .endOf('month')
          ],
          'Last 30 days': [moment().subtract(30, 'day'), moment()],

          'This quarter': [
            moment()
              .quarter(moment().quarter())
              .startOf('quarter'),
            moment()
              .quarter(moment().quarter())
              .endOf('quarter')
          ],
          'Last quarter': [
            moment()
              .subtract(1, 'quarter')
              .startOf('quarter'),
            moment()
              .subtract(1, 'quarter')
              .endOf('quarter')
          ],
          'This year full': [moment().startOf('year'), moment().endOf('year')],
          'Year to date': [moment().startOf('year'), moment()],
          'Last 12 months': [moment().subtract(12, 'month'), moment()],
          'Last calendar year': [
            moment()
              .subtract(1, 'year')
              .startOf('year'),
            moment()
              .subtract(1, 'year')
              .endOf('year')
          ],
          'Last 10 years': [
            moment()
              .subtract(10, 'year')
              .startOf('year'),
            moment()
          ]
        }
      }
    },
    value: {
      type: [Object, String],
      default: () => {}
    }
  },
  data: function () {
    return {
      debug: false,
      opened: false,
      selected: {
        period: {
          /*
          startDate: this.getRange(this.defaultRange).start,
          endDate: this.getRange(this.defaultRange).end,
          changed: false,
          */
          startDate: undefined,
          endDate: undefined,
          changed: false
        }
      },
      valueChangedManually: false,
      dateRange: {
        value: {},
        options: {
          opens: 'right',
          //singleDatePicker: false,
          autoApply: true,
          linkedCalendars: true,
          locale: {
            direction: 'ltr', //direction of text
            format: this.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD', //format of the dates displayed
            separator: ' - ', //separator between the two ranges
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: moment.weekdaysMin(), //array of days - see moment documentations for details
            monthNames: moment.monthsShort(), //array of month names - see moment documentations for details
            firstDay: 1 //ISO first day of week - see moment documentations for details
          }
        },
        input: {
          value: ''
        }
      }
    }
  },
  computed: {
    format () {
      //  return this.showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"
      return this.dateRange.options.locale.format
    },
    mask () {
      let m = ''
      if (this.singleDatePicker) {
        m = this.showTime ? '1111-11-11 11:11' : '1111-11-11'
      } else {
        m = this.showTime
          ? '1111-11-11 11:11 - 1111-11-11 11:11'
          : '1111-11-11 - 1111-11-11'
      }

      return m
    }
  },
  created: function () {},
  mounted: function () {
    let p = {
      startDate: this.getRange(this.defaultRange).start,
      endDate: this.getRange(this.defaultRange).end,
      changed: false
    }

    /*
            value can be equal to string date like 'yyyy-mm-dd' OR object {startDate: '', endDate: ''} OR empty string/object to use default period
        */
    /*
            //if single date -and not empty use value
            if (typeof this.value === "string" && this.value !== "") {
                p = { startDate: this.value, endDate: this.value}
            } else {

              //if empty object or empty string use default period or this.value

              p = ["{}", '""',undefined].includes(JSON.stringify(this.value)) ? this.selected.period : this.value;

            }*/

    //v-model value

    //console.log(JSON.stringify(p))

    //this.dateRange.value = p;

    if (typeof this.value === 'string') {
      p = {
        startDate: this.value,
        endDate: this.value
      }
    } else {
      //p = this.value ? this.value : this.selected.period
      p = this.value ? this.value : p
    }

    this.selected.period = p

    !this.debug || console.log('DP mounted', JSON.parse(JSON.stringify(p)))

    if (this.singleDatePicker) {
      this.dateRange.input.value = `${p.startDate}`
    } else {
      //  this.dateRange.input.value = this.value ? this.value : `${this.selected.period.startDate} - ${this.selected.period.endDate}`
      this.dateRange.input.value = `${p.startDate} - ${p.endDate}`
    }

    //send event with default values

    //problem! - this event onmount replaces custom values with default
    //this.$emit('input', this.singleDatePicker ? this.dateRange.input.value : this.selected.period)

    this.$emit(
      'mounted',
      this.singleDatePicker ? this.dateRange.input.value : this.selected.period
    )
  },

  methods: {
    setDefault () {
      this.selected = {
        period: {
          startDate: this.getRange(this.defaultRange).start,
          endDate: this.getRange(this.defaultRange).end,
          changed: false
        }
      }

      if (this.defaultRange == 'Empty') {
        this.selected.period = {}
      }

      this.update(this.selected.period, {
        triggerChanged: false, //value won't be saved to storage
        timeZoneChange: false
      })
    },
    onSelect (e) {
      console.log('DP onSelect', e)
    },
    //used for inline editing and lost focus event catching
    onToggle (opened) {
      this.opened = opened
      /*
                        !this.debug || console.log('DP toggle. Opened=',opened)

                        if (!opened) {

                            let value = this.dateRange.input.value

                            !this.debug || console.log('DP toggle.onInput')

                            this.onInput(value, value, true)
                        }
            */
    },
    //have to be used for updating value from the external components
    setValue (
      value,
      events = {
        triggerChanged: false, //do not trigger change event, so filtering panel won't save value to the storage
        timeZoneChange: false //timezone should not be changed if value comes from external call
      }
    ) {
      !this.debug ||
        console.log('DP setValue', JSON.parse(JSON.stringify(value)))

      //set default values if not exists
      if (!('triggerChanged' in events)) events.triggerChanged = false
      if (!('timeZoneChange' in events)) events.timeZoneChange = false

      this.update(value, events)
    },
    //used by native datetimepicker @update event so component have to send the change event and tz have to be converted too
    update (
      value,
      events = {
        triggerChanged: true,
        timeZoneChange: true
      }
    ) {
      !this.debug || console.log('DP update', JSON.parse(JSON.stringify(value)))

      if (JSON.stringify(this.selected.period) !== '{}')
        this.selected.period.changed = true

      //20201021, allowEmpty values
      if (!this.$helpers.isEmptyObject(value)) {
        if (!events.timeZoneChange || !this.showTime) {
          value.startDate = moment(value.startDate).format(this.format)
          value.endDate = moment(value.endDate).format(this.format)
        } else {
          //  value.startDate = moment.tz(moment(value.startDate), moment.tz.guess()).format(this.format)
          //  value.endDate = moment.tz(moment(value.endDate), moment.tz.guess()).format(this.format)
          value.startDate = moment
            .tz(value.startDate, moment.tz.guess())
            .format(this.format)
          value.endDate = moment
            .tz(value.endDate, moment.tz.guess())
            .format(this.format)
        }
      }

      //added 2020-02-22, #165
      this.selected.period = value

      if (this.singleDatePicker) {
        this.dateRange.input.value = `${this.selected.period.startDate}`
      } else {
        // this.dateRange.input.value = `${this.selected.period.startDate} - ${this.selected.period.endDate}`;

        //20201021, allowEmpty values
        this.dateRange.input.value = this.selected.period.startDate
          ? `${this.selected.period.startDate} - ${this.selected.period.endDate}`
          : ''
      }

      // this event allows to use v-model in parent components
      this.$emit(
        'input',
        this.singleDatePicker
          ? this.dateRange.input.value
          : this.selected.period
      )

      //input event run every time when value changes even during component creating
      if (events.triggerChanged) {
        //change event is using to save value to vuex storage
        this.$emit(
          'change',
          this.singleDatePicker
            ? this.dateRange.input.value
            : this.selected.period
        )
      }
    },

    onInputBlur (value) {
      !this.debug || console.log('DP.onInputBlur. Opened:', this.opened)

      //if (!this.opened || !this.showTime){
      if (this.valueChangedManually) {
        this.valueChangedManually = false

        this.onInput(value, value, true)
      }

      return
    },
    onKeyUp (value) {
      !this.debug || console.log(`onKeyUp, value: [${value}]`)

      this.valueChangedManually = true
    },
    /*
        onTextInput(value){

            console.log('onTextInput',value)
            this.valueChangedManually = true


        },*/

    onInput (value, value2, toggleEvent = false) {
      !this.debug || console.log(`onInput, value: [${value}]`)

      //20201022
      //this event is launched very first! before component will be mounted, it is causing premature 'change' event trigerring and wrong .
      //values are storing into Vuex. as a result, when filtering panel has several Datetime pickers then just last one has value from the
      //Vuex, and others show default values and ignoring values in Vuex
      if (!value) {
        toggleEvent = false

        !this.debug ||
          console.log('DateTimePickerCustom, !value, toggleEvent set to false!')
      }

      !this.debug ||
        console.log(
          `DP input: allowEmpty=${this.allowEmpty}, toggleEvent=${toggleEvent},value=${value},value2=${value2}`
        )

      //console.log('this.singleDatePicker,this.allowEmpty,this.showTime ', this.singleDatePicker,this.allowEmpty,this.showTime)

      //if (!toggleEvent) {
      //20201020
      //if (!this.allowEmpty && !toggleEvent) {

      if (!this.allowEmpty) {
        //console.log('1')

        if (!this.singleDatePicker)
          if (value2.includes('_'))
            //if (value2.replace("_", "").length !== 16) return;
            return

        if (this.singleDatePicker) {
          //console.log('2')
          if (!this.showTime) {
            // if (value2.replace("_", "").length !== 8) return;
            if (value2.includes('_')) return
          }

          if (this.showTime) {
            //console.log('2.2')

            //if (value2.replace(" ","").replace("_", "").replace(":", "").length !== 12) return;

            if (value2.replace(/[\s_:-]/gi, '').length < 12) return
          }
        }
      }

      if (this.singleDatePicker) {
        const date = moment(value)

        if (!date.isValid()) {
          !this.debug ||
            console.log('DP input: Invalid date value. Reset to empty', value)

          //20211501
          //return
          value = ''
        }
      }

      let period = this.stringToPeriod(value)

      this.selected.period = period
      this.dateRange.value = period

      //this.$emit("update", period);
      // this event allow to use v-model in parent components
      this.$emit('input', this.singleDatePicker ? value : period)

      //change event is using to save value to vuex storage
      //20201022
      if (toggleEvent) {
        !this.debug || console.log('DateTimePickerCustom, emitted change event')

        this.$emit(
          'change',
          this.singleDatePicker
            ? this.dateRange.input.value
            : this.selected.period
        )
      }
    },
    stringToPeriod (str) {
      if (str.includes('_') || !str)
        return {
          startDate: undefined,
          endDate: undefined
        }

      let p = str.split(' - ')

      if (p.length === 2 && !this.singleDatePicker) {
        let startDate = moment(p[0].trim())

        let endDate = moment(p[1].trim())

        if (startDate.isValid() && endDate.isValid())
          return {
            startDate: startDate.format(this.format),
            endDate: endDate.format(this.format)
          }
      }

      if (this.singleDatePicker) {
        let startDate = moment(str.trim())

        if (startDate.isValid())
          return {
            startDate: startDate.format(this.format),
            endDate: startDate.format(this.format)
          }
      }

      //return today by default
      return {
        startDate: moment().format(this.format),
        endDate: moment().format(this.format)
      }
    },
    getRange: function (value) {
      let range = {}

      if (value === 'Today') {
        range.start = moment()
        range.end = moment()
      }

      if (value === 'This week') {
        range.start = moment().startOf('isoWeek')
        range.end = moment().endOf('isoWeek')
      }

      if (value === 'Last week') {
        range.start = moment()
          .subtract(1, 'week')
          .startOf('isoWeek')
        range.end = moment()
          .subtract(1, 'week')
          .endOf('isoWeek')
      }

      if (value === 'This month') {
        range.start = moment().startOf('month')
        range.end = moment().endOf('month')
      }

      if (value === 'Last 30 days') {
        range.start = moment().subtract(30, 'day')
        range.end = moment()
      }

      if (value === 'This quarter') {
        range.start = moment()
          .quarter(moment().quarter())
          .startOf('quarter')
        range.end = moment()
          .quarter(moment().quarter())
          .endOf('quarter')
      }

      if (value === 'Last quarter') {
        range.start = moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
        range.end = moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
      }

      if (value === 'Last 90 days') {
        range.start = moment().subtract(90, 'day')
        range.end = moment()
      }

      if (value === 'This year') {
        range.start = moment().startOf('year')
        range.end = moment().endOf('month')
      }

      if (value === 'Last 12 months') {
        range.start = moment().subtract(12, 'month')
        range.end = moment()
      }

      if (value === 'This year full') {
        range.start = moment().startOf('year')
        range.end = moment().endOf('year')
      }

      if (value === 'Last 5 years') {
        range.start = moment()
          .year(
            moment()
              .subtract(5, 'year')
              .year()
          )
          .startOf('year')
        range.end = moment()
      }

      //-10 years
      if (value === 'Last 10 years') {
        range.start = moment()
          .year(
            moment()
              .subtract(10, 'year')
              .year()
          )
          .startOf('year')
        range.end = moment()
      }

      //-5 +2 years
      if (value === '7 years') {
        range.start = moment()
          .year(
            moment()
              .subtract(5, 'year')
              .year()
          )
          .startOf('year')
        range.end = moment()
          .year(
            moment()
              .add(2, 'year')
              .year()
          )
          .endOf('year')
      }

      if (value === 'Empty') {
        range.start = undefined
        range.end = undefined
      }

      let result = {
        start: range.start
          ? range.start.format(this.format ? this.format : 'YYYY-MM-DD')
          : '',
        end: range.end
          ? range.end.format(this.format ? this.format : 'YYYY-MM-DD')
          : ''
      }

      return result
    }
  },
  watch: {
    'selected.period': function () {
      // console.log("watch.selected.period", JSON.stringify(newVal));
    }
  }
}
</script>

<style>
.picker-single {
  width: 150px;
}

.picker-multiple {
  width: 230px;
}

.reportrange-text {
  min-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 5px;
  border: 1px solid #e8e8e8 !important;
  background: #fff;
  font-size: 14px;
  width: 100% !important;
}

.vue-daterange-picker {
  position: relative;
  display: block !important;
}

.picker-input-single {
  width: 140px !important;
  margin: -4px;
  border-width: 0px;
  border: none;
  outline: none;
}

.picker-input-multiple {
  width: 220px !important;
  margin: -4px;
  border-width: 0px;
  border: none;
  outline: none;
}

input:active,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

@media screen and (min-width: 768px) {
  .daterangepicker.show-ranges[data-v-8cc9549e] {
    min-width: 690px;
  }
}
</style>
