import {
  PROFILE_REQUEST,
  PROFILE_ERROR,
  PROFILE_SUCCESS
} from "../actions/profile";
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";

import { api } from "@/shared/api.js";

const storage =
  process.env.VUE_APP_STORAGE === "session"
    ? window.sessionStorage
    : window.localStorage;

const state = { status: "", data: {} };

const getters = {
  // getProfile: state => state.profile,
  // isProfileLoaded: state => !!state.profile.name,
};

const actions = {
  fetchProfile: ({ commit, dispatch }) => {
    commit(PROFILE_REQUEST);

    return api
      .post(`user/profile`)
      .then(resp => {
        commit(PROFILE_SUCCESS, resp);
      })
      .catch(() => {
        commit(PROFILE_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
  // eslint-disable-next-line no-use-before-define
  updateProfile: async user => {
    await api.put(`user/update`, user);
  },
  fetchProfileSettings: async () => {
    console.log("fetchProfileSettings");
    let res = await api.get("user/profile/settings");
    return res;
  },
  saveProfileSettings: async () => {
    console.log("saveProfileSettings");
    let vx = storage.getItem("vuex");

    await api.put("user/profile/settings", { vuex: vx });

    return;
  }
};

const mutations = {
  [PROFILE_REQUEST]: state => {
    state.status = "loading";
  },
  [PROFILE_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "data", resp);
  },
  [PROFILE_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.data = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
