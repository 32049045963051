import { MODULES } from "../shared/constants";
import { ifAuthenticated } from "./helpers";

const KnowledgeBase = () => import("@/views/KnowledgeBase/KnowledgeBase");
const Synchronization = () => import("@/views/KnowledgeBase/Synchronization");

export default {
  path: "",
  name: "Knowledge base",
  meta: { icon: "icon-info" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/knowledge-base/knowledge-base",
      name: "Knowledge base",
      meta: { icon: "icon-info", module: MODULES.KnowledgeBase },
      component: KnowledgeBase,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/knowledge-base/synchronization",
      name: "Synchronization",
      meta: { icon: "icon-settings", module: MODULES.KnowledgeBase },
      component: Synchronization,
      beforeEnter: ifAuthenticated
    }
  ]
};
