import Vue from "vue";

const state = {
  territories: [],
  countries: [],
  states: [],
  cities: [],
  accounts: [],
  products: [],
  categories: [],
  accountpremises: [],
  period: "",
  accountname: "",
  chain: "",
  user: ""
};

const getters = {
  "filterPanel.selected.territories": state => state.territories,
  "filterPanel.selected.countries": state => state.countries,
  "filterPanel.selected.states": state => state.states,
  "filterPanel.selected.cities": state => state.cities,
  "filterPanel.selected.state": state => {
    return state.states.length ? state.states[0] : "";
  },
  "filterPanel.selected.accounts": state => state.accounts,
  "filterPanel.selected.products": state => state.products,
  "filterPanel.selected.categories": state => state.acccategoriesounts,
  "filterPanel.selected.accountpremises": state => state.accountpremises,
  "filterPanel.selected.period": state => state.period,
  "filterPanel.selected.accountname": state => state.accountname,
  "filterPanel.selected.chain": state => state.chain,
  "filterPanel.selected.user": state => state.user
};

const actions = {
  "filterPanel.selected.territories": ({ commit }, data) => {
    commit("filterPanel.selected.territories", data);
  },
  "filterPanel.selected.countries": ({ commit }, data) => {
    commit("filterPanel.selected.countries", data);
  },
  "filterPanel.selected.states": ({ commit }, data) => {
    commit("filterPanel.selected.states", data);
  },
  "filterPanel.selected.cities": ({ commit }, data) => {
    commit("filterPanel.selected.cities", data);
  },
  "filterPanel.selected.state": ({ commit }, data) => {
    commit("filterPanel.selected.state", data);
  },
  "filterPanel.selected.accounts": ({ commit }, data) => {
    commit("filterPanel.selected.accounts", data);
  },
  "filterPanel.selected.products": ({ commit }, data) => {
    commit("filterPanel.selected.products", data);
  },
  "filterPanel.selected.categories": ({ commit }, data) => {
    commit("filterPanel.selected.categories", data);
  },
  "filterPanel.selected.accountpremises": ({ commit }, data) => {
    commit("filterPanel.selected.accountpremises", data);
  },
  "filterPanel.selected.period": ({ commit }, data) => {
    commit("filterPanel.selected.period", data);
  },
  "filterPanel.selected.accountname": ({ commit }, data) => {
    commit("filterPanel.selected.accountname", data);
  },
  "filterPanel.selected.chain": ({ commit }, data) => {
    commit("filterPanel.selected.chain", data);
  },
  "filterPanel.selected.user": ({ commit }, data) => {
    commit("filterPanel.selected.user", data);
  }
};

const mutations = {
  "filterPanel.selected.territories": (state, data) => {
    Vue.set(state, "territories", data);
  },
  "filterPanel.selected.countries": (state, data) => {
    Vue.set(state, "countries", data);
  },
  "filterPanel.selected.states": (state, data) => {
    Vue.set(state, "states", data);
  },
  "filterPanel.selected.cities": (state, data) => {
    Vue.set(state, "cities", data);
  },
  "filterPanel.selected.state": (state, data) => {
    Vue.set(state, "states", [data]);
  },
  "filterPanel.selected.accounts": (state, data) => {
    Vue.set(state, "accounts", data);
  },
  "filterPanel.selected.products": (state, data) => {
    Vue.set(state, "products", data);
  },
  "filterPanel.selected.categories": (state, data) => {
    Vue.set(state, "categories", data);
  },
  "filterPanel.selected.accountpremises": (state, data) => {
    Vue.set(state, "accountpremises", data);
  },
  "filterPanel.selected.period": (state, data) => {
    Vue.set(state, "period", data);
  },
  "filterPanel.selected.accountname": (state, data) => {
    Vue.set(state, "accountname", data);
  },
  "filterPanel.selected.chain": (state, data) => {
    Vue.set(state, "chain", data);
  },
  "filterPanel.selected.user": (state, data) => {
    Vue.set(state, "user", data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
