const state = {
  title: "",
  message: "",
  type: "",
  status: "",
  show: false
};

const getters = {
  getMessage: state => {
    state.message;
  },
  getAlertClass: state => "alert-" + state.type,
  getStatus: state => state.status
};

const mutations = {
  SET_MESSAGE(state, { title, message, type }) {
    state.title = title;
    state.message = message;
    state.type = type;
  },

  REMOVE_MESSAGE(state) {
    state.message = "";
    state.type = "";
  },
  SET_STATUS(state, status) {
    state.status = status;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
