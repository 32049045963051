export default {
  entity(name) {
    let _that = this;

    if (!_that) return;

    let e = _that.model.entities.find(i => i.name == name);

    if (!e) {
      console.error(`Entity '${name}' is not defined`);
      return;
    }

    let entity = Object.assign({}, e);

    entity.readonly =
      !(
        _that.model.actions.Edit &&
        _that.model.actions.Edit.validator(_that.data)
      ) || entity.readonly;

    return entity;
  },
  parseJSON(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  },
  parseJSONArray(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  },

  getEmptyEntitiesObject(entities) {
    let data = {};

    entities.forEach(e => {
      let value = undefined;

      if (e.type === "dropdown" && !e.multiple) value = { id: "", value: "" };

      if (e.type === "dropdown" && e.multiple) value = [];

      if (e.type === "period") value = "";

      if (e.type === "string") value = "";

      if (e.type === "number") value = 0;

      if (Object.prototype.hasOwnProperty.call(e, "defaultValue"))
        data[e.name] = e.defaultValue;
      else data[e.name] = value;
    });

    return data;
  },
  getEmptyEntitiesObjectFlatten(entities) {
    let data = {};

    entities.forEach(e => {
      let value = "";

      if (e.type === "dropdown") {
        data[`${e.name}_ID`] = "";
        data[`${e.name}`] = "";
        if (Object.prototype.hasOwnProperty.call(e, "defaultValue")) {
          data[`${e.name}_ID`] = e.defaultValue.id;
          data[`${e.name}`] = e.defaultValue.label;
        }
      } else {
        if (e.type === "period") value = "";

        if (e.type === "string") value = "";

        if (e.type === "number") value = 0;

        if (Object.prototype.hasOwnProperty.call(e, "defaultValue"))
          data[e.name] = e.defaultValue;
        else data[e.name] = value;
      }
    });

    return data;
  }
};
