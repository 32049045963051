import { ifAuthenticated } from './helpers'
import { MODULES } from '@/shared/constants'

export default {
  name: 'Track time',
  path: '',
  meta: { icon: 'icon-clock' },
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/track-time/my-time',
      name: 'My time',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/MyTime.vue'),
      beforeEnter: ifAuthenticated,
      children: []
    },
    {
      path: '/track-time/my-sick-time',
      name: 'My sick time',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/MySickTime.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/my-vacation-time',
      name: 'My vacation time',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/MyVacationTime.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/leave-balances',
      name: 'Leave Balances',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/LeaveBalances.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/vacation-requests',
      name: 'Vacation Requests',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/VacationRequests.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/all-users-time',
      name: 'All time',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/AllTime.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/important-dates',
      name: 'Important dates',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/ImportantDates.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/shift-templates',
      name: 'Shift templates',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/ShiftTemplates.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/shift/:action/:id?',
      name: 'Shift template submission',
      meta: {
        icon: 'icon-list',
        module: MODULES.Tracktime,
        hideFromMenu: true
      },
      component: () =>
        import('@/views/TrackTime/ShiftTemplateSubmissionForm.vue'),
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/schedule-shifts',
      name: 'Schedule / Shifts',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/ScheduleShifts.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/track-time/active-users',
      name: 'Active users',
      meta: { icon: 'icon-list', module: MODULES.Tracktime },
      component: () => import('@/views/TrackTime/ActiveUsers.vue'),
      beforeEnter: ifAuthenticated
    }
  ]
}
