const historyLen = 5;

const state = {
  routerHistory: [],
  notAuthRequest: ""
};

const getters = {
  routerHistory: state => state.routerHistory,
  previousRoute: state => {
    const historyLen = state.routerHistory.length;
    if (historyLen < 2) return null;

    return JSON.parse(state.routerHistory[historyLen - 2]);
  },
  //getNotAuthRequest: state => state.notAuthRequest
  getNotAuthRequest: () => localStorage.getItem("notAuthRequest") || ""
};

const actions = {
  addHistory({ commit }, payload) {
    commit("ADD_HISTORY", payload);
  },
  saveNotAuthRequest({ commit }, payload) {
    commit("SAVE_NOT_AUTH_REQUEST", payload);
  },
  deleteNotAuthRequest({ commit }, payload) {
    commit("DELETE_NOT_AUTH_REQUEST", payload);
  }
};

const mutations = {
  ADD_HISTORY: (state, payload) => {
    let payload_str = JSON.stringify(payload);

    let array = state.routerHistory;

    //let { [array.length - 1]: last } = array;

    array = array.filter(
      item => JSON.parse(item).name !== JSON.parse(payload_str).name
    );
    array.push(payload_str);
    if (array.length > historyLen)
      array = array.slice(array.length - historyLen);
    /*
    //do not duplicate last route
    if (last !== payload_str) array.push(payload_str);
    if (array.length > 5) array = array.slice(array.length - 5);
*/
    state.routerHistory = array;
  },
  SAVE_NOT_AUTH_REQUEST: (state, payload) => {
    //state.notAuthRequest = payload;
    localStorage.setItem("notAuthRequest", payload);
  },
  DELETE_NOT_AUTH_REQUEST: () => {
    //    state.notAuthRequest = "";
    localStorage.removeItem("notAuthRequest");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
