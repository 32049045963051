<template>
  <div class="form-group">
    <div>
      <label class="col-form-label" :for="id" v-if="!hideLabel">{{
        label
      }}</label>
    </div>

    <input
      :id="id"
      v-if="!isEditMode"
      :readonly="readonly ? readonly : !isEditMode"
      type="text"
      :class="['form-control', readonly ? 'readonly' : '']"
      :value="valueAsString"
      @dblclick="dblclickField($event)"
    />

    <vue-timepicker
      :id="id"
      v-if="isEditMode"
      class="form-control"
      :minute-interval="15"
      v-model="timePicker.value"
      @change="updateTimePicker"
      :format="format"
      lazy
    />

    <div v-if="isInvalid && isEditMode" class="invalid-feedback d-block">
      {{ label }} is required
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

import { FORMCONTROLMODE } from "@/shared/constants";

export default {
  components: {
    VueTimepicker
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: "text"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    mode: {
      type: Number,
      default: FORMCONTROLMODE.VIEW
    },
    required: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: "HH:mm"
    }
  },
  data: function() {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isEditMode: false,
      isChanged: false,
      isInvalid: false,
      timePicker: {
        value: {
          HH: null,
          mm: null
        }
      }
    };
  },
  computed: {
    valueAsString() {
      return this.timePicker.value
        ? `${this.timePicker.value.HH}:${this.timePicker.value.mm}`
        : "";
    }
  },
  created: function() {},
  mounted: function() {
    this.timePicker.value = this.parseTime(this.value);

    this.isEditMode =
      (this.mode === FORMCONTROLMODE.EDIT ||
        this.mode === FORMCONTROLMODE.CREATE) &&
      !this.readonly;
  },

  methods: {
    parseTime(value) {
      if (!value) return;

      let t = value.split(":");
      return {
        HH: t[0],
        mm: t[1]
      };
    },
    updateTimePicker(e) {
      if (this.value !== e.displayTime) {
        this.isChanged = true;
      }

      //this.timePicker.value = this.parseTime(e.displayTime);

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = false;
      }

      let id = this.id.replace(/timepicker:/g, "");

      if (this.isChanged) {
        this.$emit("changed", id, this.valueAsString, this.mode);

        //v-model
        this.$emit("input", this.valueAsString);
      }
    },
    dblclickField() {
      //let s = this.timePicker.value;
      if (this.isInvalid) return;
      if (this.readonly) return;

      //let id = e.currentTarget.id.replace(/timepicker:/g, "");

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode;
      }
    }
  },
  watch: {
    value(newVal) {
      this.timePicker.value = this.parseTime(newVal);
    }
  }
};
</script>

<style scoped>
.edit,
.edit:focus {
  background-color: #ffffed;
}

.readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

::v-deep .vue__time-picker {
  width: 100%;
}
::v-deep .vue__time-picker input.display-time {
  border: 0px solid #d2d2d2;

  height: 2.2em;
  margin: -0.3em -0.5em;
}

::v-deep ::-webkit-scrollbar {
  width: 0px;
}
</style>
