import Store from "../store";
import router from "@/router";
import CryptoJS from "crypto-js";

export function encryptPermissions(data) {
  return CryptoJS.AES.encrypt(data, process.env.VUE_APP_SECRET_KEY).toString();
}

export function decryptPermissions(data) {
  let bytes = CryptoJS.AES.decrypt(data, process.env.VUE_APP_SECRET_KEY);

  let decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
}

//append to obj1 data from obj2
export function mergePermissions(obj1, obj2) {
  let result = {};

  //pages
  result = obj1;

  for (let i = 0; i < obj2.length; i++) {
    let o2 = obj2[i];

    //check if role has same route as user
    let idx = result.findIndex(p => p.route_name === o2.route_name);
    //if not found - add
    if (idx < 0) {
      result.push(o2);
    } else {
      //use users permissions if both exist
      result[idx] = o2;
    }
  }

  return result;
}

export function routePermitted(route) {
  //if (["/", "/home"].includes(route.path)) return true;

  let data = Store.getters["permissions/getData"];

  if (!data) return false;

  for (let i = 0; i < data.length; i++) {
    let path = data[i].path;

    //replace parameters [like :action and etc] in the router with /.* for regexp test
    if (path.includes(":")) {
      let i = path.indexOf(":");
      path = path.substr(0, i) + ".*";
    }

    let x = new RegExp(`^${path}$`);

    //  console.log(path, route.path, x.test(route.path));

    if (x.test(route.path)) return true;
  }

  return false;
}

export function permitted(el) {
  let result = { visible: false, read: false, write: false };
  //let result = { visible: true, read: true, write: true, }

  let data = Store.getters["permissions/getData"];

  if (!data.length) return result;

  let route_name = this.$route.name;

  let currentRoute = data.find(item => item.route_name === route_name);

  if (!currentRoute) return result;

  let element = getElementPermissions(currentRoute, el);

  if (!this.$_.isEmpty(element)) {
    result = {
      visible:
        element.visible ||
        !this.$_.isNil(element.read) ||
        !this.$_.isNil(element.write)
          ? true
          : false,
      read: element.read,
      write: element.write
    };
  }

  return result;
}

function getElementPermissions(currentNode, id) {
  var i, currentChild, result;

  if (id == currentNode.id) {
    return currentNode;
  } else {
    if (!currentNode.component_permissions) return false;

    for (i = 0; i < currentNode.component_permissions.length; i += 1) {
      currentChild = currentNode.component_permissions[i];

      result = getElementPermissions(currentChild, id);

      if (result !== false) {
        return result;
      }
    }

    return false;
  }
}

export function buildMenuFromRouter(
  menu,
  parentPath,
  items,
  showHiddenRoutes = false
) {
  items.forEach(item => {
    //if (item.meta && item.meta.isMenuItem) {
    if (
      item.meta &&
      (!item.meta.hideFromMenu || (showHiddenRoutes && item.name))
    ) {
      //if (true) {
      let path = parentPath ? `${parentPath}/${item.path}` : `${item.path}`;

      if (item.children && item.children.length > 0) {
        let m = {
          name: item.name,
          url: path,
          children: []
        };
        if (item.meta && item.meta.icon) m.icon = item.meta.icon;
        if (item.meta && item.meta.title) m.title = true;

        buildMenuFromRouter(m.children, path, item.children, showHiddenRoutes);

        if (!m.children.length) delete m.children;

        menu.push(m);
      } else {
        let m = {
          name: item.name,
          url: path,
          icon: item.meta ? item.meta.icon : ""
        };

        if (item.meta && item.meta.icon) m.icon = item.meta.icon;
        if (item.meta && item.meta.title) m.title = true;

        //console.log("m", `[${parentPath}]`, JSON.stringify(m));

        menu.push(m);
      }
    }
  });

  return menu;
}

export function restrictMenu(menu) {
  let result = [];

  let data = Store.getters["permissions/getData"];

  if (!Store.getters.isAuthenticated && !data.menu) return result;

  if (Store.getters.isAuthenticated && !data.menu) {
    console.log("Logged in but permissions are not valid!");

    return result;
  }

  //process top level
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].name && data.menu.includes(menu[i].name)) {
      //remove children which are not listed in data array
      if (menu[i].children) {
        menu[i].children = menu[i].children.filter(item =>
          data.menu.includes(item.name)
        );
      }
      result.push(menu[i]);
    }
  }

  return result;
}

export function restrictMenu2(menu) {
  let result = [];

  let data = Store.getters["permissions/getData"];

  if (!Store.getters.isAuthenticated && !data) return result;

  if (Store.getters.isAuthenticated && !data) {
    console.log("Logged in but permissions are not valid!");

    return result;
  }

  //process top level
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].name && data.find(d => d.route_name === menu[i].name)) {
      //remove children which are not listed in data array

      if (menu[i].children) {
        menu[i].children = menu[i].children.filter(item =>
          data.find(d => d.route_name === item.name)
        );
      }

      result.push(menu[i]);
    }
  }

  return result;
}

export function restrictedMenu() {
  let menu = buildMenuFromRouter([], "", router.options.routes[2].children);

  menu = restrictMenu2(menu);

  return menu;
}
