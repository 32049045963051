import Vue from "vue";

const state = {
  filter: "",
  parent: "",
  description: "",
  method: "",
  formatColumns: []
};

const getters = {
  //'drilldown.filter': state => JSON.parse(state.filter),
  "drilldown.filter": state => state.filter,
  "drilldown.parent": state => state.parent,
  "drilldown.description": state => state.description,
  "drilldown.method": state => state.method
};

const actions = {
  "drilldown.save": ({ commit }, data) => {
    commit("drilldown.save", data);
  }
};

const mutations = {
  "drilldown.save": (state, data) => {
    Vue.set(state, "filter", data.filter);
    Vue.set(state, "parent", data.parent);
    Vue.set(state, "description", data.description);
    Vue.set(state, "method", data.method);
    Vue.set(state, "formatColumns", data.formatColumns);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
