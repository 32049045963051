import Vue from 'vue'
import axios from 'axios'

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

//eslint-disable-next-line
Vue.config.errorHandler = function(err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+

  // Create a custom error object
  const customError = {
    url: window.location.href,
    message: err.message,
    source: 'frontend',
    name: err.name,
    file_name: window.location.href + ' < ' + document.referrer,
    context: 'Info: ' + JSON.stringify(info) + ' Stack: ' + err.stack,
    level: 'ERROR'
  }

  console.log('main.[Global Error Handler].customError: ', customError)

  //eslint-disable-next-line
  Vue.prototype.$api.put(`logs`, customError).catch(error => {
    console.error('Error calling API:', error)
  })
}

Vue.config.warnHandler = function (msg, vm, trace) {
  // handle warning
  // `trace` is the component hierarchy trace
  // Create a custom warning object
  const customWarning = {
    url: window.location.href,
    message: msg,
    source: 'frontend',
    file_name: window.location.href + ' < ' + document.referrer,
    context: 'Trace: ' + JSON.stringify(trace),
    level: 'WARNING'
  }

  console.log('main.[Global Warning Handler].customWarning: ', customWarning)

  //eslint-disable-next-line
  Vue.prototype.$api.put(`logs`, customWarning).catch(error => {
    console.error('Error calling API:', error)
  })
}

//eslint-disable-next-line
window.onerror = function(msg, url, line, col, error) {
  //code to handle or report error goes here
  console.log('main.window.onerror', msg)
}

//eslint-disable-next-line
window.addEventListener('unhandledrejection', function(event) {
  //handle error here
  //event.promise contains the promise object
  //event.reason contains the reason for the rejection
  console.log('main.unhandledrejection', event)

  const customWarning = {
    url: window.location.href,
    message: JSON.stringify(event.reason),
    source: 'frontend',
    file_name: window.location.href + ' < ' + document.referrer,
    context: event?.reason?.stack,
    level: 'ERROR'
  }

  console.log(
    'main.[Global Warning Handler].unhandledrejection: ',
    customWarning
  )

  //eslint-disable-next-line
  Vue.prototype.$api.put(`logs`, customWarning).catch(error => {
    console.error('Error calling API:', error)
  })
})
