var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group"
  }, [_c('div', [!_vm.hideLabel ? _c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.id
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()]), _vm.readonly || !_vm.isEditMode ? _c('input', {
    class: ['form-control', _vm.readonly ? 'readonly' : ''],
    attrs: {
      "id": _vm.id,
      "readonly": _vm.readonly ? _vm.readonly : !_vm.isEditMode,
      "type": "text"
    },
    domProps: {
      "value": _vm.valueSingleAsString
    },
    on: {
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      },
      "blur": function blur($event) {
        return _vm.onInputBlur($event);
      }
    }
  }) : _vm._e(), _vm.isEditMode && !_vm.readonly ? _c('date-range-picker-custom', {
    ref: _vm.id,
    attrs: {
      "id": _vm.id,
      "ranges": {},
      "single-date-picker": _vm.dateRangePicker.options.singleDatePicker,
      "show-time": _vm.dateRangePicker.options.showTime
    },
    on: {
      "change": _vm.updateDateRangePicker
    },
    model: {
      value: _vm.dateRangePicker.value,
      callback: function callback($$v) {
        _vm.$set(_vm.dateRangePicker, "value", $$v);
      },
      expression: "dateRangePicker.value"
    }
  }) : _vm._e(), _vm.isInvalid && _vm.isEditMode ? _c('div', {
    staticClass: "invalid-feedback d-block"
  }, [_vm._v(" " + _vm._s(_vm.label) + " is required ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }