<template>
  <div>
    <inline-input
      :id="`input:${$helpers.str2_(properties.name)}`"
      :value="value"
      :label="properties.name"
      :mode="mode"
      :required="properties.required"
      :readonly="properties.readonly"
      :is-text-area="properties.isTextArea"
      :rows="3"
      @changed="onChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },

    properties: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    mode() {
      return this.$form.mode(this);
    }
  },
  created() {},
  methods: {
    onChange(id, value) {
      this.$emit("input", value);

      this.$emit("change", { id, value });
    }
  }
};
</script>

<style></style>
