import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const Receipts = () => import("@/views/CompanyCardReceipts/Receipts.vue");
const ReceiptSubmission = () =>
  import("@/views/CompanyCardReceipts/ReceiptSubmission.vue");

export default {
  path: "",
  name: "Company card receipts",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/company-card-receipts/my-receipts",
      name: "My Receipts",
      component: Receipts,
      props: { mode: "my-receipts" },
      meta: { module: MODULES.Receipts, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/company-card-receipts/all-receipts",
      name: "All receipts",
      component: Receipts,
      props: { mode: "all-receipts" },
      meta: { module: MODULES.Receipts, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/company-card-receipts/receipt/:action/:id?",
      name: "Receipt submission",
      component: ReceiptSubmission,
      props: true,
      meta: {
        module: MODULES.Receipts,
        hideFromMenu: true
      },
      beforeEnter: ifAuthenticated
    }
  ]
};
