var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('inline-input', {
    attrs: {
      "id": "input:".concat(_vm.$helpers.str2_(_vm.properties.name)),
      "value": _vm.value,
      "label": _vm.properties.name,
      "mode": _vm.mode,
      "required": _vm.properties.required,
      "readonly": _vm.properties.readonly,
      "is-text-area": _vm.properties.isTextArea,
      "rows": 3
    },
    on: {
      "changed": _vm.onChange
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }