import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const HistoricalActivity = () =>
  import("@/views/AccountTouchpoints/HistoricalActivity");
const Activities = () => import("@/views/AccountTouchpoints/Activities");
const ActivitySubmissionForm = () =>
  import("@/views/AccountTouchpoints/ActivitySubmissionForm");
const BatchActivitySubmissionWizard = () =>
  import("@/views/AccountTouchpoints/BatchActivitySubmissionWizard");

export default {
  path: "",
  name: "Account touchpoints",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/account-touchpoints/historical-activity",
      name: "Historical activity",
      meta: { icon: "icon-chart", module: MODULES.Activities },
      component: HistoricalActivity,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/account-touchpoints/my-activities",
      name: "My activities",
      component: Activities,
      beforeEnter: ifAuthenticated,
      props: { filter: "my" },
      meta: {
        module: MODULES.Activities,
        icon: "icon-speech"
      }
    },
    {
      path: "/account-touchpoints/all-activities",
      name: "All activities",
      meta: {
        module: MODULES.Activities,
        icon: "icon-list"
      },
      component: Activities,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/account-touchpoints/review-activities",
      name: "Review activities",
      component: Activities,
      props: { filter: "review" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Activities, icon: "icon-list" }
    },
    {
      path: "/account-touchpoints/tasting-report",
      name: "Tasting report",
      component: Activities,
      props: { filter: "tasting" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Activities, icon: "icon-list" }
    },
    {
      path: "/account-touchpoints/activity/:action/:id?",
      name: "Activity submission",
      component: ActivitySubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Activities, hideFromMenu: true }
    },

    {
      path: "/account-touchpoints/activity/submission-batch",
      name: "Batch activity submission",
      component: BatchActivitySubmissionWizard,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Activities, hideFromMenu: true }
    }
  ]
};
