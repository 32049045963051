import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";

import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

const state = {
  status: "",
  data: {}
};

const getters = {
  getData: state => state.data,
  getFullName: state => state.data.first_name + " " + state.data.last_name
};

const actions = {
  fetchUser: ({ commit, dispatch }, id) => {
    commit(USER_REQUEST);

    return axios
      .post(`${apiUrl}/user/${id}`)
      .then(resp => {
        commit(USER_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
  updateUser: async ({ commit }, user) => {
    let url = user.id ? `${apiUrl}/user/update` : `${apiUrl}/user/create`;

    await axios
      .put(url, user)
      .then(resp => {
        commit(
          "modal/SET_MESSAGE",
          { title: "message", message: resp.data.message, type: "info" },
          { root: true }
        );

        commit(USER_SUCCESS, user);
      })
      .catch(resp => {
        commit(
          "modal/SET_MESSAGE",
          { title: "message", message: resp.data.message, type: "info" },
          { root: true }
        );

        commit(USER_ERROR);
      });
  },

  disableUser: async ({ commit }, user) => {
    axios
      .put(`${apiUrl}/user/disable`, user)
      .then(() => {
        user.deleted = 1;
        commit(USER_SUCCESS, user);
      })
      .catch(() => {
        commit(USER_ERROR);
      });
  },

  enableUser: async ({ commit }, user) => {
    axios
      .put(`${apiUrl}/user/enable`, user)
      .then(() => {
        user.deleted = 1;
        commit(USER_SUCCESS, user);
      })
      .catch(() => {
        commit(USER_ERROR);
      });
  }
};

const mutations = {
  NEW_USER: state => {
    state.data.user_name = "";
    state.data.email1 = "";
    state.data.password = "";
    state.data.first_name = "";
    state.data.last_name = "";
    state.data.status = "Active";
    state.data.deleted = 0;
  },
  SET_STATUS: (state, payload) => {
    if (payload) {
      state.data.status = "Active";
      state.data.deleted = 0;
    } else {
      state.data.status = "Inactive";
      state.data.deleted = 1;
    }
  },
  SET_USERNAME: (state, payload) => {
    state.data.user_name = payload;
  },
  SET_FIRSTNAME: (state, payload) => {
    state.data.first_name = payload;
  },
  SET_LASTNAME: (state, payload) => {
    state.data.last_name = payload;
  },
  SET_EMAIL: (state, payload) => {
    state.data.email1 = payload;
  },
  SET_PASSWORD: (state, payload) => {
    state.data.user_password = payload;
  },
  SET_USERROLE: (state, payload) => {
    state.data.is_admin = payload;
  },

  SET_MOBILEPHONE: (state, payload) => {
    state.data.phone_mobile = payload;
  },
  SET_DEPARTMENT: (state, payload) => {
    state.data.department = payload;
  },
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "data", resp);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.data = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
