import { MODULES } from "../shared/constants";
import { ifAuthenticated } from "./helpers";

const NationalOverview = () => import("@/views/Distribution/National/Overview");
//const MarketOverview = () => import("@/views/Distribution/Market/Overview");
const MarketOverview = () =>
  import("@/views/Distribution/Market/MarketOverview/index.js");
const AccountOverview = () => import("@/views/Distribution/Account/Overview");
const DashboardOrderRegularity = () =>
  import("@/views/Distribution/OrderRegularity");
const ChainPerformance = () => import("@/views/Distribution/ChainPerformance");
const SalesReportCustom = () =>
  import("@/views/Distribution/SalesReportCustom");
const ProductsOverview = () => import("@/views/Distribution/ProductsOverview");
const ProductDetails = () => import("@/views/Distribution/ProductDetails");
const SalesOverview = () =>
  import("@/views/Distribution/SalesOverview/index.js");
//const SalesOverview = () => import("@/views/Distribution/SalesOverviewOld");

export default {
  path: "",
  name: "Distribution",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/distribution/national-overview",
      name: "National overview",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: NationalOverview,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/market-overview",
      name: "Market overview",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: MarketOverview,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/account-overview",
      name: "Account overview",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: AccountOverview,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/expected-next-orders",
      name: "Expected next orders",
      meta: { icon: "icon-list", module: MODULES.Distribution },
      component: DashboardOrderRegularity,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/account-chain-performance",
      name: "Account chain performance",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: ChainPerformance,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/custom-sales-reports",
      name: "Custom sales reports",
      meta: { icon: "icon-list", module: MODULES.Distribution },
      component: SalesReportCustom,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/sales-overview",
      name: "Sales overview",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: SalesOverview,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/products-overview",
      name: "Products overview",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: ProductsOverview,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/distribution/product-details",
      name: "Product details",
      meta: { icon: "icon-chart", module: MODULES.Distribution },
      component: ProductDetails,
      beforeEnter: ifAuthenticated
    }
  ]
};
