import axios from "axios";

let url = process.env.VUE_APP_API_URL;

const api = {
  auth: {
    login: user => {
      return new Promise(function(fulfill, reject) {
        let data = { username: user.username, password: user.password };

        axios({ url: `${url}/auth/login`, data: data, method: "POST" })
          .then(response => {
            fulfill(response.data);
          })
          .catch(function(ex) {
            reject(ex.response.data);
          });
      });
    }
  }
};

export default api;
