import { api } from "@/shared/api";
import store from "@/store";
const cleanupLS = () => {
  localStorage.removeItem("vuex");
  localStorage.removeItem("user-token");
};

const migrateLocalStorage = async () => {
  let lsVuex = localStorage.getItem("vuex");

  if (!lsVuex) return;

  //check if vuex exists in db
  let dbVuex = await api.get("user/profile/settings");

  if (
    dbVuex.vuex_settings &&
    lsVuex &&
    process.env.VUE_APP_STORAGE === "session"
  ) {
    cleanupLS();

    return;
  }

  //if dbVuex not exists then update db
  if (
    lsVuex &&
    !dbVuex.vuex_settings &&
    process.env.VUE_APP_STORAGE === "session"
  ) {
    const { filteringPanel, router } = JSON.parse(lsVuex);

    let dataStr = JSON.stringify({ filteringPanel, router });

    await api.put("user/profile/settings", {
      settings: dataStr
    });

    //clone to session, drop local storage, reload browser
    sessionStorage.setItem("vuex", lsVuex);

    cleanupLS();
  }

  return true;
};

const fetchSettings = async () => {
  let response = await api.get("user/profile/settings");

  if (!response || !response.vuex_settings) return undefined;

  let vx = JSON.parse(response.vuex_settings);

  if (!vx) return undefined;

  return vx;
};

let saveTimerHandler;

const saveSettings = async () => {
  try {
    const cb = async () => {
      if (store.getters.isAuthenticated) {
        const { filteringPanel, router } = store.state;

        let dataStr = JSON.stringify({ filteringPanel, router });

        await api.put("user/profile/settings", {
          settings: dataStr
        });

        saveSettings();
      } else {
        clearTimeout(saveTimerHandler);
      }
    };

    clearTimeout(saveTimerHandler);
    //const interval = process.env.VUE_APP_SAVE_SETTINGS_INTERVAL
    const interval = 60000;

    saveTimerHandler = setTimeout(cb, interval);
  } catch (error) {
    clearTimeout(saveTimerHandler);
    console.log("saveSettings.error", error);
  }

  return true;
};

export default {
  migrateLocalStorage,
  fetchSettings,
  saveSettings
};
