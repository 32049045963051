var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    staticClass: "form-group"
  }, [!_vm.hideLabel ? _c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.id
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('b-form-checkbox', {
    attrs: {
      "id": _vm.id,
      "switch": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    on: {
      "change": _vm.onChange
    },
    model: {
      value: _vm.val,
      callback: function callback($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }