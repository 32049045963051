const CREATE = 0
const EDIT = 1
const VIEW = 2
const READONLY = 3

export const JOB_STATUS = {
  RUNNING: 'running',
  STOPPED: 'stopped',
  COMPLETED: 'completed',
  FAILED: 'failed'
}

export const FORM_MODE = {
  CREATE: CREATE,
  EDIT: EDIT,
  VIEW: VIEW,
  READONLY: READONLY
}

export const FORMCONTROLMODE = {
  CREATE: CREATE,
  EDIT: EDIT,
  VIEW: VIEW
}

export const REPORTS_FREQUENCY = {
  DAILY: '1',
  WEEKLY: '2',
  BIWEEKLY: '3',
  MONTHLY: '4',
  QUARTERLY: '5',
  YEARLY: '6'
}

export const TASK_FREQUENCY = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  BIWEEKLY: 'Bi-Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly'
}

export const CUSTOM_TABLE = {
  NEW_ROW_ID: ''
}

export const TASK_STATUSES = [
  { name: 'Not Started', color: 'info' },
  { name: 'Planned', color: 'secondary' },
  { name: 'Pending Input', color: 'secondary' },
  { name: 'In Progress', color: 'warning' },
  { name: 'Completed', color: 'success' },
  { name: 'Canceled', color: 'dark' }
]

//export const MODULES = await api.get('dictionaries/modules')
//todo get from db
export const MODULES = {
  Activities: {
    id: 1,
    name: 'Activities'
  },
  Accounts: {
    id: 2,
    name: 'Accounts'
  },
  Projects: {
    id: 3,
    name: 'Projects'
  },
  Receipts: {
    id: 4,
    name: 'Receipts'
  },
  Tasks: {
    id: 5,
    name: 'Tasks'
  },
  Reports: {
    id: 6,
    name: 'Reports'
  },
  Tracktime: {
    id: 7,
    name: 'Tracktime'
  },
  Donations: {
    id: 8,
    name: 'Donations'
  },
  Contacts: {
    id: 9,
    name: 'Contacts'
  },
  Invoices: {
    id: 10,
    name: 'Invoices'
  },
  Reimbursements: {
    id: 11,
    name: 'Reimbursements'
  },
  Incentives: {
    id: 12,
    name: 'Incentives'
  },
  Samples: {
    id: 13,
    name: 'Samples'
  },
  Barrels: {
    id: 14,
    name: 'Barrels'
  },
  Products: {
    id: 15,
    name: 'Products'
  },
  DuplicateSearch: {
    id: 16,
    name: 'DuplicateSearch'
  },
  GlobalSearch: {
    id: 17,
    name: 'GlobalSearch'
  },
  Users: {
    id: 18,
    name: 'Users'
  },
  Chains: {
    id: 19,
    name: 'Chains'
  },
  Subscribers: {
    id: 20,
    name: 'Subscribers'
  },
  ReportsManager: {
    id: 21,
    name: 'ReportsManager'
  },
  Payroll: {
    id: 22,
    name: 'Payroll'
  },
  KnowledgeBase: {
    id: 23,
    name: 'KnowledgeBase'
  },
  Distribution: {
    id: 24,
    name: 'Distribution'
  },
  Settings: {
    id: 25,
    name: 'Settings'
  },
  Inventory: {
    id: 26,
    name: 'Inventory'
  },

  InventoryOrders: {
    id: 27,
    name: 'Inventory/Orders'
  },
  InventoryItems: {
    id: 28,
    name: 'Inventory/Items'
  },
  Production: {
    id: 29,
    name: 'Production'
  },
  Warehouse: {
    id: 30,
    name: 'Warehouse'
  },
  WarehouseIncomingOrders: {
    id: 31,
    name: 'Warehouse/IncomingOrders'
  },
  WarehousePickLists: {
    id: 32,
    name: 'Warehouse/PickLists'
  },
  WarehouseInvoices: {
    id: 33,
    name: 'Warehouse/Invoices'
  }
}

export const TRACK_TIME_ACTIONS = {
  CLOCK_IN: 1,
  CLOCK_OUT: 2,
  LUNCH: 3,
  ADD_TIME: 4,
  ADD_HOLIDAYS: 5
}

export const DAY_STATUS = {
  NOTSTARTED: 0,
  COMPLETED: 1,
  NOTCOMPLETED: 2,
  TRACKING: 3,
  SICK: 4,
  FORGOTTEN: 5,
  HOLIDAY: 6,
  VACATION: 7,
  VACATION_APPROVED: 8,
  VACATION_DECLINED: 9
}

export const DAY_TYPE = {
  REGULAR: 1,
  ADDTIME: 2,
  FORGOTTEN: 3,
  SICK: 4,
  VACATION: 5,
  HOLIDAY: 6,
  PERSONAL_LEAVE: 7
}

export const SALARY_TYPE = {
  HOURLY: 1,
  SALARIED: 2
}

export const TIMELOG_ERRORS = {
  MISSED_DAY: 1,
  NO_LUNCH: 2,
  OVERLAPPING_CLOCKS: 3,
  DOUBLE_DAY: 4,
  DOUBLE_BREAKS: 5,
  VACATION_OVERTIME: 6,
  SICK_OVERTIME: 7,
  EXTRA_LONG_CLOCK: 8,
  UNPAID_VACATION: 9
}

export const timeLogErrorsOptions = [
  { id: TIMELOG_ERRORS.MISSED_DAY, label: 'Missed day' },
  { id: TIMELOG_ERRORS.NO_LUNCH, label: 'No Lunch' },
  { id: TIMELOG_ERRORS.DOUBLE_DAY, label: 'Double day' },
  { id: TIMELOG_ERRORS.DOUBLE_BREAKS, label: 'Double breaks' },
  { id: TIMELOG_ERRORS.OVERLAPPING_CLOCKS, label: 'Overlapping clocks' },
  { id: TIMELOG_ERRORS.VACATION_OVERTIME, label: 'Vacation overtime' },
  { id: TIMELOG_ERRORS.SICK_OVERTIME, label: 'Sick overtime' },
  { id: TIMELOG_ERRORS.EXTRA_LONG_CLOCK, label: 'Extra long clock' },
  { id: TIMELOG_ERRORS.UNPAID_VACATION, label: 'Unpaid vacation' }
]

//ids from the database
export const CURRENCIES = {
  USD: '120',
  EUR: '37',
  KRW: '104',
  JPY: '55'
}

export const LOGICAL_OPERATORS = {
  STRING: {
    EQUALS: 'Equals',
    DOES_NOT_EQUAL: 'Does not equal',
    CONTAINS: 'Contains',
    DOES_NOT_CONTAIN: 'Does not contain',
    IS_EMPTY: 'Is empty',
    DOES_NOT_EMPTY: 'Does not empty'
  },
  NUMBER: {
    LESS_THAN: 'Less than',
    LESS_THAN_OR_EQUAL_TO: 'Less than or equal to',
    GREATER_THAN: 'Greater than',
    GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to',
    EQUALS: 'Equals',
    DOES_NOT_EQUAL: 'Does not equal'
  },
  DATETIME: {
    LESS_THAN: 'Less than',
    LESS_THAN_OR_EQUAL_TO: 'Less than or equal to',
    GREATER_THAN: 'Greater than',
    GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to',
    EQUALS: 'Equals',
    DOES_NOT_EQUAL: 'Does not equal'
  }
}

export const DATETIME_TEMPLATES = {
  TODAY: 'Today',
  TODAY_1Y_AGO: 'Today 1 year ago',
  TODAY_2Y_AGO: 'Today 2 years ago',
  START_OF_YEAR: 'Start of year',
  END_OF_YEAR: 'End of year',
  START_OF_QUARTER: 'Start of quarter',
  END_OF_QUARTER: 'End of quarter',
  START_OF_MONTH: 'Start of month',
  END_OF_MONTH: 'End of month',
  START_OF_WEEK: 'Start of week',
  END_OF_WEEK: 'End of week',
  START_OF_LAST_YEAR: 'Start of last year',
  END_OF_LAST_YEAR: 'End of last year',
  START_OF_LAST_QUARTER: 'Start of last quarter',
  END_OF_LAST_QUARTER: 'End of last quarter',
  START_OF_LAST_MONTH: 'Start of last month',
  END_OF_LAST_MONTH: 'End of last month',
  START_OF_LAST_WEEK: 'Start of last week',
  END_OF_LAST_WEEK: 'End of last week',
  SEVEN_DAYS_BEFORE_TODAY: '7 days before today',
  SEVEN_DAYS_AFTER_TODAY: '7 days after today',
  FIVE_YEARS_BEFORE: '5 years before today',
  TWO_YEARS_AFTER: '2 years after today',
  DAYS_BEFORE_TODAY_45: '45 days before today'
}

export const ALC_FINISHING_SOURCE = {
  DISTILLATIONS: 0,
  FINISHINGS: 1,
  BARRELS: 2
}

export default {
  FORM_MODE,
  FORMCONTROLMODE,
  REPORTS_FREQUENCY,
  TASK_FREQUENCY,
  TASK_STATUSES,
  MODULES,
  TRACK_TIME_ACTIONS,
  DAY_STATUS,
  DAY_TYPE,
  SALARY_TYPE,
  TIMELOG_ERRORS,
  timeLogErrorsOptions,
  CURRENCIES,
  LOGICAL_OPERATORS,
  DATETIME_TEMPLATES,
  JOB_STATUS,
  CUSTOM_TABLE,
  ALC_FINISHING_SOURCE
}
