export function injectComponentLogging(componentName, obj) {
  console.log(`${componentName}.this`, obj);

  for (let propName of Object.getOwnPropertyNames(obj)) {
    let prop = obj[propName];

    //check if property is function and not starts from _ or $

    if (typeof prop === "function" && !/^(_|\$)/.test(propName)) {
      obj[propName] = (function() {
        return function() {
          console.log(`${componentName}.${propName}()`, arguments[0]);

          return prop.apply(this, arguments);
        };
      })(propName);
    }
  }
}
