const defaultItemState = {
  panelState: {
    isUnfolded: true,
    isDropdownsBar: true,
    isCustomFiltersBar: false
  },
  selectedFilters: {},
  customFiltersBar: {
    ownFilters: { unfolded: true },
    ownSharedFilters: { unfolded: true },
    othersFilters: { unfolded: true }
  }
};

const state = {};

const getters = {
  getPanelState: state => datasetName => {
    return state[datasetName]
      ? state[datasetName].panelState
      : defaultItemState.panelState;
  },
  getSelectedFilters: state => datasetName => {
    return state[datasetName] ? state[datasetName].selectedFilters : {};
  },
  getCustomFiltersBar: state => datasetName => {
    return state[datasetName] ? state[datasetName].customFiltersBar : {};
  }
};

const actions = {
  saveState: ({ commit }, payload) => {
    commit("SAVE_STATE", payload);
  },
  saveSelectedFilters: ({ commit }, payload) => {
    commit("SAVE_SELECTED_FILTERS", payload);
  }
};

const mutations = {
  SAVE_SELECTED_FILTERS(state, payload) {
    //console.log("SAVE_SELECTED_FILTERS", payload);

    if (!payload.selected) payload.selected = {};

    state[payload.datasetName].selectedFilters = {};

    payload.panelFilters.forEach(f => {
      //if filter exists in selected object then save it
      if (Object.prototype.hasOwnProperty.call(payload.selected, f.name)) {
        //if (payload.selected.hasOwnProperty(f.name)) {
        let s = {
          name: f.name,
          dataType: f.dataType,
          value: payload.selected[f.name]
        };

        state[payload.datasetName].selectedFilters[f.name] = s;
      }
    });
  },
  SAVE_SETTINGS(state, payload) {
    state.tasks = payload.tasks;
  },
  SAVE_STATE(state, payload) {
    if (!state[payload.datasetName])
      state[payload.datasetName] = Object.assign({}, defaultItemState);

    if (!payload.panelState) payload.panelState = {};

    if (
      Object.prototype.hasOwnProperty.call(payload.panelState, "isUnfolded") &&
      //payload.panelState.hasOwnProperty("isUnfolded") &&
      state[payload.datasetName].panelState.isUnfolded !==
        payload.panelState.isUnfolded
    )
      state[payload.datasetName].panelState.isUnfolded =
        payload.panelState.isUnfolded;

    if (
      Object.prototype.hasOwnProperty.call(
        payload.panelState,
        "isDropdownsBar"
      ) &&
      //payload.panelState.hasOwnProperty("isDropdownsBar") &&
      state[payload.datasetName].panelState.isDropdownsBar !==
        payload.panelState.isDropdownsBar
    ) {
      state[payload.datasetName].panelState.isDropdownsBar =
        payload.panelState.isDropdownsBar;
      state[payload.datasetName].panelState.isCustomFiltersBar = !payload
        .panelState.isDropdownsBar;
    }

    if (
      Object.prototype.hasOwnProperty.call(
        payload.panelState,
        "isCustomFiltersBar"
      ) &&
      //payload.panelState.hasOwnProperty("isCustomFiltersBar") &&
      state[payload.datasetName].panelState.isCustomFiltersBar !==
        payload.panelState.isCustomFiltersBar
    ) {
      state[payload.datasetName].panelState.isCustomFiltersBar =
        payload.panelState.isCustomFiltersBar;
      state[payload.datasetName].panelState.isDropdownsBar = !payload.panelState
        .isCustomFiltersBar;
    }

    if (payload.customFiltersBar) {
      if (payload.customFiltersBar.ownFilters)
        state[payload.datasetName].customFiltersBar.ownFilters.unfolded =
          payload.customFiltersBar.ownFilters.unfolded;

      if (payload.customFiltersBar.ownSharedFilters)
        state[payload.datasetName].customFiltersBar.ownSharedFilters.unfolded =
          payload.customFiltersBar.ownSharedFilters.unfolded;

      if (payload.customFiltersBar.othersFilters)
        state[payload.datasetName].customFiltersBar.othersFilters.unfolded =
          payload.customFiltersBar.othersFilters.unfolded;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
