<!-- version 20201013 -->

<template>
  <div class="form-group" v-if="visible" :style="style">
    <label v-if="label" for="id"
      >{{ label }}
      <clip-loader class="ml-2" :size="14" color="#36D7B7" :loading="loading" />
    </label>
    <input
      v-if="(!linkMode && !isEditMode) || (!linkMode && isEditMode && readonly)"
      :readonly="readonly ? readonly : !isEditMode"
      type="text"
      :class="['form-control', readonly ? 'readonly' : '']"
      :value="
        multiple ? val.map(e => e.label).join(', ') : val ? val.label : ''
      "
      @change="changeField($event)"
      @dblclick="dblclickField($event)"
    />

    <!-- show value as link in the single mode -->
    <div
      v-if="
        val &&
          ((!multiple && linkMode && !isEditMode) ||
            (readonly && !multiple && linkMode))
      "
      class="pl-2 pt-2"
      @dblclick="dblclickField($event)"
    >
      <b-link
        :href="val.url ? val.url : '#'"
        target="_self"
        :for="id"
        @click="clickLink(val)"
      >
        {{ val.label }}
      </b-link>
    </div>
    <!-- show value as link in the multiple mode -->
    <div
      v-if="
        val &&
          val.length > 0 &&
          ((!isEditMode && multiple && linkMode) ||
            (readonly && multiple && linkMode))
      "
      @dblclick="dblclickField($event)"
    >
      <b-link
        v-for="(item, index) in val"
        :key="`acc-link-${index}`"
        :href="val.url ? val.url : '#'"
        target="_self"
        :for="item.id"
        style="display: flex;"
        @click="clickLink(item)"
      >
        {{ item.label }}
      </b-link>
    </div>

    <!-- flat lists -->
    <multiselect
      v-if="!readonly && isEditMode && !group_values"
      :class="[isInvalid ? 'is-invalid' : '']"
      :id="id"
      :options="options"
      :value="val"
      track-by="id"
      label="label"
      deselect-label=""
      select-label=""
      placeholder=""
      :multiple="multiple"
      :allow-empty="allowEmpty"
      :loading="loading"
      @search-change="onSearchChange"
      :taggable="taggable"
      @open="openSelect"
      @close="closeSelect"
      @input="changeSelect"
      @tag="addTag"
      @select="onSelect"
      @remove="onRemove"
      :option-height="250"
      tag-placeholder="Add this as new value"
    >
      <template slot="beforeList" v-if="async">
        <b-button-group size="sm">
          <b-button
            :pressed.sync="startsWith"
            squared
            size="sm"
            :variant="'ligth'"
          >
            <font-awesome-icon v-if="!startsWith" :icon="['far', 'square']" />
            <font-awesome-icon
              v-if="startsWith"
              :icon="['far', 'check-square']"
            />
            Starts with...
          </b-button>
        </b-button-group>
      </template>

      <template
        v-if="customOptionTemplate"
        slot="singleLabel"
        slot-scope="props"
      >
        <slot name="singleLabel" :slot-scope="props" />
      </template>
      <template v-if="customOptionTemplate" slot="option" slot-scope="props">
        <slot name="option" :slot-scope="props" />
      </template>
    </multiselect>

    <!-- grouped lists -->
    <multiselect
      v-if="!readonly && isEditMode && group_values"
      :class="[isInvalid ? 'is-invalid' : '']"
      :id="id"
      :value="val"
      track-by="id"
      label="label"
      :group-values="group_values"
      :group-label="group_label"
      :group-select="multiple"
      :options="options"
      deselect-label=""
      select-label=""
      placeholder=""
      :multiple="multiple"
      :allow-empty="allowEmpty"
      :loading="loading"
      @search-change="onSearchChange"
      @open="openSelect"
      @close="closeSelect"
      @input="changeSelect"
      @select="onSelect"
      @remove="onRemove"
    >
      <template slot="beforeList" v-if="async">
        <b-button-group size="sm">
          <b-button
            :pressed.sync="startsWith"
            squared
            size="sm"
            :variant="'ligth'"
          >
            <font-awesome-icon v-if="!startsWith" :icon="['far', 'square']" />
            <font-awesome-icon
              v-if="startsWith"
              :icon="['far', 'check-square']"
            />
            Starts with...
          </b-button>
        </b-button-group>
      </template>
    </multiselect>

    <!--
    <multiselect v-if="!readonly && isEditMode && !group_values" :class="[isInvalid && isEditMode ? 'is-invalid' : '']" :id="id" :value="val" track-by="id" label="label" :options="options" deselectLabel="" selectLabel="" placeholder="" :multiple="multiple" :allowEmpty="allowEmpty" @close="closeSelect" @input="changeSelect" :taggable="taggable" @tag="addTag" @select="onSelect" @remove="onRemove" tag-placeholder="Add this as new value"></multiselect>
    <multiselect v-if="!readonly && isEditMode && group_values" :class="[isInvalid && isEditMode ? 'is-invalid' : '']" :id="id" :value="val" track-by="id" label="label" :group-values="group_values" :group-label="group_label" :group-select="true" :options="options" deselectLabel="" selectLabel="" placeholder="" :multiple="multiple" :allowEmpty="allowEmpty" @close="closeSelect" @input="changeSelect" @select="onSelect" @remove="onRemove"></multiselect>
-->
    <!-- <div v-if="isInvalid && isEditMode" class="invalid-feedback d-block"> -->
    <div v-if="isInvalid" class="invalid-feedback d-block">
      <span v-if="!hideLabel">Field '{{ label }}' is required</span>
      <span v-if="hideLabel">Field is required</span>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import { FORMCONTROLMODE } from "@/shared/constants";

export default {
  components: {
    Multiselect
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Array, Object, String],
      default: function() {
        return [];
      }
    },
    async: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },

    mode: {
      type: Number,
      default: FORMCONTROLMODE.VIEW
    },
    required: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    group_values: {
      type: String,
      default: ""
    },
    group_label: {
      type: String,
      default: ""
    },
    customOptionTemplate: {
      type: Boolean,
      default: false
    },
    /*
        sample object
        {
            id: ID, label: LABEL, link: URL
        }
        OR use event @link-clicked in the parent component to custom behaviour
        */
    linkMode: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isEditMode: false,
      isChanged: false,
      isInvalid: false,
      /*
            isInvalid:{
                get(){
                    return !this.test()
                }
            },
            */
      val: function() {
        if (this.multiple) return [];
        if (!this.multiple) return {};
      },
      disabled: false,
      startsWith: false
    };
  },
  computed: {
    style() {
      return this.width ? "width: " + this.width + "em;" : "";
    }
  },
  created: function() {
    this.val = this.value;

    this.isEditMode =
      (this.mode === FORMCONTROLMODE.EDIT ||
        this.mode === FORMCONTROLMODE.CREATE) &&
      !this.readonly;
  },
  mounted: function() {},

  methods: {
    onSearchChange(query) {
      if (typeof this.debouncedSearchTimerId !== "undefined") {
        clearTimeout(this.debouncedSearchTimerId);
      }
      this.debouncedSearchTimerId = setTimeout(() => {
        if (query.trim() === "") return;

        this.$emit("async-search", {
          query: query,
          startsWith: this.startsWith
        });
      }, 1000);
    },

    addTag(value) {
      let id = this.id.replace(/select:|input:/g, "");

      const tag = {
        id: value,
        label: value
      };

      // eslint-disable-next-line vue/no-mutating-props
      this.options.push(tag);

      if (this.multiple) this.val.push(tag);
      if (!this.multiple) this.val = tag;

      this.isChanged = true;

      this.$emit("changed", id, this.val);
      this.$emit("input", this.val);
    },
    clear() {
      this.val = [];
    },
    disable() {},
    enable() {},
    test() {
      if (this.multiple) {
        this.isInvalid = this.required && (!this.val || this.val.length === 0);
      } else {
        //this.isInvalid = this.required && !this.val || !this.val.id

        this.isInvalid =
          this.required &&
          (!this.val ||
            this.val.id === null ||
            this.val.id === undefined ||
            this.val.id === "");
      }

      return !this.isInvalid;
    },

    blurField(e) {
      if (this.readonly) return;

      let id = e.currentTarget.id.replace(/select:|input:/g, "");

      //this.val = e.target.value

      if (!this.test()) return;

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = false;
      }

      if (this.isChanged) {
        this.$emit("changed", id, this.val);
        this.$emit("input", this.val);
      }
    },
    onSelect(e) {
      this.$emit("selected", e);
    },
    onRemove(e) {
      this.$emit("removed", e);
    },

    changeField(e) {
      this.val = e.currentTarget.value;

      this.isChanged = this.value !== this.val;

      //this.isInvalid = this.required && (!e.currentTarget.value)
      this.test();
    },
    clickLink(payload) {
      this.$emit("link-clicked", payload);
    },
    dblclickField(e) {
      //12102021 if (this.isInvalid) return;
      if (this.readonly) return;

      //let id = e.currentTarget.id.replace(/select:|input:/g, "");

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode;
        this.isEditMode || this.blurField(e);
      }
    },
    openSelect() {
      this.$emit("open");
    },
    closeSelect() {
      //id = id.replace(/select:|input:/g, "");

      if (!this.test()) return;

      if (this.mode === this.FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode;
      }

      this.$emit("close");
      // if (this.isChanged) this.$emit("changed", id, this.val);
    },
    changeSelect(value, id) {
      id = id.replace(/select:|input:/g, "");

      if (value === null) {
        value = {
          id: "",
          label: ""
        };
      }

      if (this.value !== value) {
        this.isChanged = true;
      }

      this.val = value;

      if (this.isChanged) {
        this.$emit("changed", id, this.val);
        this.$emit("input", this.val);
      }
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal;
    },
    required() {
      //20210110 user migration manager
      this.test();
    }
  }
};
</script>

<style scoped>
::v-deep .multiselect,
.multiselect__single,
.multiselect__tags {
  background-color: #ffffed;
  /*
 min-height: 30px;
 padding: 6px 40px 0 6px;
 font-size: 14px;
*/
}

::v-deep .multiselect.is-invalid {
  border: 1px solid #f86c6b;
  border-radius: 0.25rem;
}
/*
::v-deep .multiselect__option--highlight {
  background: #ccc;
  outline: none;
  color: #000;
}*/

::v-deep .readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

::v-deep .form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.form-group {
  margin-bottom: 0 !important;
}
/*scrollbars custom style */
::v-deep .multiselect__content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;

  background-color: #f5f5f5;
}

::v-deep .multiselect__content-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::v-deep .multiselect__content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(197, 196, 196);
}
</style>
