import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

export default {
  path: "",
  name: "Warehouse",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/warehouse/national-overview",
      name: "WH national overview",
      component: () => import("@/views/Warehouse/NationalOverview"),
      props: true,
      meta: { module: MODULES.Warehouse, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/sales-details",
      name: "WH sales details",
      component: () =>
        import("@/views/Warehouse/SalesDetails/SalesDetailsReport"),
      meta: { module: MODULES.Warehouse, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/wh-product-details",
      name: "WH product details",
      component: () => import("@/views/Warehouse/WarehouseProductDetails"),
      meta: { module: MODULES.Warehouse, icon: "icon-chart" },
      beforeEnter: ifAuthenticated
    },

    {
      path: "/warehouse/wh-products-overview",
      name: "WH products overview",
      component: () => import("@/views/Warehouse/WarehouseProductsOverview"),
      meta: { module: MODULES.Warehouse, icon: "icon-chart" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/available-products",
      name: "Available products",
      component: () =>
        import("@/views/Warehouse/Inventory/AvailableProducts.vue"),
      props: true,
      meta: { module: MODULES.Warehouse, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },

    {
      path: "/warehouse/available-products-totals",
      name: "Available products totals",
      component: () =>
        import("@/views/Warehouse/Inventory/AvailableProductsTotals.vue"),
      meta: { module: MODULES.Warehouse, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "",
      name: "Product need",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      meta: { module: MODULES.WarehouseIncomingOrders, icon: "icon-list" },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: "/warehouse/incoming-orders/product-need-product",
          name: "By product",
          component: () =>
            import("@/views/Warehouse/IncomingOrders/ProductNeed.vue"),
          meta: { module: MODULES.WarehouseIncomingOrders, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/warehouse/incoming-orders/product-need-account",
          name: "By account",
          component: () =>
            import("@/views/Warehouse/IncomingOrders/ProductNeedAccount.vue"),
          meta: { module: MODULES.WarehouseIncomingOrders, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        }
      ]
    },

    {
      path: "/warehouse/incoming-orders",
      name: "Incoming orders",
      component: () =>
        import("@/views/Warehouse/IncomingOrders/IncomingOrders.vue"),
      meta: { module: MODULES.WarehouseIncomingOrders, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/incoming-orders/:action/:id?",
      name: "Incoming order submission",
      component: () =>
        import("@/views/Warehouse/IncomingOrders/IncomingOrderSubmissionForm"),
      props: true,
      meta: { module: MODULES.WarehouseIncomingOrders, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/pick-lists",
      name: "Pick lists",
      component: () => import("@/views/Warehouse/PickLists/PickLists.vue"),
      meta: { module: MODULES.WarehousePickLists, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/pick-list/:action/:id?",
      name: "Pick list submission",
      component: () =>
        import("@/views/Warehouse/PickLists/PickListSubmissionForm"),
      props: true,
      meta: { module: MODULES.WarehousePickLists, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },

    {
      path: "/warehouse/invoices",
      name: "WH Invoices",
      component: () => import("@/views/Warehouse/Invoices/Invoices.vue"),
      meta: { module: MODULES.WarehouseInvoices, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/invoice/:action/:id?",
      name: "WH Invoice submission",
      component: () =>
        import("@/views/Warehouse/Invoices/InvoiceSubmissionForm"),
      props: true,
      meta: { module: MODULES.WarehouseInvoices, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/warehouse/qb-invoices-review",
      name: "QB invoices review",
      component: () =>
        import("@/views/Warehouse/Invoices/QBInvoicesReview.vue"),
      meta: { module: MODULES.WarehouseInvoices, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    }
  ]
};
