import Vue from "vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBus,
  faTruck,
  faPencilAlt,
  faEye,
  faEyeSlash,
  faSearch,
  faTrash,
  faTrashRestore,
  faSync,
  faArrowCircleLeft,
  faArrowAltCircleLeft,
  faUtensils,
  faMugHot,
  faPlaneDeparture,
  faPlane,
  faCalendarPlus,
  faCalendar,
  faCalendarAlt,
  faTemperatureHigh,
  faHome,
  faUserClock,
  faArrowCircleRight,
  faArrowAltCircleRight,
  faFilter,
  faFile,
  faFileAlt,
  faSignature,
  faCheck,
  faTimes,
  faArrowDown,
  faArrowUp,
  faExclamationCircle,
  faQuestionCircle,
  faList,
  faPlus,
  faMinus,
  faPlusSquare,
  faPlusCircle,
  faTasks,
  faBan,
  faCheckCircle,
  faEnvelope,
  faEnvelopeOpen,
  faSignInAlt,
  faSignOutAlt,
  faUpload,
  faDownload,
  faCopy,
  faSave,
  faPaste,
  faExternalLinkAlt,
  faBolt,
  faDollarSign,
  faCog,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronCircleLeft,
  faChevronRight,
  faBackspace,
  faGripHorizontal,
  faShareAlt,
  faLock,
  faLockOpen,
  faCaretSquareDown,
  faTh,
  faThList,
  faChartBar,
  faChartLine,
  faChartPie,
  faUmbrellaBeach,
  faExclamation,
  faClone,
  faCloudDownloadAlt,
  faSortAmountDownAlt,
  faShareSquare,
  faShareAltSquare,
  faShare,
  faTshirt,
  faWineBottle,
  faRing,
  faShippingFast,
  faUndo,
  faHockeyPuck,
  faBoxOpen,
  faRegistered,
  faMoneyCheckAlt,
  faPaperPlane,
  faCoins,
  faHourglassStart,
  faBold,
  faPlay,
  faAngleDoubleRight,
  faProjectDiagram,
  faReceipt,
  faClipboardList,
  faBuilding,
  faAddressCard,
  faShapes,
  faCubes,
  faCompress,
  faCompressArrowsAlt,
  faHandPointLeft,
  faHandPointRight,
  faClock,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";

library.add(
  faBus,
  faTruck,
  faPencilAlt,
  faEye,
  faEyeSlash,
  faSearch,
  faTrash,
  faTrashRestore,
  faSync,
  faArrowCircleLeft,
  faArrowAltCircleLeft,
  faUtensils,
  faMugHot,
  faPlaneDeparture,
  faPlane,
  faCalendarPlus,
  faCalendar,
  faCalendarAlt,
  faTemperatureHigh,
  faHome,
  faUserClock,
  faArrowCircleRight,
  faArrowAltCircleRight,
  faFilter,
  faFile,
  faFileAlt,
  faClock,
  faSignature,
  faCheck,
  faCheckSquare,
  faSquare,
  faTimes,
  faArrowDown,
  faArrowUp,
  faExclamationCircle,
  faQuestionCircle,
  faList,
  faPlus,
  faMinus,
  faPlusSquare,
  faPlusCircle,
  faTasks,
  faBan,
  faCheckCircle,
  faEnvelope,
  faEnvelopeOpen,
  faSignInAlt,
  faSignOutAlt,
  faUpload,
  faDownload,
  faCopy,
  faSave,
  faPaste,
  faExternalLinkAlt,
  faBolt,
  faDollarSign,
  faCog,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronCircleLeft,
  faChevronRight,
  faBackspace,
  faGripHorizontal,
  faShareAlt,
  faLock,
  faLockOpen,
  faCaretSquareDown,
  faTh,
  faThList,
  faChartBar,
  faChartLine,
  faChartPie,
  faUmbrellaBeach,
  faExclamation,
  faClone,
  faCloudDownloadAlt,
  faSortAmountDownAlt,
  faShareSquare,
  faShareAltSquare,
  faShare,
  faTshirt,
  faWineBottle,
  faRing,
  faShippingFast,
  faUndo,
  faHockeyPuck,
  faBoxOpen,
  faRegistered,
  faMoneyCheckAlt,
  faPaperPlane,
  faCoins,
  faHourglassStart,
  faBold,
  faPlay,
  faAngleDoubleRight,
  faProjectDiagram,
  faReceipt,
  faClipboardList,
  faBuilding,
  faAddressCard,
  faShapes,
  faCubes,
  faCompress,
  faCompressArrowsAlt,
  faHandPointRight,
  faHandPointLeft,
  faInfoCircle
);
