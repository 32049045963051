const SET_ACCOUNT = "SET_ACCOUNT";
const SET_BASE_ACCOUNT = "SET_BASE_ACCOUNT";
const SET_ACCOUNTS = "SET_ACCOUNTS";
const SAVE_STEP1 = "SAVE_STEP1";
const SAVE_STEP2 = "SAVE_STEP2";
const SAVE_STEP3 = "SAVE_STEP3";
const SAVE_STEP4 = "SAVE_STEP4";

const state = {
  baseAccount: {},
  accounts: [],
  step1: {},
  step2: {},
  step3: {},
  step4: {}
};

const getters = {
  getBaseAccount: state => state.baseAccount,
  getAccounts: state => state.accounts,
  getStep1: state => state.step1,
  getStep2: state => state.step2,
  getStep3: state => state.step3,
  getStep4: state => state.step4
};

const actions = {
  updateAccount: ({ commit }, payload) => {
    commit(SET_ACCOUNT, payload);
  },
  saveAccounts: ({ commit }, payload) => {
    commit(SET_ACCOUNTS, payload);
  },
  saveBaseAccount: ({ commit }, payload) => {
    commit(SET_BASE_ACCOUNT, payload);
  },
  saveStep1: ({ commit }, payload) => {
    commit(SAVE_STEP1, payload);
  },
  saveStep2: ({ commit }, payload) => {
    commit(SAVE_STEP2, payload);
  },
  saveStep3: ({ commit }, payload) => {
    commit(SAVE_STEP3, payload);
  },
  saveStep4: ({ commit }, payload) => {
    commit(SAVE_STEP4, payload);
  }
};

const mutations = {
  [SET_ACCOUNT]: (state, payload) => {
    let idx = state.accounts.findIndex(a => a["ID"] === payload.id);
    state.accounts[idx] = payload.data;
  },
  [SET_BASE_ACCOUNT]: (state, payload) => {
    state.baseAccount = payload;
  },

  [SET_ACCOUNTS]: (state, payload) => {
    state.accounts = payload;
  },
  [SAVE_STEP1]: (state, payload) => {
    state.step1 = payload;
  },
  [SAVE_STEP2]: (state, payload) => {
    state.step2 = payload;
  },
  [SAVE_STEP3]: (state, payload) => {
    state.step3 = payload;
  },
  [SAVE_STEP4]: (state, payload) => {
    state.step4 = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
