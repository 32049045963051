var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('inline-time-picker', {
    attrs: {
      "id": "timepicker:".concat(_vm.$helpers.str2_(_vm.properties.name)),
      "value": _vm.value,
      "label": _vm.properties.name,
      "readonly": _vm.properties.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.onChange
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }