<template>
  <inline-time-picker
    :id="`timepicker:${$helpers.str2_(properties.name)}`"
    :value="value"
    :label="properties.name"
    :readonly="properties.readonly"
    :mode="mode"
    @changed="onChange"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },

    properties: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    mode() {
      return this.$form.mode(this);
    }
  },
  created() {},
  methods: {
    onChange(id, val) {
      this.$emit("input", val);

      this.$emit("change", { id: id, value: val });
    }
  }
};
</script>

<style></style>
