var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('inline-select', {
    attrs: {
      "id": "select:".concat(_vm.$helpers.str2_(_vm.properties.name)),
      "value": _vm.value,
      "label": _vm.properties.name,
      "readonly": _vm.properties.readonly,
      "options": _vm.options,
      "allow-empty": _vm.properties.allowEmpty,
      "multiple": _vm.properties.multiple,
      "mode": _vm.mode,
      "required": _vm.properties.required
    },
    on: {
      "changed": _vm.onChange
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }