import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from '../actions/auth'
import axios from 'axios'
import api from '@/shared/api/auth'

import settingsService from '../../services/user-settings.service'
//import settings from "../../router/settings";

//const storage = window.localStorage
const storage =
  process.env.VUE_APP_STORAGE === 'session'
    ? window.sessionStorage
    : window.localStorage

const state = {
  token: storage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false
}

const getters = {
  authToken: state => state.token,
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
}

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch, rootState }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)

      delete axios.defaults.headers.common['Authorization']

      api.auth
        .login(user)
        .then(async resp => {
          console.log('response:', resp)
          const token = resp.data.token

          storage.setItem('user-token', token)

          axios.defaults.headers.common['Authorization'] = token

          //await settingsService.migrateLocalStorage();

          let vx = await settingsService.fetchSettings()

          commit(AUTH_SUCCESS, { auth: resp, vx: vx, rootState: rootState })

          dispatch('profile/fetchProfile')

          dispatch('timeTracker/fetchStatus')

          resolve(resp)
        })
        .catch(err => {
          console.log(err)
          commit(AUTH_ERROR, err)
          storage.removeItem('user-token')
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT)

      dispatch('permissions/delete')

      storage.removeItem('user-token')

      storage.removeItem('vuex')

      delete axios.defaults.headers.common['Authorization']

      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, payload) => {
    state.status = 'success'
    state.token = payload.auth.data.token
    state.hasLoadedOnce = true

    //initialize state
    if (payload.vx && payload.vx.filteringPanel)
      payload.rootState.filteringPanel = payload.vx.filteringPanel

    if (payload.vx && payload.vx.router)
      payload.rootState.router = payload.vx.router
  },
  [AUTH_ERROR]: state => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
