var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.header) + " "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _vm._t("default", null, {
    "id": "content"
  }), _c('hr'), _vm.formAction !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.formMode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.model, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.model, _vm.id),
        save: !_vm.readOnly,
        saveAndView: !_vm.readOnly,
        edit: !_vm.readOnly && _vm.model.actions && _vm.model.actions.Edit && _vm.model.actions.Edit.validator(_vm.data),
        view: !_vm.readOnly && _vm.model.actions && _vm.model.actions.View && _vm.model.actions.View.validator(_vm.data)
      },
      "custom-buttons": _vm.customButtons
    },
    on: {
      "custom-click": function customClick(name) {
        _vm.$emit('custom-click', name);
      },
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.model, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.model, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: _vm.model.tabularRouterName
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: _vm.model.detailsRouterName,
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: _vm.model.detailsRouterName,
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }