import Store from "../store";
import Vue from "vue";

export function track(data) {
  if (data.to && data.to.path === "/chart-details")
    data.details =
      Store.getters["drilldown.parent"] +
      " / " +
      Store.getters["drilldown.description"];

  //console.log(Store.getters['filteringPanel/getSelectedFilters']()
  const payload = {
    from_name: data.from.name,
    from_path: data.from.path,
    from_action: data.from.params.action,
    from_entity_id: data.from.params.id,
    from_module_id: data.from.meta.module ? data.from.meta.module.id : 0,
    to_name: data.to.name,
    to_path: data.to.path,
    to_action: data.to.params.action,
    to_entity_id: data.to.params.id,
    to_module_id: data.to.meta.module ? data.to.meta.module.id : 0,
    details: data.details
  };

  Vue.prototype.$api.put("user/activity-tracking", payload);
}
