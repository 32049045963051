import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const Payroll = () => import("@/views/Payroll/Payroll.vue");
const PayrollCalendar = () => import("@/views/Payroll/PayrollCalendar.vue");

export default {
  path: "",
  name: "Payroll",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/payroll/my-payroll",
      name: "My payroll",
      meta: { icon: "icon-list", module: MODULES.Payroll },
      component: Payroll,
      props: true, //{ mode: 'mypayroll' },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/payroll/payroll-calendar",
      name: "Payroll calendar",
      meta: { icon: "icon-list", module: MODULES.Payroll },
      component: PayrollCalendar,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/payroll/all-users-payroll",
      name: "All users payroll",
      meta: { icon: "icon-list", module: MODULES.Payroll },
      component: Payroll,
      props: true, //{ mode: 'payroll' },
      beforeEnter: ifAuthenticated
    }
  ]
};
