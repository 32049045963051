var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "p-2",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', [_c('b-form-row', [_c('b-col', {
    attrs: {
      "cols": "8",
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-input', {
    ref: "input-search",
    attrs: {
      "placeholder": "type to search..."
    },
    on: {
      "keyup": _vm.onSearchKeyUp
    }
  })], 1), _c('b-col', {
    staticClass: "pt-1",
    attrs: {
      "cols": "2",
      "md": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-dark",
      "title": "New filter",
      "disabled": _vm.mode !== _vm.FORM_MODE.VIEW || _vm.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.addFilter();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('hr', {
    staticClass: "m-2"
  }), !_vm.isLoading ? _c('b-row', [_c('b-col', _vm._l(_vm.collapse.items, function (citem, index) {
    return _c('div', {
      key: "ci-".concat(index)
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: "collapse-f-".concat(index),
        expression: "`collapse-f-${index}`"
      }],
      staticClass: "collapse-header"
    }, [citem.unfolded ? _c('font-awesome-icon', {
      attrs: {
        "icon": "chevron-up"
      }
    }) : _vm._e(), !citem.unfolded ? _c('font-awesome-icon', {
      attrs: {
        "icon": "chevron-down"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(citem.title) + " (" + _vm._s(_vm.filters(citem.name).length) + ") ")], 1), _c('b-collapse', {
      staticClass: "mt-0",
      attrs: {
        "id": "collapse-f-".concat(index)
      },
      on: {
        "show": function show($event) {
          return _vm.onCollapseShow(citem);
        },
        "hide": function hide($event) {
          return _vm.onCollapseHide(citem);
        }
      },
      model: {
        value: citem.unfolded,
        callback: function callback($$v) {
          _vm.$set(citem, "unfolded", $$v);
        },
        expression: "citem.unfolded"
      }
    }, [_vm.showReadMore ? _c('VueReadMoreSmooth', {
      ref: "read-more",
      refInFor: true,
      attrs: {
        "lines": 3,
        "text": "Display more",
        "text-less": "Display less"
      }
    }, [_c('draggable', {
      staticClass: "d-flex flex-row flex-wrap",
      attrs: {
        "id": citem.name,
        "group": citem.name,
        "list": _vm.$data[citem.name]
      },
      on: {
        "change": _vm.onChange,
        "start": _vm.onStart,
        "end": _vm.onEnd,
        "move": _vm.onMove
      }
    }, _vm._l(_vm.$data[citem.name], function (item, index2) {
      return _c('span', {
        key: index2
      }, [_c('custom-filter-item', {
        attrs: {
          "order": index,
          "item": item
        },
        on: {
          "change": _vm.onItemChange,
          "click": _vm.onItemClick
        }
      })], 1);
    }), 0)], 1) : _vm._e()], 1)], 1);
  }), 0)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }