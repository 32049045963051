import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const Projects = () => import("@/views/ProjectManagement/Projects");
const ProjectSubmissionForm = () =>
  import("@/views/ProjectManagement/ProjectSubmissionForm");

const Tasks = () => import("@/views/ProjectManagement/Tasks");
const TaskSubmissionForm = () =>
  import("@/views/ProjectManagement/TaskSubmissionForm");

export default {
  path: "",
  name: "Project management",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/project-management/projects",
      name: "Projects",
      component: Projects,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Projects, icon: "icon-list" }
    },
    {
      path: "/project-management/project/:action/:id?",
      name: "Project submission",
      component: ProjectSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Projects, hideFromMenu: true }
    },
    {
      path: "/project-management/tasks",
      name: "Tasks",
      component: Tasks,
      props: { mode: "table" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Tasks, icon: "icon-list" }
    },
    {
      path: "/project-management/task/:action/:id?",
      name: "Task submission",
      component: TaskSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Tasks, hideFromMenu: true }
    },
    {
      path: "/project-management/kanban",
      name: "Kanban",
      component: Tasks,
      props: { mode: "kanban" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Tasks, icon: "icon-list" }
    },
    {
      path: "/project-management/gantt",
      name: "Gantt",
      component: Tasks,
      props: { mode: "gantt" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Tasks, icon: "icon-list" }
    }
  ]
};
