import Vue from "vue";

import {
  encryptPermissions,
  decryptPermissions
} from "../../shared/permissions";

import {
  PERMISSIONS_REQUEST,
  PERMISSIONS_ERROR,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_DELETE
} from "../actions/permissions";

import { api } from "@/shared/api";

import _ from "lodash";

import { mergePermissions } from "@/shared/permissions";

const updatePermissionsIntervalSec = 300;

const state = { data: {}, status: "", lastUpdated: "" };

const getters = {
  getData: state => {
    let decryptedPermissions = decryptPermissions(state.data);

    return decryptedPermissions ? JSON.parse(decryptedPermissions) : {};
  },
  getStatus: state => state.status
};

const actions = {
  fetchMerged: ({ state, commit, getters }) => {
    return new Promise((resolve, reject) => {
      const lastUpdatedSec = (Date.now() - state.lastUpdated) / 1000;

      if (
        !_.isEmpty(getters.getData) &&
        lastUpdatedSec < updatePermissionsIntervalSec
      ) {
        resolve(getters.getData);

        return;
      }

      commit(PERMISSIONS_REQUEST);

      api
        .get("/user/permissions")
        .then(response => {
          let p = {};

          let p1 = response.role_permissions;
          //let p1 = decryptPermissions(response.role_permissions);

          p1 = Vue.prototype.$helpers.getJSONObject(p1);

          let p2 = response.user_permissions;
          //let p2 = decryptPermissions(response.user_permissions);

          p2 = Vue.prototype.$helpers.getJSONObject(p2);

          //append user to role
          if (p1 && p2) p = mergePermissions(p1, p2);

          //if users permissions empty use role
          if (p1 && !p2) p = p1;

          let encryptedPermissions = encryptPermissions(JSON.stringify(p));

          commit(PERMISSIONS_SUCCESS, encryptedPermissions);

          resolve(response.p);
        })
        .catch(err => {
          //debugger
          commit(PERMISSIONS_ERROR, err);

          reject(err);
        });
    });
  },
  async fetchRolePermissions(context, roleId) {
    let response;

    try {
      response = await api.get(`/role/${roleId}/permissions`);

      //response.permissions = decryptPermissions(response.permissions);
    } catch (error) {
      console.log(error);
    }

    return response;
  },
  async fetchUserPermissions(context, userId) {
    let response;

    try {
      response = await api.get(`/user/${userId}/permissions`);

      //response.permissions = decryptPermissions(response.permissions);
    } catch (error) {
      console.log(error);
    }

    return response;
  },
  async updateRolePermissions(context, payload) {
    let response;

    //payload.data = encryptPermissions(payload.data);

    try {
      response = await api.put(`/role/${payload.id}/permissions`, payload);
    } catch (error) {
      console.log(error);
    }

    return response;
  },
  async updateUserPermissions(context, payload) {
    let response;

    //payload.data = encryptPermissions(payload.data);

    try {
      response = await api.put(`/user/${payload.id}/permissions`, payload);
    } catch (error) {
      console.log(error);
    }

    return response;
  },

  delete: ({ commit }) => {
    commit(PERMISSIONS_DELETE);
  }
};

const mutations = {
  [PERMISSIONS_REQUEST]: state => {
    state.status = "loading";
  },
  [PERMISSIONS_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.data = resp;
    state.lastUpdated = Date.now();
  },
  [PERMISSIONS_ERROR]: state => {
    state.status = "error";
  },
  [PERMISSIONS_DELETE]: state => {
    state.data = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
