import Vue from "vue";

import moment from "moment-timezone";
moment.tz.setDefault("Etc/UTC");
Vue.prototype.moment = moment;

//Vue.prototype.$nowCST = moment.tz('America/Chicago')
Vue.prototype.$nowClient = moment.tz(moment(), moment.tz.guess());
Vue.prototype.$nowClientAsString = moment
  .tz(moment(), moment.tz.guess())
  .format("YYYY-MM-DD");
Vue.prototype.$clientTZ = moment.tz(moment.tz.guess()).format("z");

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY hh:mm");
  }
});

import axios from "axios";
Vue.prototype.$http = axios;

Vue.prototype.$client_url = process.env.VUE_APP_CLIENT_URL;
Vue.prototype.$api_url = process.env.VUE_APP_API_URL;

import { track } from "@/shared/user-activity-tracking.js";
Vue.prototype.$track = track;

import {
  permitted,
  routePermitted,
  restrictMenu
} from "@/shared/permissions.js";

Vue.prototype.$permitted = permitted;
Vue.prototype.$access = {
  object: permitted,
  routePermitted: routePermitted,
  restrictMenu: restrictMenu
};

import { injectComponentLogging } from "@/shared/utils.js";

Vue.prototype.$injectLogging = injectComponentLogging;

Vue.prototype.$log = console.log;

import customTableHelpers from "@/components/TableCustom/helpers.js";

Vue.prototype.$customTable = customTableHelpers;

import _ from "lodash";
Object.defineProperty(Vue.prototype, "$_", { value: _ });

Object.defineProperty(Vue.prototype, "$bus", {
  get: function() {
    return this.$root.bus;
  }
});

if (process.env.NODE_ENV === "production") {
  console.log = function() {};
}

Vue.config.productionTip = false;
