import Vue from "vue";

let state = {
  department: {
    id: "",
    label: ""
  },
  category: {
    id: "",
    label: "",
    frequency: ""
  },
  period: {
    startDate: "",
    endDate: ""
  },
  user: {
    id: "",
    name: ""
  }
};

const getters = {
  getState: state => state,
  getDepartment: state => state.department,
  getCategory: state => state.category,
  getPeriod: state => state.period
};

const actions = {
  saveState: ({ commit }, data) => {
    commit("SAVE", data);
  },

  clearState: ({ commit }) => {
    let state = {
      department: {
        id: "",
        label: ""
      },
      category: {
        id: "",
        label: "",
        frequency: ""
      },
      period: {
        startDate: "",
        endDate: ""
      },
      user: {
        id: "",
        name: ""
      }
    };

    commit("SAVE", state);
  }
};

const mutations = {
  SAVE: (state, data) => {
    Vue.set(state, "department", data.department);
    Vue.set(state, "category", data.category);
    Vue.set(state, "period", data.period);
    Vue.set(state, "user", data.user);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
