<template>
  <div class="form-group" v-if="visible">
    <label class="col-form-label" :for="id" v-if="!hideLabel">{{
      label
    }}</label>
    <input
      v-if="(!isTextArea && !mask) || (mask && !isEditMode)"
      :ref="id"
      :id="id"
      :value="val"
      :type="type"
      :class="[
        'form-control',
        isInvalid && isEditMode ? 'is-invalid' : '',
        readonly && !isEditMode ? 'readonly' : '',
        isEditMode || mode === FORMCONTROLMODE.EDIT ? 'edit' : ''
      ]"
      :readonly="readonly ? readonly : !isEditMode"
      @change="changeField($event)"
      @dblclick="dblclickField($event)"
      @blur="blurField($event)"
      @keyup="onKeyUp($event)"
      @keyup.enter="blurField($event)"
    />
    <masked-input
      v-if="!isTextArea && mask && isEditMode"
      :ref="id"
      :id="id"
      :value="val"
      :mask="mask"
      placeholder=" "
      :class="[
        'form-control',
        isInvalid && isEditMode ? 'is-invalid' : '',
        readonly && !isEditMode ? 'readonly' : '',
        isEditMode || mode === FORMCONTROLMODE.EDIT ? 'edit' : ''
      ]"
      type="text"
      @input="changeMaskedField($event)"
    />

    <textarea
      v-if="isTextArea && !mask"
      :ref="id"
      :id="id"
      :value="val"
      :type="type"
      :rows="rows"
      :class="[
        'form-control',
        isInvalid && isEditMode ? 'is-invalid' : '',
        readonly && !isEditMode ? 'readonly' : '',
        isEditMode || mode === FORMCONTROLMODE.EDIT ? 'edit' : ''
      ]"
      :readonly="readonly ? readonly : !isEditMode"
      @change="changeField($event)"
      @dblclick="dblclickField($event)"
      @blur="blurField($event)"
      @keyup.enter="onEnter($event)"
    />

    <div
      v-if="!customError && isInvalid && isEditMode"
      class="invalid-feedback d-block"
    >
      Field '{{ label }}' is required
    </div>
    <div v-if="customError" class="invalid-feedback d-block">
      {{ customError }}
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-masked-input";

import { FORMCONTROLMODE } from "@/shared/constants";

export default {
  components: { MaskedInput },
  props: {
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    mode: {
      type: Number,
      default: FORMCONTROLMODE.VIEW
    },
    required: {
      type: Boolean,
      default: false
    },
    isTextArea: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 7
    },
    mask: {
      type: String,
      default: undefined
    }
  },
  data: function() {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isEditMode: false,
      isChanged: false,
      isInvalid: false,
      val: "",
      customError: ""
    };
  },
  computed: {},
  created: function() {
    this.val = this.value;

    this.isEditMode =
      (this.mode === FORMCONTROLMODE.EDIT ||
        this.mode === FORMCONTROLMODE.CREATE) &&
      !this.readonly;
  },
  mounted: function() {},

  methods: {
    setCustomError(_value) {
      this.customError = _value;

      this.isInvalid = _value ? true : false;
    },
    onKeyUp(e) {
      this.changeField(e);

      this.$emit("keyup", { id: this.id, val: this.val, mode: this.mode });

      this.$emit("input", this.val);
    },
    setValue(_value) {
      this.val = _value;
    },
    getValue() {
      return this.val;
    },
    setMode(_mode) {
      if (_mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = false;
      }
      if (_mode !== FORMCONTROLMODE.VIEW) {
        this.isEditMode = true;
        this.$refs[this.id].focus();
      }
    },
    test() {
      this.isInvalid = this.required && !this.val;

      if (this.mask && this.val && this.val.indexOf("_") >= 0)
        this.isInvalid = true;

      return !this.isInvalid;
    },
    onEnter() {
      //20201210
      //this.blurField(e)
    },
    blurField(e) {
      if (this.readonly) return;

      let id = e.currentTarget.id.replace(/select:|input:/g, "");

      //this.val = e.target.value
      //this.isInvalid = this.required && (!e.currentTarget.value)

      if (!this.test()) return;

      if (this.mode === FORMCONTROLMODE.VIEW && !this.mask) {
        this.isEditMode = false;
      }

      if (this.isChanged) {
        this.$emit("changed", id, this.val, this.mode);

        this.$emit("input", this.val);
      }
    },
    changeMaskedField(e) {
      this.val = e;

      this.isChanged = this.value !== this.val;

      if (!this.test()) return;

      let id = this.id.replace(/select:|input:/g, "");

      if (this.mode === FORMCONTROLMODE.VIEW && this.mask) {
        this.isEditMode = false;
      }

      if (this.isChanged) {
        this.$emit("changed", id, this.val, this.mode);
        this.$emit("input", this.val);
      }
    },

    changeField(e) {
      this.val = e.currentTarget.value;

      this.isChanged = this.value !== this.val;

      //this.isInvalid = this.required && (!e.currentTarget.value)
      this.test();
    },
    dblclickField(e) {
      if (this.isInvalid) return;
      if (this.readonly) return;

      //let id = e.currentTarget.id.replace(/select:|input:/g, '')

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode;

        this.isEditMode || this.blurField(e);
      }
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal;
    }
  }
};
</script>

<style scoped>
.edit,
.edit:focus {
  background-color: #ffffed;
}

.readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
</style>
