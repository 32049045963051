<template>
  <inline-date-picker
    :id="`datepicker:${$helpers.str2_(properties.name)}`"
    :value-single="value"
    :label="properties.name"
    :mode="mode"
    :required="properties.required"
    :readonly="properties.readonly"
    @changed="onChange"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },

    properties: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    mode() {
      return this.$form.mode(this);
    }
  },
  created() {},
  methods: {
    onChange(e) {
      this.$emit("input", e.valueSingleAsString);

      this.$emit("change", { id: e.id, value: e.valueSingleAsString });
    }
  }
};
</script>

<style></style>
