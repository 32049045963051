import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const PhpLists = () => import("@/views/RelationshipAdministrative/PhpLists");
const PhpListSubmissionForm = () =>
  import("@/views/RelationshipAdministrative/PhpListSubmissionForm");
const ChainsManager = () =>
  import("@/views/RelationshipAdministrative/ChainsManager");
const ChainSubmissionForm = () =>
  import("@/views/RelationshipAdministrative/ChainSubmissionForm");
const DuplicateSearch = () =>
  import("@/views/RelationshipAdministrative/DuplicateSearch/index.js");

export default {
  path: "",
  name: "Relationship administrative",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/relationship-administrative/duplicate-search",
      name: "Duplicate search",
      component: DuplicateSearch,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.DuplicateSearch, icon: "icon-list" }
    },
    {
      path: "/relationship-administrative/chains-manager",
      name: "Chains manager",
      meta: { icon: "icon-list", module: MODULES.Chains },
      component: ChainsManager,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/relationship-administrative/chain/:action/:id?",
      name: "Chain submission",
      meta: { hideFromMenu: true, module: MODULES.Chains },
      component: ChainSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/relationship-administrative/subscriber-lists",
      name: "Subscribers lists",
      meta: { icon: "icon-list", module: MODULES.Subscribers },
      component: PhpLists,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/relationship-administrative/subscriber-list/:action/:id?",
      name: "Subscribers list submission",
      meta: { hideFromMenu: true, module: MODULES.Subscribers },
      component: PhpListSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated
    }
  ]
};
