<template>
  <b-card no-body>
    <b-container fluid class="pb-3">
      <b-row class="pt-3">
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            :dataset-name="model.name"
            :load-dictionaries="loadDictionaries"
            :show-labels="true"
            :hide-custom-filters="false"
            :filters="filteringPanel.filters"
            @search="getData"
            @change="filterData"
            :loaded="filteringPanel.loaded"
            @dict-loaded="onFilteringPanelLoad"
            @custom-filter-selected="onCustomFilterSelected"
            @state-changed="onFilteringPanelStateChange"
          />
        </b-col>
      </b-row>

      <b-row v-show="dataTable.visible">
        <b-col>
          <table-wrapper
            :ref="model.name"
            :model="model"
            :columns="tableColumns"
            :cell-classes="cellClasses"
            :read-only="readOnly"
            :slots="slots"
          >
            <slot
              v-for="slotName in slots"
              :row="props.row"
              :name="slotName"
              :slot="slotName"
              slot-scope="props"
            />
          </table-wrapper>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import FilteringPanel from '@/components/FilteringPanel'
import TableWrapper from './TableWrapper'
import { data } from 'jquery'

export default {
  name: 'DataViewWrapper',
  components: {
    FilteringPanel,
    TableWrapper
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    filters: {
      type: Array,
      default: () => {}
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    cellClasses: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    slots: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      filterEntities: [],
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: []
      },
      dataTable: {
        visible: true
      }
    }
  },
  computed: {},

  created () {
    this.filters.forEach(f => {
      this.filterEntities.push(this.model.entities.find(e => e.name == f))
    })

    this.filterEntities.forEach(e => {
      let f = undefined

      if (e.type === 'dropdown')
        f = {
          type: 'select',
          dataType: 'string',
          tooltip: e.name,
          title: e.name,
          name: this.getFilterName(this.model.name, e.name),
          trackby: 'id',
          label: 'label',
          options: [],
          selected: {},
          multiple: true
        }
      if (e.type === 'date')
        f = {
          type: 'daterange',
          dataType: 'datetime',
          title: e.name,
          tooltip: e.name,
          defaultRange: e.range ?? 'Last 12 months',
          name: this.getFilterName(this.model.name, e.name)
        }

      this.filteringPanel.filters.push(f)
    })
  },
  methods: {
    getFilterName (modelName, entityName) {
      return `${modelName}_${this.$helpers.str2_(entityName)}`
    },
    onFilteringPanelStateChange () {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible
    },
    onFilteringPanelLoad () {
      this.getData()
    },
    onCustomFilterSelected () {
      this.getData()
    },

    async loadDictionaries () {
      let self = this
      let fnArray = []

      this.filterEntities.forEach(entity => {
        if (entity.type === 'dropdown') {
          const fn = () => {
            entity.optionsService().then(response => {
              self.filteringPanel.filters.find(
                f => f.name === this.getFilterName(this.model.name, entity.name)
              ).options = response.map(u => ({
                id: u.id,
                label: u.name
              }))
            })
          }
          fnArray.push(fn())
        }
      })

      await Promise.all(fnArray).then(() => {
        self.filteringPanel.loaded = true
      })
    },
    filterData (e) {
      this.filteringPanel.selected = e
    },
    setFilters (e) {
      // console.log('dataView.setFilters:', e)
      this.$refs.filteringPanel.selected = e
    },
    getFilters () {
      return this.$refs.filteringPanel.selected
    },
    getData () {
      // console.log(
      //   'getData.this.filteringPanel.filters',
      //   this.filteringPanel.filters
      // )

      let fp = this.$refs.filteringPanel

      if (!fp.isSearchAllowed()) return

      this.dataTable.isLoading = true

      this.dataTable.dataSet = []

      let f = fp.selected

      let payload = {}

      this.filteringPanel.filters.forEach(filter => {
        if (filter.type === 'select') {
          payload[filter.name] = fp.selected[filter.name]
            ? fp.selected[filter.name].map(i => i.label)
            : []
        }
        if (
          filter.type === 'daterange' &&
          fp.selected[filter.name] &&
          fp.selected[filter.name].startDate &&
          fp.selected[filter.name].endDate
        ) {
          payload[filter.name] = fp.selected[filter.name]
        }
      })

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f['custom-filter'])
        )
      }

      this.$refs[this.model.name].getData(payload)
    }
  },
  watch: {}
}
</script>

<style></style>
