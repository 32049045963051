import { LOGICAL_OPERATORS } from "@/shared/constants.js";

export function getExpressionTranscription(e, that) {
  let transcription = "";

  let expressions = JSON.parse(e),
    values = [];

  let fieldsExpressions = expressions.filter(
    e => !["dimensions", "measures"].includes(e.fieldName)
  );
  let baseExpressions = expressions.filter(e =>
    ["dimensions", "measures"].includes(e.fieldName)
  );

  fieldsExpressions.forEach((se, sidx) => {
    transcription += `${se.matchingLogic} : [`;

    //process all rules inside field expression
    se.rules.forEach((r, ridx) => {
      /***** TERRITORY LOGIC *****/
      if (
        se.fieldType === "territory" &&
        r.logicalOperator === LOGICAL_OPERATORS.STRING.EQUALS
      ) {
        transcription += `<b>"${se.fieldTitle}"</b> EQUALS <b>[${r.selectedValue.label}]</b>`;
      }
      if (
        se.fieldType === "territory" &&
        r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_EQUAL
      ) {
        transcription += `<b>"${se.fieldTitle}"</b> DOES NOT EQUAL <b>[${r.selectedValue.label}]</b>`;
      }

      values = r.selectedValues.map(l => l.label);
      /***** STRING LOGIC *****/
      if (
        se.fieldType === "string" &&
        r.logicalOperator === LOGICAL_OPERATORS.STRING.EQUALS
      ) {
        transcription += `<b>"${se.fieldTitle}"</b> EQUALS <b>[${values.join(
          ", "
        )}]</b>`;
      }

      if (
        se.fieldType === "string" &&
        r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_EQUAL
      ) {
        transcription += `<b>"${
          se.fieldTitle
        }"</b> DOES NOT EQUAL <b>[${values.join(", ")}]</b>`;
      }

      if (
        se.fieldType === "string" &&
        r.logicalOperator === LOGICAL_OPERATORS.STRING.CONTAINS
      ) {
        transcription += `<b>"${se.fieldTitle}"</b> CONTAINS <b>"${r.selectedValue}"</b>`;
      }

      if (r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN) {
        transcription += `<b>"${se.fieldTitle}"</b> DOES NOT CONTAIN <b>"${r.selectedValue}"</b>`;
      }

      if (r.logicalOperator === LOGICAL_OPERATORS.STRING.IS_EMPTY) {
        transcription += `<b>"${se.fieldTitle}"</b> IS EMPTY`;
      }
      if (r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_EMPTY) {
        transcription += `<b>"${se.fieldTitle}"</b> DOES NOT EMPTY`;
      }

      /***** DATETIME LOGIC *****/

      let selectedDateTimeValue = undefined;

      if (se.fieldType === "datetime")
        selectedDateTimeValue = that.$helpers
          .getDateTimeTemplateValue(r.selectedValue)
          .format("YYYY-MM-DD");

      if (se.fieldType === "datetime") {
        transcription += `<b>"${
          se.fieldTitle
        }"</b> ${r.logicalOperator.toUpperCase()} <b>"${selectedDateTimeValue}"</b>`;
      }

      /***** NUMBERS LOGIC *****/

      if (se.fieldType === "number") {
        transcription += `<b>"${
          se.fieldTitle
        }"</b> ${r.logicalOperator.toUpperCase()} <b>"${r.selectedValue}"</b>`;
      }

      /***** MATCHING LOGIC *****/

      //if match All rules then
      if (se.matchingLogic === "All") {
        if (ridx + 1 < se.rules.length) transcription += " AND ";
      }

      //if match Any rule then combine data
      if (se.matchingLogic === "Any") {
        if (ridx + 1 < se.rules.length) transcription += " OR ";
      }
    });

    if (sidx + 1 < fieldsExpressions.length) transcription += "] AND <br>";
    else transcription += "]<br>";
  });

  //add dimensions and measures
  //transcription += `<br>`
  baseExpressions.forEach(se => {
    se.rules.forEach(r => {
      values = r.selectedValues.map(l => l.label);

      if (se.fieldType === "string") {
        transcription += `${se.fieldTitle}: <b>[${values.join(", ")}]</b>`;
      }
    });
    transcription += "<br>";
  });

  return transcription;
}
