<template>
  <div class="animated fadeIn">
    <!-- single page mode -->
    <div v-if="!moduleId && !readonly">
      <b-row>
        <b-col lg="2">
          <b-button-group>
            <button
              v-b-toggle.collapse-1
              class="btn btn-outline-dark btn"
              type="button"
              @click="addItem()"
            >
              <font-awesome-icon icon="upload" />
            </button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-collapse id="collapse-1" class="mt-3">
        <b-row>
          <b-col lg="3">
            <inline-select
              ref="module"
              :id="controls.module.id"
              :loading="controls.module.isLoading"
              :value="controls.module.value"
              :label="controls.module.label"
              :readonly="false"
              :options="controls.module.options"
              :allow-empty="false"
              :mode="controls.module.mode"
              :required="true"
              @changed="onModuleChange"
            />
          </b-col>
          <b-col lg="5">
            <inline-select
              ref="entity"
              :width="40"
              :id="controls.entity.id"
              :loading="controls.entity.isLoading"
              :value="controls.entity.value"
              :label="controls.entity.label"
              :readonly="false"
              :options="controls.entity.options"
              :allow-empty="false"
              :mode="controls.entity.mode"
              :required="true"
            />
          </b-col>
        </b-row>

        <vue-dropzone
          ref="dropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-sending="vdropzoneSending"
          @vdropzone-complete="vdropzoneComplete"
          @vdropzone-file-added="vdropzoneFileAdded"
        />
        <hr />

        <b-input-group>
          <b-input-group-append>
            <b-button variant="outline-dark">
              <font-awesome-icon icon="paste" @click="pasteUrl" />
            </b-button>
            <b-button variant="outline-dark">
              <font-awesome-icon icon="save" @click="onSaveClick" />
            </b-button>
          </b-input-group-append>
          <b-form-input
            placeholder="or insert link to cloud storage"
            v-model="controls.urlInput"
          />
        </b-input-group>
      </b-collapse>
    </div>
    <!-- nested table mode -->
    <div v-if="moduleId && !readonly">
      <b-row v-if="showCategory">
        <b-col lg="4" class="mb-2">
          <inline-select
            ref="category"
            :id="`${uid}-${controls.category.id}`"
            :loading="controls.category.isLoading"
            :value="controls.category.value"
            :label="controls.category.label"
            :readonly="false"
            :options="controls.category.options"
            :allow-empty="false"
            :mode="controls.category.mode"
            :required="false"
            @changed="onCategoryChange"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-overlay
            :show="!controls.category.value.id && showCategory"
            :opacity="0.8"
            spinner-variant="secondary"
            rounded="sm"
          >
            <template #overlay>
              Please select a file category before uploading the file
            </template>
            <vue-dropzone
              class="nested-dropzone"
              v-if="moduleId"
              ref="dropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-sending="vdropzoneSending"
              @vdropzone-complete="vdropzoneComplete"
              @vdropzone-file-added="vdropzoneFileAdded"
            />
            <hr />
            <b-row v-if="showCategory">
              <b-col lg="12" class="mb-2">
                <b-input-group>
                  <b-input-group-append>
                    <b-button variant="outline-dark">
                      <font-awesome-icon icon="paste" @click="pasteUrl" />
                    </b-button>

                    <b-button variant="outline-dark">
                      <font-awesome-icon icon="save" @click="saveUrl" />
                    </b-button>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="or insert link to cloud storage"
                    v-model="controls.urlInput"
                  />
                </b-input-group>
              </b-col>
              <!--
              <b-col>
                <b-button
                  variant="outline-dark"
                  style="width: 10em"
                  :disabled="
                    !controls.urlInput && !controls.existing_file.value
                  "
                  @click="onSaveClick"
                >
                  <font-awesome-icon icon="save" /> Save
                </b-button>
              </b-col>
              -->
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
    <hr />
    <span>
      <h5>
        Files
        <clip-loader
          class="ml-3 mt-2"
          :size="14"
          color="#36D7B7"
          :loading="this.isLoading"
        />
      </h5>
    </span>
    <v-client-table
      v-if="dataTable.dataSet.length > 0"
      ref="dataTable"
      name="client_table_files_container"
      @update="onInlineTableUpdate"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      :columns="dataTable.columns"
    >
      <div slot="Entity" slot-scope="props">
        <b-link @click="onRowEntityClick(props.row)">
          {{ props.row["Entity"] }}
        </b-link>
      </div>
      <div
        slot="File Name"
        slot-scope="{ row, update, setEditing, isEditing, revertValue }"
      >
        <span @click="setEditing(true)" v-if="!isEditing() || readonly">{{
          row["File Name"]
        }}</span>
        <span
          @click="setEditing(true)"
          v-if="!isEditing() && !row['File Name']"
          class="pl-5"
        >
          <b-img
        /></span>

        <span
          class="d-flex flex-row align-items-center"
          v-if="isEditing() && !readonly"
        >
          <!--     <input v-if="isEditing()" class="form-control  flex-grow-1" type="text" v-model="row['File Name']" @input="onColumnUpdate(row['ID'], 'File Name', row['File Name'])" />-->

          <b-input-group>
            <template #prepend v-if="row['fname_prefix']">
              <b-input-group-text>{{ row["fname_prefix"] }}</b-input-group-text>
            </template>
            <b-form-input
              v-if="isEditing()"
              v-model="row['fname_body']"
              @input="onColumnUpdate(row['ID'], 'File Name', row['fname_body'])"
            />
            <template #append v-if="row['fname_ext']">
              <b-input-group-text>{{ row["fname_ext"] }}</b-input-group-text>
            </template>
          </b-input-group>
          <b-button
            variant="success"
            size="sm"
            class="m-1"
            @click="
              if (validateCell('File Name', row['File Name'])) {
                update(row['fname_body']);
                setEditing(false);
              }
            "
          >
            <font-awesome-icon icon="check" />
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="
              revertValue();
              setEditing(false);
            "
          >
            <font-awesome-icon icon="ban" />
          </b-button>
        </span>
      </div>
      <div slot="Actions" slot-scope="props">
        <div class="btn-group">
          <b-button
            v-if="props.row['is_url'] == 0"
            variant="success"
            size="sm"
            @click="downloadItem(props.row)"
          >
            <font-awesome-icon icon="download" />
          </b-button>
          <b-button
            v-if="props.row['is_url'] == 1"
            variant="primary"
            size="sm"
            @click="openUrl(props.row)"
          >
            <font-awesome-icon icon="external-link-alt" />
          </b-button>

          <b-button
            variant="danger"
            size="sm"
            @click="deleteItem(props.row.ID)"
            v-if="!readonly"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
        </div>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import axios from "axios";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import InlineSelect from "@/components/InlineSelectMultiple";

import { FORMCONTROLMODE, MODULES } from "@/shared/constants.js";

import Vue from "vue";

export default {
  name: "Documents",
  components: {
    vueDropzone: vue2Dropzone,
    InlineSelect
  },
  props: {
    moduleId: {
      type: [String, Number],
      default: ""
    },
    entityId: {
      type: [String, Number],
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showCategory: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      controls: {
        urlInput: "",
        module: {
          id: "input:module",
          label: "Related module",
          changed: false,
          readonly: false,
          mode: FORMCONTROLMODE.EDIT,
          options: [],
          isLoading: false,
          value: []
        },
        category: {
          id: "input:category",
          label: "File category",
          changed: false,
          readonly: false,
          mode: FORMCONTROLMODE.EDIT,
          options: [],
          isLoading: false,
          value: {}
        },
        existing_file: {
          id: "input:existing_file",
          options: [],
          value: undefined
        },
        entity: {
          id: "input:entity",
          label: "Related entity",
          changed: false,
          readonly: false,
          mode: FORMCONTROLMODE.EDIT,
          options: [],
          isLoading: false,
          value: []
        }
      },
      filterPanel: {
        selected: {},
        filters: [
          {
            type: "select",
            title: "Module",
            name: "module",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            placeholder: "Select module",
            selected: {}
          },
          {
            type: "select",
            title: "Owner",
            name: "owner",
            trackby: "id",
            label: "label",
            options: [],
            multiple: true,
            placeholder: "Select owner",
            selected: {}
          },
          {
            type: "daterange",
            defaultRange: "7 years",
            title: "Created Date",
            name: "created-date"
          }
        ]
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + "/files",
        uploadMultiple: true,
        parallelUploads: 10,
        thumbnailWidth: 50,
        resizeWidth: 2500,
        resizeQuality: 0.8,
        maxFilesize: 10,
        headers: {
          Authorization: this.$store.getters["authToken"],
          "Cache-Control": null,
          "X-Requested-With": null
        }
      },
      dataTable: {
        options: {
          uniqueKey: "ID",
          filterByColumn: true,
          filterable: [
            "Module",
            "Entity",
            "Category",
            "File Name",
            "Size",
            "Created Date",
            "Owner"
          ],
          editableColumns: ["File Name"],
          perPage: 50,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: [
          "ID",
          "Module",
          "Entity",
          "Category",
          "File Name",
          "Size",
          "Created Date",
          "Owner",
          "Actions"
        ],
        dataSet: [],
        totalRecords: 0
      },
      modules: {
        options: []
      },
      owners: {
        options: []
      }
    };
  },
  computed: {
    count() {
      return this.dataTable.dataSet.length;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    onInlineTableUpdate(payload) {
      let self = this;
      let data = {
        id: payload.row["ID"]
      };

      if (payload.column === "File Name") {
        data.filename = payload["oldVal"];
      }

      this.onColumnUpdate(data.id, payload.column, payload["newVal"]);

      this.$api
        .put(`files/${data.id}`, data)
        .then(response => {
          self.$form.makeToastInfo(response.message);
        })
        .catch(function(error) {
          self.$form.makeToastError(error.response.statusText);

          self.drawDataTable();
        });
    },
    validateCell(field, value) {
      if (field === "File Name" && value.trim() === "") {
        this.$form.makeToastError("Please enter file name or link to file");
        return false;
      }

      return true;
    },
    onColumnUpdate(id, column, value) {
      let fileIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let file = this.dataTable.dataSet[fileIdx];

      file["fname_body"] = value;

      file[column] = file["fname_prefix"] + value + file["fname_ext"];

      Vue.set(this.dataTable.dataSet, fileIdx, file);
    },
    pasteUrl: async function() {
      const text = await navigator.clipboard.readText();

      this.controls.urlInput = text;
    },
    openUrl: async function(row) {
      window.open(row["url"], "_blank");
    },
    onSaveClick() {
      if (this.controls.urlInput) this.saveUrl();

      if (this.controls.existing_file.value.id) this.saveExistingFile();
    },
    saveExistingFile() {
      if (!this.moduleId && !this.entityId) {
        if (!this.validateControls()) return;
      }

      let data = {
        file_id: this.controls.existing_file.value.id,
        module_id: this.moduleId || this.$refs.module.val.id,
        entity_id: this.entityId || this.$refs.entity.val.id,
        category_id: this.controls.category.value.id || 0
      };

      this.$api
        .post("files/existing", data)
        .then(response => {
          this.controls.existing_file.value = {};

          this.$form.makeToastInfo(response.message);

          this.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          this.$form.makeToastError(response.message);
        });
    },

    saveUrl() {
      if (!this.moduleId && !this.entityId) {
        if (!this.validateControls()) return;
      }

      if (!this.controls.urlInput) {
        this.$form.msgBoxOk("Please insert link to file");
        return;
      }

      let data = {
        filename: this.controls.urlInput,
        module_id: this.moduleId || this.$refs.module.val.id,
        entity_id: this.entityId || this.$refs.entity.val.id,
        is_url: true,
        category_id: this.controls.category.value.id || 0
      };
      console.log("data", data);
      this.$api
        .post("files", data)
        .then(response => {
          this.controls.urlInput = "";

          this.$form.makeToastInfo(response.message);

          this.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          this.$form.makeToastError(response.message);
        });
    },
    onRowEntityClick(row) {
      let routeName = "";
      if (row["Module"] === "Accounts") routeName = "Account submission";
      if (row["Module"] === "Activities") routeName = "Activity submission";
      if (row["Module"] === "Projects") routeName = "Project submission";
      if (row["Module"] === "Tasks") routeName = "Task submission";
      if (row["Module"] === "Reports") routeName = "Report submission";
      if (row["Module"] === "Invoices") routeName = "Invoice submission";
      if (row["Module"] === "Receipts") routeName = "Receipt submission";
      if (row["Module"] === "Incentives") routeName = "Incentive submission";
      if (row["Module"] === "Reimbursements")
        routeName = "Reimbursement submission";
      if (row["Module"] === "Inventory") routeName = "Order submission";

      this.$router.push({
        name: routeName,
        params: {
          action: "view",
          id: row["entity_id"]
        }
      });
      /*
            let routeData = this.$router.resolve({
                name: routeName,
                params: {
                    action: "view",
                    id: row["entity_id"]
                }
            });
            window.open(routeData.href, '_blank');
*/
    },
    validateControls() {
      if (!this.$refs.module.test()) {
        this.$form.msgBoxOk("Please select related module");

        return false;
      }
      if (!this.$refs.entity.test()) {
        this.$form.msgBoxOk("Please select related entity");

        return false;
      }
      return true;
    },
    vdropzoneFileAdded() {
      console.log("vdropzoneFileAdded");
      if (this.moduleId && this.entityId) return;

      if (!this.validateControls()) this.$refs.dropzone.removeAllFiles();
    },
    vdropzoneSending(file, xhr, formData) {
      //console.log("vdropzoneSending", file, formData);
      //const invalidCharsRegex = /[<>:"\/\\|?*\x00-\x7F]/g;
      //const fileName = file.name.replace("â¯", "_");
      //console.log("fileName", fileName);

      formData.append("filename", file.name);
      formData.append("filesize", file.size);
      formData.append("module_id", this.moduleId || this.$refs.module.val.id);
      formData.append("entity_id", this.entityId || this.$refs.entity.val.id);
      formData.append("category_id", this.controls.category.value.id || 0);

      console.log("formData", formData);
    },
    vdropzoneComplete(response) {
      console.log("vdropzoneComplete.response:", response);

      if (response.status === "success") {
        this.$form.makeToastInfo(response.status);

        this.drawDataTable();
      } else {
        this.$form.makeToastError(response.status);
      }

      this.$refs.dropzone.removeAllFiles();
    },
    getData(e) {
      if (e) this.filterPanel.selected = e;

      let self = this;

      self.$api.get("dictionaries/file-categories").then(response => {
        self.controls.category.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      });

      /*
      self.$api.get("inventory/files").then(response => {
        self.controls.existing_file.options = response.map(u => ({
          id: u["ID"],
          label: u["File Name"]
        }));
      });
      */
      if (!this.moduleId) {
        self.controls.module.isLoading = true;
        self.$api.get("dictionaries/modules").then(response => {
          self.controls.module.isLoading = false;

          self.modules.options = response.map(u => ({
            id: u.id.toString(),
            label: u.name
          }));

          self.modules.options.find(
            m => m.label === "Donations"
          ).$isDisabled = true;

          self.controls.module.options = self.modules.options;
        });
      }

      if (
        (this.moduleId && this.entityId) ||
        (!this.moduleId && !this.entityId)
      ) {
        this.drawDataTable();
      }
    },
    async drawDataTable() {
      // if (!this.entityId || !this.moduleId) return;

      this.isLoading = true;

      let url = "files";

      if (this.moduleId === MODULES.Reports.id)
        url = `users/reports/${this.entityId}/files`;
      if (this.moduleId === MODULES.Tasks.id)
        url = `tasks/${this.entityId}/files`;
      if (this.moduleId === MODULES.Accounts.id)
        url = `accounts/${this.entityId}/files`;
      if (this.moduleId === MODULES.Activities.id)
        url = `activities/${this.entityId}/files`;
      if (this.moduleId === MODULES.Projects.id)
        url = `projects/${this.entityId}/files`;
      if (this.moduleId === MODULES.Contacts.id)
        url = `contacts/${this.entityId}/files`;
      if (this.moduleId === MODULES.Invoices.id)
        url = `invoices/${this.entityId}/files`;
      if (this.moduleId === MODULES.Receipts.id)
        url = `receipts/${this.entityId}/files`;
      if (this.moduleId === MODULES.Reimbursements.id)
        url = `reimbursements/${this.entityId}/files`;
      if (this.moduleId === MODULES.Incentives.id)
        url = `incentives/${this.entityId}/files`;
      if (this.moduleId === MODULES.Samples.id)
        url = `sample-requests/${this.entityId}/files`;
      if (this.moduleId === MODULES.InventoryOrders.id)
        url = `inventory/order/${this.entityId}/files`;
      if (this.moduleId === MODULES.WarehouseIncomingOrders.id)
        url = `warehouse/incoming-order/${this.entityId}/files`;
      if (this.moduleId === MODULES.WarehousePickLists.id)
        url = `warehouse/pick-list/${this.entityId}/files`;
      if (this.moduleId === MODULES.WarehouseInvoices.id)
        url = `warehouse/invoice/${this.entityId}/files`;

      let response = await this.$api.get(url);

      this.rawData = response;

      this.rawData.forEach(row => {
        let fullName = row["File Name"];
        row["fname_prefix"] = "";
        row["fname_body"] = "";
        row["fname_ext"] = "";

        /*
                if (fullName.startsWith('http://')) {

                    row['fname_prefix'] = 'http://'
                    row['fname_body'] = fullName.replace('http://', '')

                } else if (fullName.startsWith('https://')) {

                    row['fname_prefix'] = 'https://'
                    row['fname_body'] = fullName.replace('https://', '')

                } else
                */

        if (
          row["is_url"] == "0" &&
          fullName.substring(0, fullName.lastIndexOf("."))
        ) {
          row["fname_body"] = fullName.substring(0, fullName.lastIndexOf("."));
          row["fname_ext"] = "." + fullName.split(".").pop();
        } else {
          row["fname_body"] = fullName;
        }
      });

      this.isLoading = false;

      this.dataTable.dataSet = this.rawData;

      //  this.filterData(this.filterPanel.selected);

      this.$emit("loaded", this.dataTable.dataSet.length);

      if (this.moduleId) {
        this.dataTable.columns = !this.showCategory
          ? ["File Name", "Size", "Created Date", "Owner", "Actions"]
          : [
              "Category",
              "File Name",
              "Size",
              "Created Date",
              "Owner",
              "Actions"
            ];
        this.dataTable.options.filterByColumn = false;
        this.dataTable.options.filterable = [];
      }
    },

    addItem: function() {},

    viewItem: function() {},

    downloadItem: function(row) {
      axios({
        url: process.env.VUE_APP_API_URL + `/files/${row.ID}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          //const contentDisposition = response.headers['content-disposition'];
          let fileName = row["File Name"];

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(response => {
          console.log(response);

          this.$form.makeToastError(response.message);
        });
    },
    deleteItem: async function(id) {
      let file = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `File #${file["File Name"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`files/${file.ID}`)
        .then(() => {
          self.$form.makeToastInfo("File deleted");

          self.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.data.message);

          self.drawDataTable();
        });
    },
    onCategoryChange(id, val) {
      this.controls.category.value = val;
    },
    onModuleChange(id, val) {
      let url = "";
      let self = this;

      if (val.label === "Accounts") url = "dictionaries/accounts";
      if (val.label === "Activities") url = "dictionaries/activities";
      if (val.label === "Projects") url = "dictionaries/projects";
      if (val.label === "Tasks") url = "dictionaries/tasks";
      if (val.label === "Reports") url = "dictionaries/reports";
      if (val.label === "Receipts") url = "dictionaries/receipts";

      self.controls.entity.options = [];

      if (!url) return;

      self.controls.entity.isLoading = true;

      self.$api.get(url).then(response => {
        self.controls.entity.isLoading = false;

        self.controls.entity.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      });
    }
  },
  watch: {}
};
</script>

<style scoped>
.nested-dropzone {
  min-height: 50px;
  padding: 0px;
}
::v-deep .multiselect__tags {
  height: 37px;
  min-height: 37px;
  padding: 5px 37px 0 5px;
}
</style>
