import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

export default {
  path: "",
  name: "Production",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/production/mashing",
      name: "Mashing",
      component: () => import("@/views/Production/Mashing/Mashing"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/mashing/:action/:id?",
      name: "Mash submission",
      component: () => import("@/views/Production/Mashing/MashSubmissionForm"),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },

    {
      path: "/production/fermentation",
      name: "Fermentation",
      component: () => import("@/views/Production/Fermentation/Fermentation"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/fermentation/:action/:id?",
      name: "Fermentation submission",
      component: () =>
        import("@/views/Production/Fermentation/FermentationSubmissionForm"),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/distillation",
      name: "Distillation",
      component: () => import("@/views/Production/Distillation/Distillation"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/distillation/:action/:id?",
      name: "Distillation submission",
      component: () =>
        import("@/views/Production/Distillation/DistillationSubmissionForm"),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/alcohol-finishing",
      name: "Alcohol finishing",
      component: () =>
        import("@/views/Production/AlcoholFinishing/AlcoholFinishing"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/alcohol-finishing/:action/:id?",
      name: "Alcohol finishing submission",
      component: () =>
        import(
          "@/views/Production/AlcoholFinishing/AlcoholFinishingSubmissionForm"
        ),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/bottling",
      name: "Bottling",
      component: () => import("@/views/Production/Bottling/index.js"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/bottling/:action/:id?",
      name: "Bottling submission",
      component: () =>
        import("@/views/Production/Bottling/BottlingSubmissionForm"),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/barrel-filling",
      name: "Barrel filling",
      component: () => import("@/views/Production/BarrelFilling/BarrelFilling"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/barrel-filling/:action/:id?",
      name: "Barrel filling submission",
      component: () =>
        import("@/views/Production/BarrelFilling/BarrelFillingSubmissionForm"),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/barrels",
      name: "Barrels overview",
      component: () => import("@/views/Production/Barrels/Barrels"),
      meta: { module: MODULES.Production, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/production/barrel/:action/:id?",
      name: "Production barrel submission",
      component: () =>
        import("@/views/Production/Barrels/BarrelSubmissionForm"),
      props: true,
      meta: { module: MODULES.Production, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: "",
      name: "Production settings",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      meta: { module: MODULES.Production, icon: "icon-settings" },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: "/production/base-products",
          name: "Base products",
          component: () =>
            import("@/views/Production/BaseProducts/BaseProducts"),
          meta: { module: MODULES.Production, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/base-products/:action/:id?",
          name: "Base product submission",
          component: () =>
            import("@/views/Production/BaseProducts/BaseProductSubmissionForm"),
          props: true,
          meta: { module: MODULES.Production, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/finished-products",
          name: "Finished products",
          component: () =>
            import("@/views/Production/FinishedProducts/FinishedProducts"),
          meta: { module: MODULES.Production, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/finished-product/:action/:id?",
          name: "Finished product submission",
          component: () =>
            import(
              "@/views/Production/FinishedProducts/FinishedProductSubmissionForm"
            ),
          props: true,
          meta: { module: MODULES.Production, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        }
      ]
    },
    {
      path: "",
      name: "Equipment",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      meta: { module: MODULES.Production, icon: "icon-settings" },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: "/production/mash-tanks",
          name: "Mash tanks",
          component: () => import("@/views/Production/MashTanks/MashTanks"),
          meta: { module: MODULES.Production, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/mash-tanks/:action/:id?",
          name: "Mash tank submission",
          component: () =>
            import("@/views/Production/MashTanks/MashTankSubmissionForm"),
          props: true,
          meta: { module: MODULES.Production, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/fermentation-containers",
          name: "Fermentation containers",
          component: () =>
            import(
              "@/views/Production/FermentationContainers/FermentationContainers"
            ),
          meta: { module: MODULES.Production, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/fermentation-container/:action/:id?",
          name: "Fermentation container submission",
          component: () =>
            import(
              "@/views/Production/FermentationContainers/FermentationContainerSubmissionForm"
            ),
          props: true,
          meta: { module: MODULES.Production, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/distillation-containers",
          name: "Distillation containers",
          component: () =>
            import(
              "@/views/Production/DistillationContainers/DistillationContainers"
            ),
          meta: { module: MODULES.Production, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/distillation-container/:action/:id?",
          name: "Distillation container submission",
          component: () =>
            import(
              "@/views/Production/DistillationContainers/DistillationContainerSubmissionForm"
            ),
          props: true,
          meta: { module: MODULES.Production, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/storage-containers",
          name: "Storage containers",
          component: () =>
            import("@/views/Production/StorageContainers/StorageContainers"),
          meta: { module: MODULES.Production, icon: "icon-list" },
          beforeEnter: ifAuthenticated
        },
        {
          path: "/production/storage-container/:action/:id?",
          name: "Storage container submission",
          component: () =>
            import(
              "@/views/Production/StorageContainers/StorageContainerSubmissionForm"
            ),
          props: true,
          meta: { module: MODULES.Production, hideFromMenu: true },
          beforeEnter: ifAuthenticated
        }
      ]
    }
  ]
};
