import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

export default {
  path: "",
  name: "Koval Benefits",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/benefits/health-insurance",
      name: "Health Insurance",
      component: () => import("@/views/Benefits/HealthInsurance"),
      meta: { module: MODULES.Benefits, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/benefits/401-k",
      name: "401(k)",
      component: () => import("@/views/Benefits/401k"),
      meta: { module: MODULES.Benefits, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/benefits/bright-directions",
      name: "Bright Directions",
      component: () => import("@/views/Benefits/BrightDirections"),
      meta: { module: MODULES.Benefits, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/benefits/fsa-dca",
      name: "FSA/DCA",
      component: () => import("@/views/Benefits/FSA-DCA"),
      meta: { module: MODULES.Benefits, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    }
  ]
};
