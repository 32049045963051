var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    staticClass: "form-group",
    style: _vm.style
  }, [_vm.label ? _c('label', {
    attrs: {
      "for": "id"
    }
  }, [_vm._v(_vm._s(_vm.label) + " "), _c('clip-loader', {
    staticClass: "ml-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": _vm.loading
    }
  })], 1) : _vm._e(), !_vm.linkMode && !_vm.isEditMode || !_vm.linkMode && _vm.isEditMode && _vm.readonly ? _c('input', {
    class: ['form-control', _vm.readonly ? 'readonly' : ''],
    attrs: {
      "readonly": _vm.readonly ? _vm.readonly : !_vm.isEditMode,
      "type": "text"
    },
    domProps: {
      "value": _vm.multiple ? _vm.val.map(function (e) {
        return e.label;
      }).join(', ') : _vm.val ? _vm.val.label : ''
    },
    on: {
      "change": function change($event) {
        return _vm.changeField($event);
      },
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      }
    }
  }) : _vm._e(), _vm.val && (!_vm.multiple && _vm.linkMode && !_vm.isEditMode || _vm.readonly && !_vm.multiple && _vm.linkMode) ? _c('div', {
    staticClass: "pl-2 pt-2",
    on: {
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      }
    }
  }, [_c('b-link', {
    attrs: {
      "href": _vm.val.url ? _vm.val.url : '#',
      "target": "_self",
      "for": _vm.id
    },
    on: {
      "click": function click($event) {
        return _vm.clickLink(_vm.val);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.val.label) + " ")])], 1) : _vm._e(), _vm.val && _vm.val.length > 0 && (!_vm.isEditMode && _vm.multiple && _vm.linkMode || _vm.readonly && _vm.multiple && _vm.linkMode) ? _c('div', {
    on: {
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      }
    }
  }, _vm._l(_vm.val, function (item, index) {
    return _c('b-link', {
      key: "acc-link-".concat(index),
      staticStyle: {
        "display": "flex"
      },
      attrs: {
        "href": _vm.val.url ? _vm.val.url : '#',
        "target": "_self",
        "for": item.id
      },
      on: {
        "click": function click($event) {
          return _vm.clickLink(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1) : _vm._e(), !_vm.readonly && _vm.isEditMode && !_vm.group_values ? _c('multiselect', {
    class: [_vm.isInvalid ? 'is-invalid' : ''],
    attrs: {
      "id": _vm.id,
      "options": _vm.options,
      "value": _vm.val,
      "track-by": "id",
      "label": "label",
      "deselect-label": "",
      "select-label": "",
      "placeholder": "",
      "multiple": _vm.multiple,
      "allow-empty": _vm.allowEmpty,
      "loading": _vm.loading,
      "taggable": _vm.taggable,
      "option-height": 250,
      "tag-placeholder": "Add this as new value"
    },
    on: {
      "search-change": _vm.onSearchChange,
      "open": _vm.openSelect,
      "close": _vm.closeSelect,
      "input": _vm.changeSelect,
      "tag": _vm.addTag,
      "select": _vm.onSelect,
      "remove": _vm.onRemove
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(props) {
        return _vm.customOptionTemplate ? [_vm._t("singleLabel", null, {
          "slotScope": props
        })] : undefined;
      }
    }, {
      key: "option",
      fn: function fn(props) {
        return _vm.customOptionTemplate ? [_vm._t("option", null, {
          "slotScope": props
        })] : undefined;
      }
    }], null, true)
  }, [_vm.async ? _c('template', {
    slot: "beforeList"
  }, [_c('b-button-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-button', {
    attrs: {
      "pressed": _vm.startsWith,
      "squared": "",
      "size": "sm",
      "variant": 'ligth'
    },
    on: {
      "update:pressed": function updatePressed($event) {
        _vm.startsWith = $event;
      }
    }
  }, [!_vm.startsWith ? _c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'square']
    }
  }) : _vm._e(), _vm.startsWith ? _c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'check-square']
    }
  }) : _vm._e(), _vm._v(" Starts with... ")], 1)], 1)], 1) : _vm._e()], 2) : _vm._e(), !_vm.readonly && _vm.isEditMode && _vm.group_values ? _c('multiselect', {
    class: [_vm.isInvalid ? 'is-invalid' : ''],
    attrs: {
      "id": _vm.id,
      "value": _vm.val,
      "track-by": "id",
      "label": "label",
      "group-values": _vm.group_values,
      "group-label": _vm.group_label,
      "group-select": _vm.multiple,
      "options": _vm.options,
      "deselect-label": "",
      "select-label": "",
      "placeholder": "",
      "multiple": _vm.multiple,
      "allow-empty": _vm.allowEmpty,
      "loading": _vm.loading
    },
    on: {
      "search-change": _vm.onSearchChange,
      "open": _vm.openSelect,
      "close": _vm.closeSelect,
      "input": _vm.changeSelect,
      "select": _vm.onSelect,
      "remove": _vm.onRemove
    }
  }, [_vm.async ? _c('template', {
    slot: "beforeList"
  }, [_c('b-button-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-button', {
    attrs: {
      "pressed": _vm.startsWith,
      "squared": "",
      "size": "sm",
      "variant": 'ligth'
    },
    on: {
      "update:pressed": function updatePressed($event) {
        _vm.startsWith = $event;
      }
    }
  }, [!_vm.startsWith ? _c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'square']
    }
  }) : _vm._e(), _vm.startsWith ? _c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'check-square']
    }
  }) : _vm._e(), _vm._v(" Starts with... ")], 1)], 1)], 1) : _vm._e()], 2) : _vm._e(), _vm.isInvalid ? _c('div', {
    staticClass: "invalid-feedback d-block"
  }, [!_vm.hideLabel ? _c('span', [_vm._v("Field '" + _vm._s(_vm.label) + "' is required")]) : _vm._e(), _vm.hideLabel ? _c('span', [_vm._v("Field is required")]) : _vm._e()]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }