import { ifAuthenticated } from './helpers'
import { MODULES } from '@/shared/constants'

const Profile = () => import('@/views/Settings/Profile')
const Territories = () => import('@/views/Settings/Territories')
const Jobs = () => import('@/views/Settings/Jobs')
const CrmLogs = () => import('@/views/Settings/Logs/CrmLogs')

export default {
  path: '',
  name: 'Settings',
  meta: { icon: 'icon-star' },
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/settings/profile',
      name: 'Profile',
      meta: { icon: 'icon-user', module: MODULES.Settings },

      component: Profile,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/settings/territories',
      name: 'Territories',
      meta: { icon: 'icon-globe', module: MODULES.Settings },

      component: Territories,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/settings/jobs',
      name: 'Jobs',
      meta: { icon: 'icon-list', module: MODULES.Settings },

      component: Jobs,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/settings/logs',
      name: 'Logs',
      meta: { icon: 'icon-list', module: MODULES.Settings },

      component: CrmLogs,
      beforeEnter: ifAuthenticated
    }
  ]
}
