import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const MyReports = () => import("@/views/Reports/MyReports");
const ReportSubmissionForm = () =>
  import("@/views/Reports/ReportSubmissionForm");
const AllReports = () => import("@/views/Reports/AllReports");
const ReportsMap = () => import("@/views/Reports/ReportsMap");

export default {
  path: "",
  name: "Reports",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/reports/reports-submission",
      name: "Reports submission",
      component: ReportsMap,
      props: { mode: "submission" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list" }
    },
    {
      path: "/reports/my-reports",
      name: "My reports",
      component: MyReports,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list" }
    },
    {
      path: "/reports/review-reports",
      name: "Review reports",
      component: AllReports,
      props: { filter: "review" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list" }
    },
    {
      path: "/reports/report-submission/:action/:id?",
      name: "Report submission",
      component: ReportSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, hideFromMenu: true }
    },
    {
      path:
        "/reports/report-submission-ext/create/:pCategoryId/:pDepartmentId/:pFrom/:pTo",
      name: "Report submission External",
      component: ReportSubmissionForm,
      props: { action: "create" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list", hideFromMenu: true }
    }
  ]
};
