import { api } from '@/shared/api'

const helpers = {
  getCustomTableName (module) {
    return `ct_${module.name.toLowerCase()}`
  },
  getDatasetKeys (module) {
    let result = sessionStorage.getItem(
      `vuetables_${this.getCustomTableName(module)}_keys`
    )

    result = JSON.parse(result)

    return result
  },
  getPrevNextKeys (module, id) {
    let data = this.getDatasetKeys(module)

    if (!data || !id) return { prev: false, next: false }

    let keyIndex = data.findIndex(e => e === id)

    let prev = keyIndex > 0 ? data[keyIndex - 1] : false,
      next = keyIndex < data.length - 1 ? data[keyIndex + 1] : false

    return { prev: prev, next: next }
  },
  getPrevKey (module, id) {
    return this.getPrevNextKeys(module, id).prev
  },
  getNextKey (module, id) {
    return this.getPrevNextKeys(module, id).next
  },
  getDatasetServerFilters (module) {
    //console.log("getDatasetServerFilters", module);
    let result = sessionStorage.getItem(
      `vuetables_${this.getCustomTableName(module)}_filters`
    )

    //console.log("getDatasetServerFilters", result);

    //   result = JSON.parse(result);

    return result
  },
  setDatasetServerFilters (module, payload) {
    //console.log("setDatasetServerFilters", payload);
    sessionStorage.setItem(
      `vuetables_${this.getCustomTableName(module)}_filters`,
      JSON.stringify(payload)
    )
  },
  saveAppliedFilters (module, filters) {
    sessionStorage.setItem(
      `vuetables_${this.getCustomTableName(module)}_filters`,
      JSON.stringify(filters)
    )
  },
  saveServerPrevNextListToSession (module, data) {
    sessionStorage.setItem(
      `vuetables_${this.getCustomTableName(module)}_prevnext_list`,
      JSON.stringify(data)
    )
  },
  getPrevNextServerListFromSession (module) {
    let list = sessionStorage.getItem(
      `vuetables_${this.getCustomTableName(module)}_prevnext_list`
    )

    list = JSON.parse(list)

    return list
  },

  /*
   * module { id: Number, name: String}
   * value { id: Number, page: Number}
   */
  async getPrevNextServerKeys (payload) {
    //console.log("getPrevNextServerKeys, payload", JSON.stringify(payload));

    let values = this.searchPrevNextValuesInSession(payload.module, payload.id)

    //if both value exist then return
    if (values.prev && values.next) return values

    //if not exists then request server filters
    let filters = this.getDatasetServerFilters(payload.module)

    console.log('server filters:', filters)

    filters = JSON.parse(filters)

    if (!filters)
      filters = {
        serverOptions: { pagination: { currentPage: 1, rowsPerPage: 10 } }
      }

    //allows to emulate pagination during fetching prev and next values
    if (
      +payload.page &&
      filters.serverOptions.pagination.currentPage !== +payload.page
    ) {
      filters.serverOptions.pagination.currentPage = payload.page
      this.setDatasetServerFilters(payload.module, filters)
    }

    //and make request to server

    //console.log("REQUEST TO SERVER");

    //"accounts"
    //payload.module.name.toLowerCase()
    let response = await api.post(payload.module.name.toLowerCase(), filters)

    //console.log("response", response);

    let prevNextArray = []
    if (response.prev_next_list)
      prevNextArray = response.prev_next_list.map(i => ({
        id: i['ID'],
        page: i['page']
      }))

    //save response
    this.saveServerPrevNextListToSession(payload.module, {
      currentPage: filters.serverOptions.pagination.currentPage,
      data: prevNextArray
    })

    values = this.findPrevNextServerValues(prevNextArray, payload.id)

    return values
  },

  searchPrevNextValuesInSession (module, id) {
    let values = { prev: false, next: false }

    //check if list exists in sessions
    let prevNextSessionObject = this.getPrevNextServerListFromSession(module)

    if (prevNextSessionObject) {
      //find values and return

      values = this.findPrevNextServerValues(prevNextSessionObject.data, id)
    }

    //console.log("searchValuesInSession", JSON.stringify(values));
    return values
  },
  findPrevNextServerValues (list, id) {
    //console.log("findPrevNextServerValues", JSON.stringify(list), id);
    let keyIndex = list.findIndex(e => e.id === id)

    let prev = keyIndex > 0 ? list[keyIndex - 1] : false,
      next = keyIndex < list.length - 1 ? list[keyIndex + 1] : false

    return { prev: prev, next: next }
  }
}

export default helpers
