import Vue from "vue";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
Vue.use(PerfectScrollbar);

import { VueSpinners } from "@saeris/vue-spinners";
Vue.use(VueSpinners);

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDPm_pLRVSsqrEqye4f4zJD0ZR-cE9Cbdk"
  }
});
import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component("GmapCluster", GmapCluster);

import uid from "vue-unique-id";
Vue.use(uid);

import VueAuthImage from "vue-auth-image";
Vue.use(VueAuthImage);

import { ClientTable } from "vue-tables-2";
/*Vue.use(ClientTable);*/

import MyCell from "@/components/TableCustom/MyCell";

Vue.use(ClientTable, {}, false, "bootstrap4", {
  tableCell: MyCell
});
