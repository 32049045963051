var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    staticClass: "form-group"
  }, [!_vm.hideLabel ? _c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.id
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), !_vm.isTextArea && !_vm.mask || _vm.mask && !_vm.isEditMode ? _c('input', {
    ref: _vm.id,
    class: ['form-control', _vm.isInvalid && _vm.isEditMode ? 'is-invalid' : '', _vm.readonly && !_vm.isEditMode ? 'readonly' : '', _vm.isEditMode || _vm.mode === _vm.FORMCONTROLMODE.EDIT ? 'edit' : ''],
    attrs: {
      "id": _vm.id,
      "type": _vm.type,
      "readonly": _vm.readonly ? _vm.readonly : !_vm.isEditMode
    },
    domProps: {
      "value": _vm.val
    },
    on: {
      "change": function change($event) {
        return _vm.changeField($event);
      },
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      },
      "blur": function blur($event) {
        return _vm.blurField($event);
      },
      "keyup": [function ($event) {
        return _vm.onKeyUp($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.blurField($event);
      }]
    }
  }) : _vm._e(), !_vm.isTextArea && _vm.mask && _vm.isEditMode ? _c('masked-input', {
    ref: _vm.id,
    class: ['form-control', _vm.isInvalid && _vm.isEditMode ? 'is-invalid' : '', _vm.readonly && !_vm.isEditMode ? 'readonly' : '', _vm.isEditMode || _vm.mode === _vm.FORMCONTROLMODE.EDIT ? 'edit' : ''],
    attrs: {
      "id": _vm.id,
      "value": _vm.val,
      "mask": _vm.mask,
      "placeholder": " ",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.changeMaskedField($event);
      }
    }
  }) : _vm._e(), _vm.isTextArea && !_vm.mask ? _c('textarea', {
    ref: _vm.id,
    class: ['form-control', _vm.isInvalid && _vm.isEditMode ? 'is-invalid' : '', _vm.readonly && !_vm.isEditMode ? 'readonly' : '', _vm.isEditMode || _vm.mode === _vm.FORMCONTROLMODE.EDIT ? 'edit' : ''],
    attrs: {
      "id": _vm.id,
      "type": _vm.type,
      "rows": _vm.rows,
      "readonly": _vm.readonly ? _vm.readonly : !_vm.isEditMode
    },
    domProps: {
      "value": _vm.val
    },
    on: {
      "change": function change($event) {
        return _vm.changeField($event);
      },
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      },
      "blur": function blur($event) {
        return _vm.blurField($event);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onEnter($event);
      }
    }
  }) : _vm._e(), !_vm.customError && _vm.isInvalid && _vm.isEditMode ? _c('div', {
    staticClass: "invalid-feedback d-block"
  }, [_vm._v(" Field '" + _vm._s(_vm.label) + "' is required ")]) : _vm._e(), _vm.customError ? _c('div', {
    staticClass: "invalid-feedback d-block"
  }, [_vm._v(" " + _vm._s(_vm.customError) + " ")]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }