import { ifAuthenticated } from './helpers'
import { MODULES } from '@/shared/constants'

const Reimbursements = () => import('@/views/Reimbursements/Reimbursements.vue')
const ReimbursementSubmission = () =>
  import('@/views/Reimbursements/ReimbursementSubmission.vue')
const MileageRates = () => import('@/views/Reimbursements/MileageRates.vue')

export default {
  path: '',
  name: 'Reimbursements',
  meta: { icon: 'icon-star' },
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/reimbursements/my-reimbursements',
      name: 'My reimbursements',
      component: Reimbursements,
      props: { mode: 'my-reimbursements' },
      meta: { module: MODULES.Reimbursements, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reimbursements/all-reimbursements',
      name: 'All reimbursements',
      component: Reimbursements,
      props: { mode: 'all-reimbursements' },
      meta: { module: MODULES.Reimbursements, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reimbursements/reimbursement/:action/:id?',
      name: 'Reimbursement submission',
      component: ReimbursementSubmission,
      props: true,
      meta: { module: MODULES.Reimbursements, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reimbursements/mileage-rates',
      name: 'Mileage rates',
      component: MileageRates,

      meta: { module: MODULES.Reimbursements, icon: 'icon-list' },
      beforeEnter: ifAuthenticated
    }
  ]
}
