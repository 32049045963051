import Vue from "vue";

import { api } from "@/shared/api.js";
Vue.prototype.$api = api;

Vue.prototype.$to = function(promise) {
  return promise
    .then(data => {
      return [null, data];
    })
    .catch(err => [err, null]);
};

import fs from "@/shared/form-helpers.js";
Vue.prototype.$form = fs;

//utils methods
import helpers from "@/shared/helpers.js";
Vue.prototype.$helpers = helpers;

import constants from "@/shared/constants.js";
Vue.prototype.$constants = constants;

import mixin from "@/shared/mixins.js";

Vue.mixin(mixin);
