import Vue from "vue";
import Store from "../store";

export const ifNotAuthenticated = (to, from, next) => {
  if (!Store.getters.isAuthenticated) {
    next();
  } else {
    next("/");
  }
};

export const ifAuthenticated = async (to, from, next) => {
  if (Store.getters.isAuthenticated) {
    await Store.dispatch("permissions/fetchMerged");

    Store.dispatch("router/addHistory", { name: to.name, params: to.params });

    if (
      !to.path.includes("/access-denided") &&
      !Vue.prototype.$access.routePermitted(to)
    ) {
      next(`/access-denided/${to.name}`);
    } else {
      next();
    }
  } else {
    Store.dispatch("router/saveNotAuthRequest", to.path);

    next("/login");
  }
};
