<template>
  <div>
    <div
      class="d-flex"
      :class="itemStyle(item)"
      @mouseover="onItemMouseOver(item)"
      @mouseout="onItemMouseOut(item)"
    >
      <span class="cf-item-text" @click="onItemClick(item)">
        <font-awesome-icon
          class="mr-1"
          icon="envelope"
          v-if="item.linked2phplist"
        />
        {{ getItemTitle(item) }}
      </span>
      <font-awesome-icon
        @click="onChevronToggle(item)"
        @mouseover="onChevronRightMouseOver(item)"
        v-show="!item.isUnfolded"
        class="shevron-icon"
        icon="chevron-right"
      />
      <font-awesome-icon
        @click="onChevronToggle(item)"
        @mouseover="onChevronLeftMouseOver(item)"
        v-show="item.isUnfolded"
        class="shevron-icon"
        icon="chevron-left"
      />

      <b-button-group v-show="item.isUnfolded">
        <b-button size="sm" variant="white">
          <font-awesome-icon
            class="action"
            icon="question-circle"
            :id="`popover-target-${item.id}`"
          />
        </b-button>
        <b-button size="sm" variant="white">
          <font-awesome-icon
            class="action"
            icon="home"
            @click="onActionDefaultClick(item)"
          />
        </b-button>
        <b-button size="sm" variant="white" v-if="item.isOwner">
          <font-awesome-icon
            class="action"
            :icon="item.shared ? 'lock-open' : 'lock'"
            @click="onActionShareClick(item)"
          />
        </b-button>
        <b-button size="sm" variant="white">
          <font-awesome-icon
            class="action"
            icon="clone"
            @click="onActionCloneClick(item)"
          />
        </b-button>

        <b-button size="sm" variant="white" v-if="item.isOwner">
          <font-awesome-icon
            class="action"
            icon="pencil-alt"
            @click="onActionEditClick(item)"
          />
        </b-button>
        <b-button size="sm" variant="white" v-if="item.isOwner">
          <font-awesome-icon
            class="action"
            icon="trash"
            @click="onActionDeleteClick(item)"
          />
        </b-button>
      </b-button-group>

      <b-popover
        v-show="item.isUnfolded"
        delay="100"
        :show="true"
        :target="`popover-target-${item.id}`"
        triggers="hover"
        placement="bottom"
        custom-class="transcription-popover"
      >
        <div v-html="item.transcription" />
      </b-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomFilterItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {};
  },
  computed: {},
  mounted: function() {},
  methods: {
    getItemTitle(item) {
      return !item.isOwner ? `${item.name} (${item.abbr})` : `${item.name}`;
    },
    onItemMouseOver(item) {
      if (item.timer) clearInterval(item.timer);
    },
    onItemMouseOut(item) {
      if (item.isUnfolded) {
        item.autoHideCounter = 1;
        this.autoHideTimer(item);
      }
    },
    onChevronRightMouseOver() {},
    onChevronLeftMouseOver() {},
    onChevronToggle(item) {
      item.isUnfolded = !item.isUnfolded;

      //updates newly added item
      this.$forceUpdate();
    },
    autoHideTimer(item) {
      let self = this;

      clearInterval(item.timer);

      item.autoHideCounter -= 1;

      if (item.autoHideCounter === 0) {
        item.timer = setInterval(function() {
          self.autoHideTimer(item);
        }, 5000);

        return true;
      }

      if (item.autoHideCounter < 0) {
        item.isUnfolded = false;

        item.autoHideCounter = 0;
      }
    },
    itemStyle(item) {
      let c = "cf-item";

      c += item.default ? " default" : "";

      c += item.shared ? " shared" : "";

      c += item.disabled ? " disabled" : "";

      c += item.selected ? " selected" : "";

      return c;
    },
    onItemClick(item) {
      item.selected = true;

      this.$emit("click", item);
    },
    onActionDefaultClick: async function(item) {
      let message = item.default
        ? `Default view "${item.name}" will be disabled. Do you want to proceed?`
        : `View "${item.name}" will be set as default. Do you want to proceed?`;

      let confirm = await this.$form.showConfirmation(message);

      if (!confirm) return;

      item.default = !item.default;

      this.$emit("change", {
        action: "default",
        item: item
      });
    },
    onActionShareClick: async function(item) {
      let message = item.shared
        ? `View "${item.name}" will become unavailable for other users. Do you want to proceed?`
        : `View "${item.name}" will be available for all users. Do you want to proceed?`;

      let confirm = await this.$form.showConfirmation(message);

      if (!confirm) return;

      item.shared = !item.shared;

      this.$emit("change", {
        action: "shared",
        item: item
      });
    },
    onActionEditClick: async function(item) {
      this.$emit("change", {
        action: "edit",
        item: item
      });
    },
    onActionDeleteClick: async function(item) {
      let confirm = false;

      if (item.linked2phplist) {
        confirm = await this.$form.showConfirmation(
          `View "${item.name}" linked to the subscriber's list! Once deleted then linked contacts synchronization will be canceled. Do you want to proceed?`
        );
      } else {
        confirm = await this.$form.showConfirmation(
          `View "${item.name}" will be deleted. Do you want to proceed?`
        );
      }

      if (!confirm) return;

      this.$emit("change", {
        action: "delete",
        item: item
      });
    },

    onActionCloneClick: async function(item) {
      let confirm = await this.$form.showConfirmation(
        `View "${item.name}" will be cloned. Do you want to proceed?`
      );

      if (!confirm) return;

      this.$emit("change", {
        action: "clone",
        item: item
      });
    }
  }
};
</script>

<style scoped>
.cf-item {
  border: 1px solid #c8ced3;
  /*#8fdff7a1;*/
  color: #2f353a9e;
  margin: 5px;
  padding-left: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.cf-item-text {
  /*color: #2f353a9e;*/
  padding: 3px;
}

.cf-item:hover {
  color: white;
  background-color: #2f353a;
  margin: 5px;
  padding-left: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.selected {
  color: white;
  background-color: #2f353a !important;
  margin: 5px;
  padding-left: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.cf-item.disabled {
  display: none;
}

.default {
  text-decoration: underline;
}

.shevron-icon {
  color: gray;
  margin: 5px;
  padding-top: 3px;
  float: right;
  cursor: pointer;
}

.cf-item .shevron-icon:hover {
  color: white;
  margin: 5px;
  padding-top: 3px;
  float: right;
  cursor: pointer;
}

.action {
  color: gray;
  margin-left: 5px;
  margin-right: 5px;
}

.action:hover {
  color: white;
  margin-left: 5px;
  margin-right: 5px;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.popover {
  max-width: max-content;
}

.transcription-popover {
  text-align: left;
  max-width: max-content;
}
</style>
