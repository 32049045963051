import { ifAuthenticated } from "./helpers";

const LegacyReportingSales = () => import("@/views/LegacyReporting/LegacyReportingSales");
const LegacyReportingTouchpoints = () => import("@/views/LegacyReporting/LegacyReportingTouchpoints");
const LegacyReportingDatabase = () => import("@/views/LegacyReporting/LegacyReportingDatabase");

export default {
  path: "",
  name: "Legacy Reporting",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/legacy-reporting/legacyreportingsales",
      name: "Legacy Sales Reporting",
      meta: { icon: "icon-list" },
      component: LegacyReportingSales,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/legacy-reporting/legacysreportingtouchpoints",
      name: "Legacy Touchpoints Reporting",
      meta: { icon: "icon-list" },
      component: LegacyReportingTouchpoints,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/legacy-reporting/legacyreportingdatabase",
      name: "Legacy Database Reporting",
      meta: { icon: "icon-list" },
      component: LegacyReportingDatabase,
      beforeEnter: ifAuthenticated
    }
  ]
};
