<template>
  <div class="form-row d-flex  justify-content-end ">
    <template v-for="(button, index) in customButtons">
      <b-button
        :key="`${uid}-${index}`"
        v-if="button.visible && !button.menu"
        :variant="button.variant"
        :disabled="button.disabled || button.loading"
        class="m-1"
        @click="$emit('custom-click', button.method)"
      >
        <b-spinner v-if="button.loading" small type="grow" />
        <font-awesome-icon v-if="!button.loading" :icon="button.icon" />
        {{ button.text }}
      </b-button>

      <b-dropdown
        :key="`${uid}-${index}`"
        v-if="button.visible && button.menu"
        :variant="button.variant"
        :disabled="button.disabled"
        class="m-1"
        right
        split
        :text="button.text"
        @click="$emit('custom-click', button.method)"
      >
        <b-dropdown-item
          :key="`${uid}-${index2}`"
          v-for="(item, index2) in button.menu"
          @click="$emit('custom-click', item.method)"
        >
          {{ item.label }}
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <b-button
      v-if="
        visible('save') &&
          !(mode === this.$constants.FORM_MODE.VIEW) &&
          !(mode === this.$constants.FORM_MODE.READONLY)
      "
      variant="outline-dark"
      class="m-1"
      @click="save"
      :disabled="loading.save || loading.saveAndView"
    >
      <b-spinner v-if="loading.save" small type="grow" />
      <font-awesome-icon v-if="!loading.save" icon="save" />
      Save
    </b-button>

    <b-button
      v-if="
        visible('saveAndView') &&
          !(mode === this.$constants.FORM_MODE.VIEW) &&
          !(mode === this.$constants.FORM_MODE.READONLY)
      "
      variant="outline-dark"
      class="m-1"
      @click="saveAndView"
      :disabled="loading.save || loading.saveAndView"
    >
      <b-spinner v-if="loading.saveAndView" small type="grow" />
      <font-awesome-icon v-if="!loading.saveAndView" icon="save" />
      Save & View
    </b-button>

    <b-button
      v-if="
        visible('edit') &&
          mode === this.$constants.FORM_MODE.VIEW &&
          !(mode === this.$constants.FORM_MODE.READONLY)
      "
      variant="outline-dark"
      class="m-1"
      @click="edit"
    >
      <font-awesome-icon icon="pencil-alt" />
      Edit
    </b-button>

    <b-button
      v-if="visible('back')"
      variant="outline-dark"
      class="m-1"
      @click="back()"
    >
      <font-awesome-icon icon="arrow-circle-left" /> Back
    </b-button>

    <b-button
      v-if="visible('previous')"
      variant="outline-dark"
      class="m-1"
      @click="previous"
      :disabled="false"
    >
      <font-awesome-icon v-if="!loading.save" icon="hand-point-left" />
      Previous
    </b-button>
    <b-button
      v-if="visible('next')"
      variant="outline-dark"
      class="m-1"
      @click="next"
      :disabled="false"
    >
      <font-awesome-icon v-if="!loading.save" icon="hand-point-right" />
      Next
    </b-button>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    mode: {
      type: [Number],
      required: true
    },
    hideRegularButtons: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Object,
      default: () => {
        return {
          save: false,
          saveAndView: false
        };
      }
    },
    buttonsVisibility: {
      type: Object,
      default: () => {
        return {
          previous: false,
          next: false,
          edit: false,
          view: false
        };
      }
    },
    customButtons: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: function() {
    return {};
  },
  computed: {},
  mounted: function() {},

  methods: {
    visible(buttonName) {
      if (this.hideRegularButtons) return false;

      if (
        this.buttonsVisibility[buttonName] !== undefined &&
        this.buttonsVisibility[buttonName] === false
      )
        return false;

      return true;
    },
    save() {
      this.$emit("save");
    },
    saveAndView() {
      this.$emit("save-and-view");
    },
    edit() {
      this.$emit("edit");
    },
    back() {
      this.$emit("back");
    },
    previous() {
      this.$emit("previous-item");
    },
    next() {
      this.$emit("next-item");
    }
  },
  watch: {}
};
</script>

<style scoped>
.btn {
  width: 10em;
}
</style>
