import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const Incentives = () => import("@/views/Incentives/Incentives.vue");
const IncentiveSubmission = () =>
  import("@/views/Incentives/IncentiveSubmission.vue");

export default {
  path: "",
  name: "Incentives",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/incentives/incentives",
      name: "Incentives",
      component: Incentives,
      props: { mode: "incentives" },
      meta: { module: MODULES.Incentives, icon: "icon-list" },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/incentives/incentive/:action/:id?",
      name: "Incentive submission",
      component: IncentiveSubmission,
      props: true,
      meta: { module: MODULES.Incentives, hideFromMenu: true },
      beforeEnter: ifAuthenticated
    }
  ]
};
