<template>
  <b-container fluid class="p-2">
    <b-row>
      <b-col>
        <b-form-row>
          <b-col cols="8" md="3" sm="3">
            <b-input
              ref="input-search"
              placeholder="type to search..."
              @keyup="onSearchKeyUp"
            />
          </b-col>
          <b-col cols="2" md="2" class="pt-1">
            <b-button
              size="sm"
              variant="outline-dark"
              title="New filter"
              :disabled="mode !== FORM_MODE.VIEW || isLoading"
              @click="addFilter()"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
          </b-col>
        </b-form-row>
      </b-col>
    </b-row>
    <hr class="m-2" />

    <b-row v-if="!isLoading">
      <b-col>
        <div v-for="(citem, index) in collapse.items" :key="`ci-${index}`">
          <div v-b-toggle="`collapse-f-${index}`" class="collapse-header">
            <font-awesome-icon v-if="citem.unfolded" icon="chevron-up" />
            <font-awesome-icon v-if="!citem.unfolded" icon="chevron-down" />

            {{ citem.title }} ({{ filters(citem.name).length }})
          </div>
          <b-collapse
            :id="`collapse-f-${index}`"
            class="mt-0"
            v-model="citem.unfolded"
            @show="onCollapseShow(citem)"
            @hide="onCollapseHide(citem)"
          >
            <VueReadMoreSmooth
              v-if="showReadMore"
              ref="read-more"
              :lines="3"
              text="Display more"
              text-less="Display less"
            >
              <draggable
                :id="citem.name"
                class="d-flex flex-row flex-wrap"
                :group="citem.name"
                :list="$data[citem.name]"
                @change="onChange"
                @start="onStart"
                @end="onEnd"
                @move="onMove"
              >
                <span v-for="(item, index2) in $data[citem.name]" :key="index2">
                  <custom-filter-item
                    :order="index"
                    :item="item"
                    @change="onItemChange"
                    @click="onItemClick"
                  />
                </span>
              </draggable>
            </VueReadMoreSmooth>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from "vue";

import VueReadMoreSmooth from "vue-read-more-smooth";

import { getExpressionTranscription } from "./helpers";

import CustomFilterItem from "./CustomFilterItem";

import draggable from "vuedraggable";

import { mapGetters, mapState } from "vuex";

import { FORM_MODE } from "@/shared/constants";

export default {
  name: "CustomFiltersBar",
  components: {
    draggable,
    CustomFilterItem,
    VueReadMoreSmooth
  },
  props: {
    datasetName: {
      type: String,
      default: "",
      required: true,
      validator(val) {
        if (!val) {
          console.error("datasetName must be defined");
          return false;
        }
        return true;
      }
    }
  },
  data: function() {
    return {
      showReadMore: false,
      isLoading: false,
      FORM_MODE: FORM_MODE,
      mode: FORM_MODE.VIEW,
      selected: {},
      items: [],
      ownFilters: [],
      ownSharedFilters: [],
      othersFilters: [],
      collapse: {
        items: [
          {
            title: "My filters",
            name: "ownFilters",
            unfolded: true,
            data: []
          },
          {
            title: "My shared filters",
            name: "ownSharedFilters",
            unfolded: true,
            data: []
          },
          {
            title: "Others filters",
            name: "othersFilters",
            unfolded: true,
            data: []
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    ...mapGetters("filteringPanel", [
      "getPanelState",
      "getSelectedFilters",
      "getCustomFiltersBar"
    ])
  },
  created() {},
  mounted() {},
  methods: {
    async load() {
      let state = this.getPanelState(this.datasetName);

      if (state.isCustomFiltersBar) {
        let customFiltersBar = this.getCustomFiltersBar(this.datasetName);

        this.collapse.items[0].unfolded = customFiltersBar.ownFilters.unfolded;
        this.collapse.items[1].unfolded =
          customFiltersBar.ownSharedFilters.unfolded;
        this.collapse.items[2].unfolded =
          customFiltersBar.othersFilters.unfolded;
      }

      await this.getFiltersList();
    },
    filters(c) {
      if (c === "ownFilters")
        return this.items.filter(
          i => i.visible && !i.disabled && i.isOwner && !i.shared
        );

      if (c === "ownSharedFilters")
        return this.items.filter(
          i => i.visible && !i.disabled && i.isOwner && i.shared
        );

      if (c === "othersFilters")
        return this.items.filter(
          i => i.visible && !i.disabled && !i.isOwner && i.shared
        );
    },
    onSearchKeyUp() {
      let text = this.$refs["input-search"].vModelValue;

      if (text.trim() === "") {
        this.items.forEach(i => (i.visible = true));
      } else {
        this.items.forEach(
          i => (i.visible = i.name.toLowerCase().includes(text.toLowerCase()))
        );
      }
    },
    onItemClick(_payload) {
      this.selected = _payload;

      //reset others
      this.items
        .filter(i => i.id !== _payload.id)
        .forEach(i => (i.selected = false));

      this.$emit("filter-selected", _payload);

      //update stats
      this.$api.put(`custom-filters/${_payload.id}/click`);
    },
    onItemChange(_payload) {
      let self = this;

      self.updateArraysGroups();

      if (_payload.action === "default") {
        let payload = {
          default: _payload.item.default
        };

        //reset others items
        this.items
          .filter(i => i.id !== _payload.item.id)
          .forEach(i => (i.default = false));
        //update current item
        //let i = this.items.find(i => i.id === _payload.item.id)
        //i = _payload.item

        this.$api
          .put(`custom-filters/${_payload.item.id}`, payload)
          .then(response => {
            self.updateArraysGroups();

            self.$form.makeToastInfo(response.message);
          })
          .catch(e => {
            self.$form.makeToastError(e.message);
          });
      }
      //////
      if (_payload.action === "shared") {
        //update current item
        //let i = this.items.find(i => i.id === _payload.item.id)
        //i = _payload.item

        let payload = {
          shared: _payload.item.shared
        };
        this.$api
          .put(`custom-filters/${_payload.item.id}`, payload)
          .then(response => {
            self.updateArraysGroups();

            self.$form.makeToastInfo(response.message);
          })
          .catch(e => {
            self.$form.makeToastError(e.message);
          });
      }
      //////
      if (_payload.action === "edit") {
        this.items
          .filter(i => i.id !== _payload.item.id)
          .forEach(i => (i.disabled = true));

        this.$emit("edit", _payload.item);

        this.mode = FORM_MODE.EDIT;
      }
      //////
      if (_payload.action === "delete") {
        self.items = self.items.filter(i => i.id !== _payload.item.id);

        this.$api
          .delete(`custom-filters/${_payload.item.id}`)
          .then(response => {
            self.updateArraysGroups();

            self.$form.makeToastInfo(response.message);
          })
          .catch(e => {
            self.$form.makeToastError(e.message);
          });
      }
      //////
      if (_payload.action === "clone") {
        //clone current item

        let clonedItem = Object.assign({}, _payload.item);

        //reset id until getting new one from the backend to avoid duplicating tooltips binding
        clonedItem.id = "";
        clonedItem.isOwner = true;
        clonedItem.shared = false;
        clonedItem.name = "Copy of " + clonedItem.name;

        this.items.push(clonedItem);

        //console.log(JSON.stringify(clonedItem))

        this.$api
          .post("custom-filters", clonedItem)
          .then(response => {
            let idx = self.items.findIndex(n => n.name === clonedItem.name);

            clonedItem.id = response.id;

            Vue.set(self.items, idx, clonedItem);

            self.$form.makeToastInfo(response.message);
          })
          .catch(e => {
            self.$form.makeToastError(e.message);
          });
      }
    },
    onCollapseShow(item) {
      this.$emit("state-change", item);
    },
    onCollapseHide(item) {
      this.$emit("state-change", item);
    },
    setMode(value) {
      this.mode = value;
    },
    updateOrCreateItem(item) {
      let i = this.items.find(i => i.id === item.id);

      //add new filter or update
      if (!i) {
        this.items.push(item);
      } else {
        item = i;
      }
    },

    addFilter() {
      this.items.forEach(i => (i.disabled = true));

      this.mode = FORM_MODE.CREATE;

      this.$emit("create");
    },
    async getFiltersList() {
      let self = this;

      self.isLoading = true;

      let filters = await this.$api.get(`custom-filters/${this.datasetName}`);

      self.isLoading = false;

      filters.forEach(i => {
        i.transcription = getExpressionTranscription(i.data, this);
        i.isUnfolded = false;
        i.autoHideCounter = 0;
        i.default = i.default == "1";
        i.shared = i.shared == "1";
        i.isOwner = self.profile.data.id == +i.user_id;
        i.disabled = false;
        i.visible = true;
        i.selected = false;
        i.linked2phplist = i.linked2phplist == "1";
        i.order = i.filter_order;
      });

      self.items = filters;

      self.items.forEach(i => {
        //save default filter

        if (i.default) {
          i.selected = true;
          self.selected = i;
        }
      });

      this.updateArraysGroups();

      // this.sortItems();

      self.$emit("loaded");
    },
    updateArraysGroups() {
      this.ownFilters = this.filters("ownFilters");
      this.ownSharedFilters = this.filters("ownSharedFilters");
      this.othersFilters = this.filters("othersFilters");
    },
    onStart() {},
    onEnd(e) {
      const self = this;

      const groupName = e.to.id;

      //update orders
      for (let i = 0; i < this[groupName].length; i++) {
        this[groupName][i].order = i + 1;
      }

      const payload = this[groupName].map(f => ({
        id: f.id,
        order: f.order
      }));

      //save to db
      this.$api
        .put("custom-filters/reorder", payload)
        .then(response => {
          self.$form.makeToastInfo(response.message);
        })
        .catch(err => {
          console.error(err);

          self.$form.makeToastError(err.message);
        });
    },
    onMove() {},
    onChange: function() {}
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        //required to avoid errors from the vue-read-more-smooth component because of empty divs
        //Uncaught TypeError: Cannot read property 'offsetHeight' of undefined
        this.showReadMore = true;
      }
    },
    mode(newVal) {
      if (newVal === FORM_MODE.VIEW)
        this.items.forEach(i => (i.disabled = false));
    },
    items() {
      this.updateArraysGroups();
    }
  }
};
</script>

<style scoped>
.collapse-header {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
}

::v-deep .read-more-button {
  margin: 0 auto;
}
</style>
