var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_vm.compact ? _c('div', [_c('b-container', {
    staticStyle: {
      "width": "200"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pl-3 pr-3",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    class: _vm.showLabels ? '' : 'p-1',
    attrs: {
      "lg": "10"
    }
  }, [_c('b-row', [_vm._l(_vm.regularInputs, function (filter, index) {
    return _c('b-col', {
      key: "rinput-".concat(index),
      attrs: {
        "lg": ""
      }
    }, [_c('div', {
      staticClass: "mb-1 mr-1",
      attrs: {
        "id": "tt-".concat(filter.name)
      }
    }, [_vm.showLabels ? _c('label', {
      staticClass: "col-form-label pb-1 pt-0",
      attrs: {
        "for": filter.name
      }
    }, [_vm._v(_vm._s(filter.title))]) : _vm._e(), _c('b-input', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": "ip-".concat(_vm.datasetName, "-").concat(index, "-").concat(filter.name)
      },
      on: {
        "input": _vm.onInput
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    })], 1), filter.tooltip ? _c('b-tooltip', {
      attrs: {
        "target": "tt-".concat(filter.name),
        "placement": "top",
        "triggers": "focus",
        "delay": "5000"
      }
    }, [_vm._v(" " + _vm._s(filter.tooltip) + " ")]) : _vm._e()], 1);
  }), _vm._l(_vm.regularDropdowns, function (filter, index) {
    return _c('b-col', {
      key: "rin-".concat(index),
      attrs: {
        "lg": ""
      }
    }, [_c('div', {
      staticClass: "mb-1 mr-1",
      attrs: {
        "id": "tt-".concat(filter.name)
      }
    }, [_vm.showLabels ? _c('label', {
      staticClass: "col-form-label pb-1 pt-0",
      attrs: {
        "for": filter.name
      }
    }, [_vm._v(_vm._s(filter.title))]) : _vm._e(), filter.group_values ? _c('multiselect', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": "ms-".concat(_vm.datasetName, "-").concat(index, "-").concat(filter.name),
        "allow-empty": true,
        "group-values": filter.group_values,
        "group-label": filter.group_label,
        "group-select": true,
        "track-by": filter.trackby,
        "label": filter.label,
        "options": filter.options,
        "multiple": filter.multiple,
        "show-labels": false,
        "placeholder": "select ".concat(filter.title.toLowerCase())
      },
      on: {
        "input": _vm.onSelectInput,
        "close": _vm.onSelectClose
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    }) : _vm._e(), !filter.group_values ? _c('multiselect', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": "ms-".concat(_vm.datasetName, "-").concat(index, "-").concat(filter.name),
        "allow-empty": true,
        "track-by": filter.trackby,
        "label": filter.label,
        "options": filter.options,
        "multiple": filter.multiple,
        "show-labels": false,
        "placeholder": "select ".concat(filter.title.toLowerCase())
      },
      on: {
        "input": _vm.onSelectInput,
        "close": _vm.onSelectClose
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    }) : _vm._e()], 1), filter.tooltip ? _c('b-tooltip', {
      attrs: {
        "target": "tt-".concat(filter.name),
        "placement": "top",
        "triggers": "focus",
        "delay": "5000"
      }
    }, [_vm._v(" " + _vm._s(filter.tooltip) + " ")]) : _vm._e()], 1);
  }), _vm._l(_vm.dateRanges, function (dateRange, index) {
    return _c('b-col', {
      key: "dr-".concat(index)
    }, [_c('div', {
      attrs: {
        "id": "tt-".concat(dateRange.name)
      }
    }, [_vm.showLabels ? _c('label', {
      staticClass: "col-form-label pb-1 pt-0"
    }, [_vm._v(_vm._s(dateRange.title))]) : _vm._e(), _c('date-range-picker-custom', {
      ref: "".concat(dateRange.name),
      refInFor: true,
      attrs: {
        "id": dateRange.name,
        "default-range": dateRange.defaultRange,
        "allow-empty": dateRange.allowEmpty
      },
      on: {
        "mounted": function mounted($event) {
          return _vm.onDateRangeMounted($event, dateRange.name);
        },
        "input": _vm.onDateRangeInput,
        "change": function change($event) {
          return _vm.onDateRangeChange(dateRange.name);
        }
      },
      model: {
        value: _vm.selected[dateRange.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, dateRange.name, $$v);
        },
        expression: "selected[dateRange.name]"
      }
    })], 1), dateRange.tooltip ? _c('b-tooltip', {
      attrs: {
        "target": "tt-".concat(dateRange.name),
        "placement": "top",
        "triggers": "focus",
        "delay": "5000"
      }
    }, [_vm._v(" " + _vm._s(dateRange.tooltip) + " ")]) : _vm._e()], 1);
  }), _vm.dimensions ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    attrs: {
      "id": "tt-".concat(_vm.dimensions.name)
    }
  }, [_vm.showLabels ? _c('label', {
    staticClass: "col-form-label pb-1 pt-0",
    attrs: {
      "for": _vm.dimensions.name
    }
  }, [_vm._v(_vm._s(_vm.dimensions.title))]) : _vm._e(), _c('multiselect', {
    ref: _vm.dimensions.name,
    attrs: {
      "id": _vm.dimensions.name,
      "track-by": _vm.dimensions.trackby,
      "label": _vm.dimensions.label,
      "options": _vm.dimensions.options,
      "multiple": _vm.dimensions.multiple,
      "show-labels": false,
      "placeholder": _vm.dimensions.placeholder
    },
    on: {
      "input": _vm.onSelectInput,
      "close": _vm.onSelectClose
    },
    model: {
      value: _vm.selected[_vm.dimensions.name],
      callback: function callback($$v) {
        _vm.$set(_vm.selected, _vm.dimensions.name, $$v);
      },
      expression: "selected[dimensions.name]"
    }
  })], 1), _vm.dimensions.tooltip ? _c('b-tooltip', {
    attrs: {
      "target": "tt-".concat(_vm.dimensions.name),
      "placement": "top",
      "triggers": "focus",
      "delay": "5000"
    }
  }, [_vm._v(" " + _vm._s(_vm.dimensions.tooltip) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.measures ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    attrs: {
      "id": "tt-".concat(_vm.measures.name)
    }
  }, [_vm.showLabels ? _c('label', {
    staticClass: "col-form-label pb-1 pt-0",
    attrs: {
      "for": _vm.measures.name
    }
  }, [_vm._v(_vm._s(_vm.measures.title))]) : _vm._e(), _c('multiselect', {
    ref: _vm.measures.name,
    attrs: {
      "id": _vm.measures.name,
      "track-by": _vm.measures.trackby,
      "label": _vm.measures.label,
      "options": _vm.measures.options,
      "multiple": _vm.measures.multiple,
      "show-labels": false,
      "placeholder": _vm.measures.placeholder
    },
    on: {
      "input": _vm.onSelectInput,
      "close": _vm.onSelectClose
    },
    model: {
      value: _vm.selected[_vm.measures.name],
      callback: function callback($$v) {
        _vm.$set(_vm.selected, _vm.measures.name, $$v);
      },
      expression: "selected[measures.name]"
    }
  })], 1), _vm.measures.tooltip ? _c('b-tooltip', {
    attrs: {
      "target": "tt-".concat(_vm.measures.name),
      "placement": "top",
      "triggers": "focus",
      "delay": "5000"
    }
  }, [_vm._v(" " + _vm._s(_vm.measures.tooltip) + " ")]) : _vm._e()], 1) : _vm._e()], 2)], 1), !_vm.hideSearchButton ? _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "p-2",
    attrs: {
      "cols": "6",
      "lg": "12"
    }
  }, [_c('b-button', {
    staticStyle: {
      "width": "8em"
    },
    attrs: {
      "variant": _vm.isUpdateRequired ? 'success' : 'outline-secondary'
    },
    on: {
      "click": _vm.searchData
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "sync"
    }
  })], 1)], 1), !_vm.hideResetButton ? _c('b-col', {
    staticClass: "p-2",
    attrs: {
      "cols": "6",
      "lg": "12"
    }
  }, [_c('b-button', {
    staticStyle: {
      "width": "8em"
    },
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.onResetClick
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "backspace"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), !_vm.compact ? _c('b-card', {
    attrs: {
      "no-body": "",
      "header-bg-variant": "white"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.panelState.isUnfolded ? _c('font-awesome-icon', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-fp",
            modifiers: {
              "collapse-fp": true
            }
          }],
          style: _vm.iconStyleLeft,
          attrs: {
            "size": "2x",
            "icon": "chevron-up"
          }
        }) : _vm._e(), !_vm.panelState.isUnfolded ? _c('font-awesome-icon', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-fp",
            modifiers: {
              "collapse-fp": true
            }
          }],
          style: _vm.iconStyleLeft,
          attrs: {
            "size": "2x",
            "icon": "chevron-down"
          }
        }) : _vm._e(), _c('font-awesome-icon', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.hideCustomFilters && _vm.panelState.isDropdownsBar,
            expression: "!hideCustomFilters && panelState.isDropdownsBar"
          }],
          style: _vm.iconStyleRight,
          attrs: {
            "id": "tooltip-cf",
            "size": "2x",
            "icon": "th"
          },
          on: {
            "click": _vm.toggleView
          }
        }), _c('font-awesome-icon', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.hideCustomFilters && _vm.panelState.isCustomFiltersBar,
            expression: "!hideCustomFilters && panelState.isCustomFiltersBar"
          }],
          style: _vm.iconStyleRight,
          attrs: {
            "id": "tooltip-dd",
            "size": "2x",
            "icon": "caret-square-down"
          },
          on: {
            "click": _vm.toggleView
          }
        }), _c('b-tooltip', {
          attrs: {
            "target": "tooltip-cf",
            "triggers": "hover",
            "placement": "left",
            "delay": "2000"
          }
        }, [_vm._v(" Switch to custom filters ")]), _c('b-tooltip', {
          attrs: {
            "target": "tooltip-dd",
            "triggers": "hover",
            "placement": "left",
            "delay": "2000"
          }
        }, [_vm._v(" Switch to simple filters ")])];
      },
      proxy: true
    }], null, false, 995535491)
  }, [_c('b-collapse', {
    staticClass: "mt-0",
    attrs: {
      "id": "collapse-fp"
    },
    model: {
      value: _vm.panelState.isUnfolded,
      callback: function callback($$v) {
        _vm.$set(_vm.panelState, "isUnfolded", $$v);
      },
      expression: "panelState.isUnfolded"
    }
  }, [_c('custom-filters-bar', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.panelState.isCustomFiltersBar && !_vm.expressionBuilder.visible,
      expression: "panelState.isCustomFiltersBar && !expressionBuilder.visible"
    }],
    ref: "cfb",
    attrs: {
      "dataset-name": _vm.datasetName
    },
    on: {
      "edit": _vm.onCustomFilterEdit,
      "create": _vm.onCustomFilterCreate,
      "loaded": _vm.onCustomFiltersLoaded,
      "filter-selected": _vm.onCustomFilterSelected,
      "state-change": _vm.onCustomFiltersCollapseStateChage
    }
  }), _c('expression-builder', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.expressionBuilder.visible,
      expression: "expressionBuilder.visible"
    }],
    ref: "exb",
    attrs: {
      "districts": _vm.districts,
      "fields": _vm.filters,
      "dimensions": _vm.dimensions,
      "measures": _vm.measures,
      "dataset-name": _vm.datasetName
    },
    on: {
      "close": _vm.onExpressionBuilderClose,
      "save": _vm.onExpressionBuilderSave,
      "async-search-exb": _vm.onSearchChangeExb
    }
  }), _c('b-card-body', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.panelState.isDropdownsBar,
      expression: "panelState.isDropdownsBar"
    }]
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pl-3 pr-3",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    class: _vm.showLabels ? '' : 'p-1',
    attrs: {
      "lg": "10"
    }
  }, [_c('b-row', [_vm._l(_vm.regularInputs, function (filter, index) {
    return _c('b-col', {
      key: "rinput-".concat(index),
      attrs: {
        "xl": "3",
        "lg": "6",
        "md": "6",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('div', {
      staticClass: "mb-1 mr-1",
      attrs: {
        "id": "tt-".concat(filter.name)
      }
    }, [_vm.showLabels ? _c('label', {
      staticClass: "col-form-label pb-1 pt-0",
      attrs: {
        "for": filter.name
      }
    }, [_vm._v(_vm._s(filter.title))]) : _vm._e(), _c('b-input', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": "ip-".concat(_vm.datasetName, "-").concat(index, "-").concat(filter.name)
      },
      on: {
        "input": _vm.onInput
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    })], 1), _c('b-tooltip', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: filter.tooltip,
        expression: "filter.tooltip"
      }],
      attrs: {
        "target": "tt-".concat(filter.name),
        "placement": "top",
        "triggers": "focus",
        "delay": "5000"
      }
    }, [_vm._v(" " + _vm._s(filter.tooltip) + " ")])], 1);
  }), _vm._l(_vm.regularDropdowns, function (filter, index) {
    return _c('b-col', {
      key: "rin-".concat(index),
      attrs: {
        "xl": "3",
        "lg": "6",
        "md": "6",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('div', {
      staticClass: "mb-1 mr-1",
      attrs: {
        "id": "tt-".concat(filter.name)
      }
    }, [_vm.showLabels ? _c('label', {
      staticClass: "col-form-label pb-1 pt-0",
      attrs: {
        "for": filter.name
      }
    }, [_vm._v(_vm._s(filter.title))]) : _vm._e(), filter.async ? _c('span', [_c('multiselect', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": filter.name,
        "loading": filter.loading,
        "track-by": filter.trackby,
        "label": filter.label,
        "options": filter.options,
        "multiple": filter.multiple,
        "show-labels": false,
        "placeholder": "type to find ".concat(filter.title.toLowerCase())
      },
      on: {
        "search-change": function searchChange($event) {
          _vm.$helpers.debounce(_vm.onSearchChange, 500)($event, filter);
        },
        "input": _vm.onSelectInput,
        "close": _vm.onSelectClose
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    }, [_c('template', {
      slot: "beforeList"
    }, [_c('b-button-group', {
      attrs: {
        "size": "sm"
      }
    }, [_c('b-button', {
      attrs: {
        "pressed": filter.startsWith,
        "squared": "",
        "size": "sm",
        "variant": 'ligth'
      },
      on: {
        "update:pressed": function updatePressed($event) {
          return _vm.$set(filter, "startsWith", $event);
        }
      }
    }, [!filter.startsWith ? _c('font-awesome-icon', {
      attrs: {
        "icon": ['far', 'square']
      }
    }) : _vm._e(), filter.startsWith ? _c('font-awesome-icon', {
      attrs: {
        "icon": ['far', 'check-square']
      }
    }) : _vm._e(), _vm._v(" Starts with... ")], 1)], 1)], 1)], 2)], 1) : _c('span', [filter.group_values ? _c('multiselect', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": filter.name,
        "group-values": filter.group_values,
        "group-label": filter.group_label,
        "group-select": true,
        "track-by": filter.trackby,
        "label": filter.label,
        "options": filter.options,
        "multiple": filter.multiple,
        "show-labels": false,
        "placeholder": "select ".concat(filter.title.toLowerCase())
      },
      on: {
        "input": _vm.onSelectInput,
        "close": _vm.onSelectClose
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    }) : _vm._e(), !filter.group_values ? _c('multiselect', {
      ref: filter.name,
      refInFor: true,
      attrs: {
        "id": filter.name,
        "track-by": filter.trackby,
        "label": filter.label,
        "options": filter.options,
        "multiple": filter.multiple,
        "show-labels": false,
        "placeholder": "select ".concat(filter.title.toLowerCase())
      },
      on: {
        "input": _vm.onSelectInput,
        "close": _vm.onSelectClose
      },
      model: {
        value: _vm.selected[filter.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, filter.name, $$v);
        },
        expression: "selected[filter.name]"
      }
    }) : _vm._e()], 1)]), filter.tooltip ? _c('b-tooltip', {
      attrs: {
        "target": "tt-".concat(filter.name),
        "placement": "top",
        "triggers": "focus",
        "delay": "5000"
      }
    }, [_vm._v(" " + _vm._s(filter.tooltip) + " ")]) : _vm._e()], 1);
  }), _vm._l(_vm.dateRanges, function (dateRange, index) {
    return _c('b-col', {
      key: "dr-".concat(index),
      attrs: {
        "xl": "3",
        "lg": "6",
        "md": "6",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('div', {
      attrs: {
        "id": "tt-".concat(dateRange.name)
      }
    }, [_vm.showLabels ? _c('label', {
      staticClass: "col-form-label pb-1 pt-0"
    }, [_vm._v(_vm._s(dateRange.title))]) : _vm._e(), _c('date-range-picker-custom', {
      ref: dateRange.name,
      refInFor: true,
      attrs: {
        "id": dateRange.name,
        "default-range": dateRange.defaultRange,
        "allow-empty": dateRange.allowEmpty
      },
      on: {
        "mounted": function mounted($event) {
          return _vm.onDateRangeMounted($event, dateRange.name);
        },
        "input": _vm.onDateRangeInput,
        "change": function change($event) {
          return _vm.onDateRangeChange(dateRange.name);
        }
      },
      model: {
        value: _vm.selected[dateRange.name],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, dateRange.name, $$v);
        },
        expression: "selected[dateRange.name]"
      }
    })], 1), dateRange.tooltip ? _c('b-tooltip', {
      attrs: {
        "target": "tt-".concat(dateRange.name),
        "placement": "top",
        "triggers": "focus",
        "delay": "5000"
      }
    }, [_vm._v(" " + _vm._s(dateRange.tooltip) + " ")]) : _vm._e()], 1);
  }), _vm.dimensions ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    attrs: {
      "id": "tt-".concat(_vm.dimensions.name)
    }
  }, [_vm.showLabels ? _c('label', {
    staticClass: "col-form-label pb-1 pt-0",
    attrs: {
      "for": _vm.dimensions.name
    }
  }, [_vm._v(_vm._s(_vm.dimensions.title))]) : _vm._e(), _c('multiselect', {
    ref: _vm.dimensions.name,
    attrs: {
      "id": _vm.dimensions.name,
      "track-by": _vm.dimensions.trackby,
      "label": _vm.dimensions.label,
      "options": _vm.dimensions.options,
      "multiple": _vm.dimensions.multiple,
      "show-labels": false,
      "placeholder": _vm.dimensions.placeholder
    },
    on: {
      "input": _vm.onSelectInput,
      "close": _vm.onSelectClose
    },
    model: {
      value: _vm.selected[_vm.dimensions.name],
      callback: function callback($$v) {
        _vm.$set(_vm.selected, _vm.dimensions.name, $$v);
      },
      expression: "selected[dimensions.name]"
    }
  })], 1), _vm.dimensions.tooltip ? _c('b-tooltip', {
    attrs: {
      "target": "tt-".concat(_vm.dimensions.name),
      "placement": "top",
      "triggers": "focus",
      "delay": "5000"
    }
  }, [_vm._v(" " + _vm._s(_vm.dimensions.tooltip) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.measures ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    attrs: {
      "id": "tt-".concat(_vm.measures.name)
    }
  }, [_vm.showLabels ? _c('label', {
    staticClass: "col-form-label pb-1 pt-0",
    attrs: {
      "for": _vm.measures.name
    }
  }, [_vm._v(_vm._s(_vm.measures.title))]) : _vm._e(), _c('multiselect', {
    ref: _vm.measures.name,
    attrs: {
      "id": _vm.measures.name,
      "track-by": _vm.measures.trackby,
      "label": _vm.measures.label,
      "options": _vm.measures.options,
      "multiple": _vm.measures.multiple,
      "show-labels": false,
      "placeholder": _vm.measures.placeholder
    },
    on: {
      "input": _vm.onSelectInput,
      "close": _vm.onSelectClose
    },
    model: {
      value: _vm.selected[_vm.measures.name],
      callback: function callback($$v) {
        _vm.$set(_vm.selected, _vm.measures.name, $$v);
      },
      expression: "selected[measures.name]"
    }
  })], 1), _vm.measures.tooltip ? _c('b-tooltip', {
    attrs: {
      "target": "tt-".concat(_vm.measures.name),
      "placement": "top",
      "triggers": "focus",
      "delay": "5000"
    }
  }, [_vm._v(" " + _vm._s(_vm.measures.tooltip) + " ")]) : _vm._e()], 1) : _vm._e()], 2)], 1), !_vm.hideSearchButton ? _c('b-col', {
    attrs: {
      "lg": "2"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "p-2",
    attrs: {
      "cols": "6",
      "lg": "12"
    }
  }, [_c('b-button', {
    staticStyle: {
      "width": "8em"
    },
    attrs: {
      "variant": _vm.isUpdateRequired ? 'success' : 'outline-secondary'
    },
    on: {
      "click": _vm.searchData
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "sync"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "p-2",
    attrs: {
      "cols": "6",
      "lg": "12"
    }
  }, [_c('b-button', {
    staticStyle: {
      "width": "8em"
    },
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.resetFilters
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "backspace"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e(), !_vm.$isMobile ? _c('b-row', {
    staticClass: "pr-5",
    attrs: {
      "align-h": "end"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#d3d3d3",
      "font-size": "10px",
      "margin-top": "-5px"
    }
  }, [_vm._v("FP 2.0 [" + _vm._s(_vm.loadingTime) + " ms]")])]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }