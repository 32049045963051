var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex",
    class: _vm.itemStyle(_vm.item),
    on: {
      "mouseover": function mouseover($event) {
        return _vm.onItemMouseOver(_vm.item);
      },
      "mouseout": function mouseout($event) {
        return _vm.onItemMouseOut(_vm.item);
      }
    }
  }, [_c('span', {
    staticClass: "cf-item-text",
    on: {
      "click": function click($event) {
        return _vm.onItemClick(_vm.item);
      }
    }
  }, [_vm.item.linked2phplist ? _c('font-awesome-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "envelope"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.getItemTitle(_vm.item)) + " ")], 1), _c('font-awesome-icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.item.isUnfolded,
      expression: "!item.isUnfolded"
    }],
    staticClass: "shevron-icon",
    attrs: {
      "icon": "chevron-right"
    },
    on: {
      "click": function click($event) {
        return _vm.onChevronToggle(_vm.item);
      },
      "mouseover": function mouseover($event) {
        return _vm.onChevronRightMouseOver(_vm.item);
      }
    }
  }), _c('font-awesome-icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.isUnfolded,
      expression: "item.isUnfolded"
    }],
    staticClass: "shevron-icon",
    attrs: {
      "icon": "chevron-left"
    },
    on: {
      "click": function click($event) {
        return _vm.onChevronToggle(_vm.item);
      },
      "mouseover": function mouseover($event) {
        return _vm.onChevronLeftMouseOver(_vm.item);
      }
    }
  }), _c('b-button-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.isUnfolded,
      expression: "item.isUnfolded"
    }]
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "white"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": "question-circle",
      "id": "popover-target-".concat(_vm.item.id)
    }
  })], 1), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "white"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": "home"
    },
    on: {
      "click": function click($event) {
        return _vm.onActionDefaultClick(_vm.item);
      }
    }
  })], 1), _vm.item.isOwner ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "white"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": _vm.item.shared ? 'lock-open' : 'lock'
    },
    on: {
      "click": function click($event) {
        return _vm.onActionShareClick(_vm.item);
      }
    }
  })], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "white"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": "clone"
    },
    on: {
      "click": function click($event) {
        return _vm.onActionCloneClick(_vm.item);
      }
    }
  })], 1), _vm.item.isOwner ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "white"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": "pencil-alt"
    },
    on: {
      "click": function click($event) {
        return _vm.onActionEditClick(_vm.item);
      }
    }
  })], 1) : _vm._e(), _vm.item.isOwner ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "white"
    }
  }, [_c('font-awesome-icon', {
    staticClass: "action",
    attrs: {
      "icon": "trash"
    },
    on: {
      "click": function click($event) {
        return _vm.onActionDeleteClick(_vm.item);
      }
    }
  })], 1) : _vm._e()], 1), _c('b-popover', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.isUnfolded,
      expression: "item.isUnfolded"
    }],
    attrs: {
      "delay": "100",
      "show": true,
      "target": "popover-target-".concat(_vm.item.id),
      "triggers": "hover",
      "placement": "bottom",
      "custom-class": "transcription-popover"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.item.transcription)
    }
  })])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }