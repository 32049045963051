import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const Accounts = () => import("@/views/RelationshipManagement/Accounts");
const AccountSubmissionForm = () =>
  import("@/views/RelationshipManagement/AccountSubmissionForm/index.js");
const Contacts = () => import("@/views/RelationshipManagement/Contacts");
const ContactSubmissionForm = () =>
  import("@/views/RelationshipManagement/ContactSubmissionForm/index.js");
const ContactSubmissionFormQuick = () =>
  import("@/views/RelationshipManagement/ContactSubmissionFormQuick/index.js");

export default {
  path: "",
  name: "Relationship management",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/relationship-management/accounts",
      name: "Accounts",
      component: Accounts,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Accounts, icon: "icon-list" }
    },
    {
      path: "/relationship-management/account/:action/:id?",
      name: "Account submission",
      component: AccountSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Accounts, hideFromMenu: true }
    },
    {
      path: "/relationship-management/contacts",
      name: "Contacts",
      component: Contacts,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Contacts, icon: "icon-list" }
    },
    {
      path: "/relationship-management/contact/create/quick",
      name: "Contact submission quick",
      component: ContactSubmissionFormQuick,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Contacts, hideFromMenu: true }
    },
    {
      path: "/relationship-management/contact/:action/:id?",
      name: "Contact submission",
      component: ContactSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Contacts, hideFromMenu: true }
    }
  ]
};
