import { ifAuthenticated } from './helpers'
import { MODULES } from '@/shared/constants'

const UserList = () => import('@/views/UserManagement/UserList')
const UsersPermissions = () => import('@/views/UserManagement/UsersPermissions')
const User = () => import('@/views/UserManagement/User')
const UserSalaries = () => import('@/views/UserManagement/UserSalaries')
const BonusPayments = () => import('@/views/UserManagement/BonusPayments')
const UserMigrationManager = () =>
  import('@/views/UserManagement/UserMigrationManager')
const UserActivityTracking = () =>
  import('@/views/UserManagement/UserActivityTracking')
const WeeklyStatistics = () => import('@/views/UserManagement/WeeklyStatistics')
const RolePermissions = () => import('@/views/UserManagement/RolePermissions')
const OrganizationChart = () =>
  import('@/views/UserManagement/OrganizationChart/Main')

export default {
  path: '',
  name: 'User management',
  component: {
    render (c) {
      return c('router-view')
    }
  },
  meta: {
    label: 'User management',

    icon: 'icon-star'
  },
  children: [
    {
      path: '/user-management/users',
      name: 'Users',
      meta: { icon: 'icon-list', module: MODULES.Users },
      component: UserList,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/user/:action/:id?',
      name: 'User submission',
      component: User,
      props: true,
      meta: { hideFromMenu: true, module: MODULES.Users },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/salaries',
      name: 'Salaries',
      meta: { icon: 'icon-list', module: MODULES.Users },
      component: UserSalaries,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/bonus-payments',
      name: 'Bonus payments',
      component: BonusPayments,
      meta: { icon: 'icon-list', module: MODULES.Users },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/user-permissions',
      name: 'User permissions',
      meta: { icon: 'icon-shield', module: MODULES.Users },
      component: UsersPermissions,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/role-permissions',
      name: 'Role permissions',
      meta: { icon: 'icon-shield', module: MODULES.Users },
      component: RolePermissions,
      beforeEnter: ifAuthenticated
    },

    {
      path: '/user-management/migration-manager',
      name: 'Migration manager',
      meta: { icon: 'icon-note', module: MODULES.Users },
      props: true,
      component: UserMigrationManager,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/user-activity-tracking',
      name: 'User activity tracking',
      meta: { icon: 'icon-list', module: MODULES.Users },
      props: true,
      component: UserActivityTracking,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/weekly-statistics',
      name: 'Weekly statistics',
      meta: { icon: 'icon-list', module: MODULES.Users },
      props: true,
      component: WeeklyStatistics,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/user-management/org-chart',
      name: 'Organization chart',
      meta: { icon: 'icon-list', module: MODULES.Users },
      props: true,
      component: OrganizationChart,
      beforeEnter: ifAuthenticated
    }
  ]
}
