import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import { api } from '@/shared/api.js'

import { FORMCONTROLMODE, FORM_MODE } from '@/shared/constants.js'

let vm = new Vue()

import Store from '../store'

export default {
  mode (that) {
    if (!that) return FORM_MODE.READONLY

    const action = that.$route.params.action || that.action

    if (action == 'create') return that.$constants.FORM_MODE.CREATE
    if (action == 'edit') return that.$constants.FORM_MODE.EDIT
    if (action == 'view') return that.$constants.FORM_MODE.VIEW
    if (action == 'preview') return that.$constants.FORM_MODE.VIEW

    /*
     if (that.$route.params.action === "create" || that.action === "create")
      return that.$constants.FORM_MODE.CREATE;
        if (that.$route.params.action === "edit" || that.action === "edit")
          return that.$constants.FORM_MODE.EDIT;
    
        if (that.$route.params.action === "view" || that.action === "view")
          return that.$constants.FORM_MODE.VIEW;
    
        if (that.$route.params.action === "preview" || that.action === "preview")
          return that.$constants.FORM_MODE.VIEW;
          */
  },
  isCreateMode (that) {
    return this.mode(that) === FORM_MODE.CREATE
  },
  isViewMode (that) {
    return this.mode(that) === FORM_MODE.VIEW
  },
  isEditMode (that) {
    return this.mode(that) === FORM_MODE.EDIT
  },
  isReadOnlyMode (that) {
    return this.mode(that) === FORM_MODE.READONLY
  },
  yesNo: {
    no: { id: 0, label: 'No' },
    yes: { id: 1, label: 'Yes' },
    options: [
      { id: 0, label: 'No' },
      { id: 1, label: 'Yes' }
    ]
  },

  updateField (url, self, field, value) {
    let params = {}
    params['id'] = self.id
    params[field] = value

    //update local data values - it can be used in Edit action
    self.data[field] = value

    //if control in View mode then update database

    if (self.mode === FORMCONTROLMODE.VIEW)
      api
        .put(`${url}/${self.data.id}`, params)
        .then(response => {
          this.makeToastInfo(`${field}: ${response.message}`)

          self.controls[field].changed = false

          self.initialize()
        })
        .catch(response => {
          console.log(response)

          this.makeToastError(`${field}: ${response.message}`)
        })
  },

  //v2
  updateField2 (payload) {
    let params = {}
    params['id'] = payload.self.id
    params[payload.field] = payload.value

    //update local data values - it can be used in Edit action
    payload.self.data[payload.field] = payload.value

    //if control in View mode then update database

    if (payload.self.mode === FORMCONTROLMODE.VIEW)
      return api
        .put(`${payload.url}/${payload.self.data.id}`, params)
        .then(response => {
          this.makeToastInfo(`${payload.field}: ${response.message}`)

          payload.self.controls[payload.field].changed = false

          // payload.self.initialize();
        })
        .catch(response => {
          console.log(response)

          this.makeToastError(`${payload.field}: ${response.message}`)
        })
  },
  updateControlsState (_readonly) {
    for (var prop in this.controls) {
      if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
        this.controls[prop].readonly = _readonly
      }
    }
  },
  makeToast (title, message, variant) {
    vm.$bvToast.toast(message, {
      title: title,
      variant: variant,
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 5000,
      solid: true
    })
  },
  makeToastInfo (message) {
    return vm.$bvToast.toast(message, {
      title: 'Info',
      variant: 'primary',
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 5000,
      solid: true
    })
  },
  makeToastWarning (message) {
    return vm.$bvToast.toast(message, {
      title: 'Warning',
      variant: 'warning',
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 5000,
      solid: true
    })
  },
  makeToastError (message) {
    return vm.$bvToast.toast(message, {
      title: 'Error',
      variant: 'danger',
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 5000,
      solid: true
    })
  },
  showConfirmation (message) {
    return vm.$bvModal.msgBoxConfirm(
      [vm.$createElement('div', { domProps: { innerHTML: message } })],
      {
        title: 'Confirm action',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }
    )
  },
  msgBoxOk (message) {
    vm.$bvModal.msgBoxOk(message, {
      title: 'Message',
      size: 'md',
      buttonSize: 'md',
      okTitle: 'OK',
      bodyClass: 'p-10',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    })
  },
  testForm (_this) {
    let result = true

    for (let i = 0; i < _this.$children.length; i++) {
      if (
        result !== false &&
        _this.$children[i].$children &&
        _this.$children[i].$children.length > 0
      ) {
        result = this.testForm(_this.$children[i])

        if (result === false) return result
      }

      if (_this.$children[i].test) {
        if (!_this.$children[i].test()) {
          result = false
          // return result
        }
      }
    }

    return result
  },
  testForm2 (_this) {
    let result = true

    for (let i = 0; i < _this.$children.length; i++) {
      if (
        _this.$children[i].$children &&
        _this.$children[i].$children.length > 0
      ) {
        for (let j = 0; j < _this.$children[i].$children.length; j++) {
          if (_this.$children[i].$children[j].test) {
            if (!_this.$children[i].$children[j].test()) result = false
          }
        }
      } else if (_this.$children[i].test) {
        if (!_this.$children[i].test()) result = false
      }
    }

    return result
  },

  prepareDrillDownFilters (refFilteringPanel, accountName) {
    console.log(
      'form-helpers.prepareDrillDownFilters',
      refFilteringPanel,
      accountName
    )
    let payload = {
      datasetName: 'distribution-accounts-overview',
      //panelFilters: JSON.parse(JSON.stringify(refFilteringPanel.filters)),
      //selected: refFilteringPanel.selected
      panelFilters: [],
      selected: {}
    }
    if (
      refFilteringPanel &&
      refFilteringPanel.filters.find(f => f.name === 'period')
    ) {
      payload.panelFilters.push(
        JSON.parse(
          JSON.stringify(
            refFilteringPanel.filters.find(f => f.name === 'period')
          )
        )
      )
      payload.selected.period = refFilteringPanel.selected.period
    }

    payload.panelFilters.push({
      type: 'select',
      dataType: 'string',
      title: 'Account',
      tooltip: 'Full account name',
      name: 'account',
      trackby: 'id',
      label: 'label',
      multiple: false,
      options: [],
      selected: {},
      async: true,
      loading: false,
      startsWith: false
    })

    payload.selected.account = {
      id: undefined,
      label: accountName
    }

    //this.saveSelectedFilters(payload);

    console.log('form-helpers.prepareDrillDownFilters', JSON.stringify(payload))

    Store.dispatch('filteringPanel/saveSelectedFilters', payload)
  },

  prepareDrillDownFiltersWeeklyStats (refFilteringPanel, cellEvent) {
    console.log('prepareDrillDownFiltersWeeklyStats', JSON.stringify(cellEvent))
    let datasetName = ''
    if (cellEvent.name.toLowerCase().includes('activities')) {
      datasetName = 'activities'
    }
    if (cellEvent.name.toLowerCase().includes('tasks')) {
      datasetName = 'tasks'
    }

    let payload = {
      datasetName: datasetName,
      panelFilters: [],
      selected: {}
    }
    if (
      refFilteringPanel &&
      refFilteringPanel.filters.find(f => f.name === 'ws-period')
    ) {
      let p = refFilteringPanel.filters.find(f => f.name === 'ws-period')
      p = JSON.parse(JSON.stringify(p))

      if (datasetName == 'activities') {
        p.name = 'period'
        payload.panelFilters.push(p)
        payload.selected.period = JSON.parse(
          JSON.stringify(refFilteringPanel.selected['ws-period'])
        )
      }

      if (datasetName == 'tasks') {
        p.name = 'updated-date'
        payload.panelFilters.push(p)
        payload.selected['updated-date'] = JSON.parse(
          JSON.stringify(refFilteringPanel.selected['ws-period'])
        )
      }

      if (cellEvent.name === 'PastActivities') {
        payload.selected['period'].startDate = cellEvent['lws']
        payload.selected['period'].endDate = cellEvent['lwe']
      }
      if (cellEvent.name === 'CurrentActivities') {
        payload.selected['period'].startDate = cellEvent['tws']
        payload.selected['period'].endDate = cellEvent['twe']
      }

      if (cellEvent.name === 'PastTasks') {
        payload.selected['updated-date'].startDate = cellEvent['lws']
        payload.selected['updated-date'].endDate = cellEvent['lwe']
      }
      if (cellEvent.name === 'CurrentTasks') {
        payload.selected['updated-date'].startDate = cellEvent['tws']
        payload.selected['updated-date'].endDate = cellEvent['twe']
      }
    }

    //submitter
    //period
    if (datasetName == 'activities') {
      payload.panelFilters.push({
        type: 'select',
        dataType: 'string',
        tooltip: 'Submitter',
        title: 'Submitter',
        name: 'submitter',
        trackby: 'id',
        label: 'label',
        options: [],
        selected: {},
        multiple: true
      })

      payload.selected.submitter = []
      payload.selected.submitter.push({
        id: cellEvent['ID'],
        label: cellEvent['User name']
      })
    }

    if (datasetName == 'tasks') {
      payload.panelFilters.push({
        type: 'select',
        dataType: 'string',
        tooltip: 'Assigned To',
        title: 'Assigned To',
        name: 'assigned_to',
        trackby: 'id',
        label: 'label',
        options: [],
        selected: {},
        multiple: true
      })

      payload.selected.assigned_to = []
      payload.selected.assigned_to.push({
        id: cellEvent['ID'],
        label: cellEvent['User name']
      })

      payload.panelFilters.push({
        type: 'select',
        dataType: 'string',
        tooltip: 'Status',
        title: 'Status',
        name: 'status',
        trackby: 'id',
        label: 'label',
        options: [],
        selected: {},
        multiple: true
      })

      payload.selected.status = []

      if (cellEvent.name === 'PastTasks' || cellEvent.name === 'CurrentTasks') {
        payload.selected.status.push({
          id: '3',
          label: 'Completed'
        })
      }

      if (cellEvent.name === 'OpenTasks') {
        payload.selected['updated-date'].startDate = '2018-01-01'
        payload.selected['updated-date'].endDate = '2025-12-31'

        payload.selected.status.push({
          id: '1',
          label: 'Not Started'
        })
        payload.selected.status.push({
          id: '2',
          label: 'In Progress'
        })
        payload.selected.status.push({
          id: '4',
          label: 'Pending Input'
        })
        payload.selected.status.push({
          id: '6',
          label: 'Planned'
        })
      }
    }
    //this.saveSelectedFilters(payload);

    console.log(
      'form-helpers.prepareDrillDownFiltersWeeklyStats',
      JSON.stringify(payload)
    )

    Store.dispatch('filteringPanel/saveSelectedFilters', payload)
  }
}
