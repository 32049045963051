import { render, staticRenderFns } from "./TableWrapper.vue?vue&type=template&id=8d169674&scoped=true"
import script from "./TableWrapper.vue?vue&type=script&lang=js"
export * from "./TableWrapper.vue?vue&type=script&lang=js"
import style0 from "./TableWrapper.vue?vue&type=style&index=0&id=8d169674&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d169674",
  null
  
)

export default component.exports