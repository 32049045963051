import { ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

const AllReports = () => import("@/views/Reports/AllReports");
const ReportsMap = () => import("@/views/Reports/ReportsMap");

const ReportsNotifications = () =>
  import("@/views/Reports/ReportsNotifications");
const ReportsManager = () =>
  import("@/views/Reports/ReportsManager/ReportsManager");
const ReportsManagerSubmissionForm = () =>
  import("@/views/Reports/ReportsManager/ReportsManagerSubmissionForm");

export default {
  path: "",
  name: "Reports admins",
  meta: { icon: "icon-star" },
  component: {
    render(c) {
      return c("router-view");
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "/reports-admin/all-reports",
      name: "All reports",
      component: AllReports,
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list" }
    },
    {
      path: "/reports-admin/reports-overview",
      name: "Reports overview",
      component: ReportsMap,
      props: { mode: "overview" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list" }
    },
    {
      path: "/reports-admin/review-overview",
      name: "Review overview",
      component: ReportsMap,
      props: { mode: "review" },
      beforeEnter: ifAuthenticated,
      meta: { module: MODULES.Reports, icon: "icon-list" }
    },

    {
      path: "/reports-admin/reports-manager",
      name: "Reports manager",
      meta: { module: MODULES.ReportsManager, icon: "icon-list" },
      component: ReportsManager,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/reports-admin/reports-manager/:action/:id?",
      name: "Report category submission",
      meta: {
        module: MODULES.ReportsManager,
        icon: "icon-list",
        hideFromMenu: true
      },
      component: ReportsManagerSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/reports-admin/reports-notifications",
      name: "Reports notifications",
      meta: { module: MODULES.Reports, icon: "icon-list" },
      component: ReportsNotifications,
      props: true,
      beforeEnter: ifAuthenticated
    }
  ]
};
