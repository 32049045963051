var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_vm._l(_vm.customButtons, function (button, index) {
    return [button.visible && !button.menu ? _c('b-button', {
      key: "".concat(_vm.uid, "-").concat(index),
      staticClass: "m-1",
      attrs: {
        "variant": button.variant,
        "disabled": button.disabled || button.loading
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('custom-click', button.method);
        }
      }
    }, [button.loading ? _c('b-spinner', {
      attrs: {
        "small": "",
        "type": "grow"
      }
    }) : _vm._e(), !button.loading ? _c('font-awesome-icon', {
      attrs: {
        "icon": button.icon
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(button.text) + " ")], 1) : _vm._e(), button.visible && button.menu ? _c('b-dropdown', {
      key: "".concat(_vm.uid, "-").concat(index),
      staticClass: "m-1",
      attrs: {
        "variant": button.variant,
        "disabled": button.disabled,
        "right": "",
        "split": "",
        "text": button.text
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('custom-click', button.method);
        }
      }
    }, _vm._l(button.menu, function (item, index2) {
      return _c('b-dropdown-item', {
        key: "".concat(_vm.uid, "-").concat(index2),
        on: {
          "click": function click($event) {
            return _vm.$emit('custom-click', item.method);
          }
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1) : _vm._e()];
  }), _vm.visible('save') && !(_vm.mode === this.$constants.FORM_MODE.VIEW) && !(_vm.mode === this.$constants.FORM_MODE.READONLY) ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.loading.save || _vm.loading.saveAndView
    },
    on: {
      "click": _vm.save
    }
  }, [_vm.loading.save ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.loading.save ? _c('font-awesome-icon', {
    attrs: {
      "icon": "save"
    }
  }) : _vm._e(), _vm._v(" Save ")], 1) : _vm._e(), _vm.visible('saveAndView') && !(_vm.mode === this.$constants.FORM_MODE.VIEW) && !(_vm.mode === this.$constants.FORM_MODE.READONLY) ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.loading.save || _vm.loading.saveAndView
    },
    on: {
      "click": _vm.saveAndView
    }
  }, [_vm.loading.saveAndView ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.loading.saveAndView ? _c('font-awesome-icon', {
    attrs: {
      "icon": "save"
    }
  }) : _vm._e(), _vm._v(" Save & View ")], 1) : _vm._e(), _vm.visible('edit') && _vm.mode === this.$constants.FORM_MODE.VIEW && !(_vm.mode === this.$constants.FORM_MODE.READONLY) ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "pencil-alt"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e(), _vm.visible('back') ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.back();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "arrow-circle-left"
    }
  }), _vm._v(" Back ")], 1) : _vm._e(), _vm.visible('previous') ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": false
    },
    on: {
      "click": _vm.previous
    }
  }, [!_vm.loading.save ? _c('font-awesome-icon', {
    attrs: {
      "icon": "hand-point-left"
    }
  }) : _vm._e(), _vm._v(" Previous ")], 1) : _vm._e(), _vm.visible('next') ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": false
    },
    on: {
      "click": _vm.next
    }
  }, [!_vm.loading.save ? _c('font-awesome-icon', {
    attrs: {
      "icon": "hand-point-right"
    }
  }) : _vm._e(), _vm._v(" Next ")], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }