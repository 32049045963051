import { api } from "@/shared/api.js";

let state = {};

const getters = {};

const actions = {
  async getBlacklistedKeywords() {
    return await api.get(`chains/bl-keywords`);
  },
  async updateBlacklistedKeywords(context, data) {
    return await api.put(`chains/bl-keywords`, { data: data });
  },
  async addKeyword2Blacklist(context, keyword) {
    return await api.post(`chains/bl-keyword/add`, { keyword: keyword });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
