import { api } from "@/shared/api.js";
import moment from "moment";

let state = {
  isTimeTracking: false,
  updated: false,
  data: {},
  ip: ""
};

const getters = {
  isTimeTracking: state => state.isTimeTracking,
  timeTrackId: state => !!state.data.trackid || false,
  ip: state => !!state.ip || false
};

const actions = {
  fetchStatus: ({ commit, rootGetters }) => {
    const isAuthenticated = rootGetters["isAuthenticated"];

    if (!isAuthenticated) return Promise.resolve(false);

    //get client's time offset
    const clientOffsetMinutes = moment
      .tz(moment(), moment.tz.guess())
      .utcOffset();
    //const clientOffsetMinutes = moment.tz(moment(), 'US/Central').utcOffset()

    //console.log('clientOffsetMinutes',clientOffsetMinutes)

    return api
      .get(`timetracker/status/${clientOffsetMinutes}`)
      .then(response => {
        //console.log('timetracker/status',response)

        commit("STATUS_SUCCESS", response);
      })
      .catch(() => {
        commit("STATUS_ERROR");
      });
  },
  getIp: ({ commit, rootGetters }) => {
    const isAuthenticated = rootGetters["isAuthenticated"];

    if (!isAuthenticated) return Promise.resolve(false);

    return api
      .get("user/ip")
      .then(response => {
        commit("IP_SUCCESS", response);

        return response;
      })
      .catch(() => {
        commit("IP_ERROR");
      });
  },

  isHoliday: ({ commit }, data) => {
    return api
      .post("timetracker/isholiday", data)
      .then(response => {
        //20201112
        commit("ISHOLIDAY_SUCCESS", response);

        if (response == true) return true;
        else return false;
      })
      .catch(resp => {
        return resp.message;
        //commit('ISHOLIDAY_ERROR')
      });
  },
  addHoliday: ({ commit }, data) => {
    return api
      .post("timetracker/holiday", data)
      .then(response => {
        commit("ADDTIME_SUCCESS", response);

        return response;
      })
      .catch(error => {
        console.log("time tracker", error);

        commit("ADDTIME_ERROR", error);

        return error;
      });
  },
  addTime: ({ commit }, data) => {
    return api
      .post("timetracker/time", data)
      .then(response => {
        commit("ADDTIME_SUCCESS", response);

        return response;
      })
      .catch(error => {
        console.log(error);

        commit("ADDTIME_ERROR", error);

        return error;
      });
  },
  deleteTime: ({ commit }, data) => {
    return api
      .delete("timetracker/time", data)
      .then(response => {
        commit("DELTIME_SUCCESS", response);

        return response;
      })
      .catch(error => {
        console.log(error);

        commit("DELTIME_ERROR", error);

        return error;
      });
  },
  deleteTimeTrack: ({ commit }, trackid) => {
    return api
      .delete(`timetracker/time/${trackid}`)
      .then(response => {
        commit("DELTIME_SUCCESS", response);

        return response;
      })
      .catch(error => {
        console.log(error);

        commit("DELTIME_ERROR", error);

        return error;
      });
  },

  clockIn: ({ commit }, data) => {
    return api
      .post("timetracker/clockin", data)
      .then(response => {
        commit("CLOCKIN_SUCCESS", response);

        return response;
      })
      .catch(error => {
        console.log(error);

        commit("CLOCKIN_ERROR", error);

        return error;
      });
  },

  clockOut: ({ commit }, data) => {
    return api
      .post("timetracker/clockout", data)
      .then(response => {
        commit("CLOCKOUT_SUCCESS", response);

        return response;
      })
      .catch(error => {
        commit("CLOCKOUT_ERROR", error);

        return error;
      });
  },
  lunch: ({ commit }, data) => {
    return api
      .post("timetracker/lunch", data)
      .then(response => {
        commit("LUNCH_SUCCESS", response);

        return response;
      })
      .catch(error => {
        commit("LUNCH_ERROR", error);

        return error;
      });
  }
};

const mutations = {
  STATUS_SUCCESS: (state, data) => {
    state.data = data;
    state.isTimeTracking = data.trackaction == "1";
  },
  STATUS_ERROR: state => {
    //state.trackId = ''
    state.data = null;
    state.isTimeTracking = null;
  },
  IP_SUCCESS: (state, data) => {
    state.ip = data;
  },
  IP_ERROR: state => {
    state.ip = null;
  },

  ISHOLIDAY_SUCCESS: (state, data) => {
    state.isHoliday = data.isholiday == "1";
  },
  ISHOLIDAY_ERROR: state => {
    state.isHoliday = undefined;
  },
  CLOCKIN_SUCCESS: (state, data) => {
    state.data = data.data;
    state.isTimeTracking = true;
  },
  CLOCKIN_ERROR: () => {},
  CLOCKOUT_SUCCESS: (state, data) => {
    state.data = data.data;
    state.isTimeTracking = false;
  },
  CLOCKOUT_ERROR: () => {},
  LUNCH_SUCCESS: (state, data) => {
    state.data = data.data;
    state.data.trackid = data.data.breakreltrackid;
  },
  LUNCH_ERROR: () => {},
  ADDTIME_SUCCESS: (state, data) => {
    state.data = data.data;
    state.isTimeTracking = false;
  },
  ADDTIME_ERROR: () => {},
  DELTIME_SUCCESS: () => {},
  DELTIME_ERROR: () => {}
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
