import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import permissions from "./modules/permissions";
import profile from "./modules/profile";
import auth from "./modules/auth";
import filterPanel from "./modules/filterPanel";
//import filterPanelDynamic from "./modules/filterPanelDynamic";
import filteringPanel from "./modules/filteringPanel";
import drilldown from "./modules/drilldown";
import user from "./modules/user";

import modal from "./modules/modal";
import userReports from "./modules/user-reports";
import timeTracker from "./modules/time-tracker";
import router from "./modules/router";
import accountsMerge from "./modules/accounts-merge";
import chains from "./modules/chains";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const storage =
  process.env.VUE_APP_STORAGE === "session"
    ? window.sessionStorage
    : window.localStorage;

export default new Vuex.Store({
  modules: {
    permissions,
    profile,
    accountsMerge,
    auth,
    filterPanel,
    //filterPanelDynamic,
    filteringPanel,
    drilldown,
    user,
    modal,
    userReports,
    timeTracker,
    router,
    chains
  },
  strict: debug,
  plugins: [
    new VuexPersistence({
      rehydrated() {
        console.log("rehydrated called");
      },
      storage: storage
    }).plugin
  ]
});
