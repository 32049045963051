<template>
  <b-container fluid>
    <b-row align-v="center" class="mt-2">
      <b-col lg="1">
        Filter name:
      </b-col>
      <b-col sm="10" md="10" lg="2">
        <b-input
          ref="filter-name"
          v-model="data.name"
          @keyup="onFilterChange"
        />
      </b-col>

      <b-col lg="1">
        <div>
          Add new condition:
        </div>
      </b-col>
      <b-col sm="10" md="10" lg="2">
        <multiselect
          id="fields"
          v-model="selectedField"
          :options="fields"
          track-by="title"
          label="title"
          @input="onSelectInput"
          :show-labels="false"
        />
      </b-col>
      <b-col sm="1" md="1" lg="2">
        <b-button-group>
          <b-button
            variant="outline-dark"
            title="Add new condition"
            @click="addField"
            :disabled="!selectedField.name"
            size="sm"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <hr />
    <!-- <span v-if="selectedField.name">-->
    <span>
      <b-row>
        <b-col
          cols="3"
          v-for="(expression, index) in allExpressions.filter(e =>
            ['dimensions', 'measures'].includes(e.fieldName)
          )"
          :key="`base-${index}`"
        >
          <h5 v-if="expression.fieldName === 'dimensions'">
            Dimensions
          </h5>
          <h5 v-if="expression.fieldName === 'measures'">
            Measures
          </h5>
          <multiselect
            :ref="`${expression.fieldName}`"
            v-model="expression.rules[0].selectedValues"
            @input="onSelectInput"
            :multiple="true"
            track-by="id"
            label="label"
            :options="expression.options"
            :show-labels="false"
          />
        </b-col>
      </b-row>

      <b-row
        v-for="(expression, index) in allExpressions.filter(
          e => !['dimensions', 'measures'].includes(e.fieldName)
        )"
        :key="index"
      >
        <b-col>
          <h5 v-if="!['dimensions', 'measures'].includes(expression.fieldName)">
            <hr />
            <b-button
              size="sm"
              variant="outline-dark"
              title="Delete rule"
              @click="deleteExpression(expression)"
            >
              <font-awesome-icon icon="minus" />
            </b-button>
            {{ expression.fieldTitle }}
          </h5>

          <b-container
            fluid
            v-if="!['dimensions', 'measures'].includes(expression.fieldName)"
          >
            <b-row align-v="center">
              <b-col lg="1" />
              <b-col lg="1" class="font-weight-bold">Match</b-col>
              <b-col lg="2">
                <multiselect
                  v-model="expression.matchingLogic"
                  :options="matchingOptions"
                  @input="onSelectInput"
                  :allow-empty="false"
                  :show-labels="false"
                />
              </b-col>

              <b-col sm="10" md="10" lg="2" class="font-weight-bold"
                >of the following rules:</b-col
              >
              <b-col sm="1" md="1" lg="1">
                <b-button
                  size="sm"
                  variant="outline-dark"
                  title="Add rule"
                  @click="addRule(expression, index)"
                >
                  <font-awesome-icon icon="plus" />
                </b-button>
              </b-col>
            </b-row>

            <b-row
              class="mt-1"
              align-v="center"
              v-for="(rule, index2) in expression.rules"
              :key="index2"
            >
              <b-col lg="1" />
              <b-col lg="1" />
              <b-col sm="5" md="5" lg="2">
                <multiselect
                  v-model="rule.logicalOperator"
                  :options="logicalOperatorOptions[expression.fieldType]"
                  @input="onSelectInput"
                  :show-labels="false"
                />
              </b-col>
              <b-col sm="5" md="5" lg="2">
                <multiselect
                  v-if="expression.fieldType === 'territory'"
                  v-model="rule.selectedValue"
                  @input="onSelectInput"
                  :multiple="false"
                  track-by="id"
                  label="label"
                  :options="getExpressionOptions(expression, rule)"
                  :show-labels="false"
                />

                <multiselect
                  v-if="expression.fieldType === 'datetime'"
                  v-model="rule.selectedValue"
                  @input="onSelectInput"
                  :options="predefinedDateTimeValues"
                  :show-labels="false"
                />

                <b-input
                  v-if="
                    (expression.fieldType === 'string' &&
                      //show input in case of CONTAINS or DOES NOT CONTAIN operators and dropdown filter
                      [
                        LOGICAL_OPERATORS.STRING.CONTAINS,
                        LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN
                      ].includes(rule.logicalOperator)) ||
                      //show input also if no options provided, i.e used simple input filter
                      (!expression.options &&
                        ![
                          LOGICAL_OPERATORS.STRING.IS_EMPTY,
                          LOGICAL_OPERATORS.STRING.DOES_NOT_EMPTY
                        ].includes(rule.logicalOperator))
                  "
                  v-model="rule.selectedValue"
                  @keyup="onFilterChange"
                />

                <b-input
                  v-if="expression.fieldType === 'number'"
                  v-model="rule.selectedValue"
                  @keyup="onFilterChange"
                />

                <!-- grouped lists -->
                <multiselect
                  v-if="
                    expression.fieldType === 'string' &&
                      ![
                        LOGICAL_OPERATORS.STRING.CONTAINS,
                        LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN,
                        LOGICAL_OPERATORS.STRING.IS_EMPTY,
                        LOGICAL_OPERATORS.STRING.DOES_NOT_EMPTY
                      ].includes(rule.logicalOperator) &&
                      expression.group_values &&
                      expression.options
                  "
                  v-model="rule.selectedValues"
                  @input="onSelectInput"
                  :multiple="true"
                  :group-values="expression.group_values"
                  :group-label="expression.group_label"
                  track-by="id"
                  label="label"
                  :options="getExpressionOptions(expression, rule)"
                  :show-labels="false"
                />

                <!-- simple lists -->
                <!-- ASYNC DROPDOWNS -->

                <span v-if="expression.fieldName == 'account'">
                  <multiselect
                    :ref="`${expression.fieldName}`"
                    v-if="
                      expression.fieldType === 'string' &&
                        ![
                          LOGICAL_OPERATORS.STRING.CONTAINS,
                          LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN,
                          LOGICAL_OPERATORS.STRING.IS_EMPTY,
                          LOGICAL_OPERATORS.STRING.DOES_NOT_EMPTY
                        ].includes(rule.logicalOperator) &&
                        !expression.group_values &&
                        expression.options
                    "
                    v-model="rule.selectedValues"
                    @input="onRuleSelectInput(expression, rule)"
                    :multiple="true"
                    track-by="id"
                    label="label"
                    :options="accountsFilterOptions"
                    :show-labels="false"
                    :loading="false"
                    :placeholder="
                      `type to find ${expression.fieldTitle.toLowerCase()}`
                    "
                    @search-change="
                      $helpers.debounce(onSearchChange, 500)($event, expression)
                    "
                  >
                    <template slot="beforeList">
                      <b-button-group size="sm">
                        <b-button
                          :pressed.sync="rule.startsWith"
                          squared
                          size="sm"
                          :variant="'ligth'"
                        >
                          <font-awesome-icon
                            v-if="!rule.startsWith"
                            :icon="['far', 'square']"
                          />
                          <font-awesome-icon
                            v-if="rule.startsWith"
                            :icon="['far', 'check-square']"
                          />

                          Starts with...
                        </b-button>
                      </b-button-group>
                    </template>
                  </multiselect>
                </span>
                <span v-else>
                  <multiselect
                    v-if="
                      expression.fieldType === 'string' &&
                        ![
                          LOGICAL_OPERATORS.STRING.CONTAINS,
                          LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN,
                          LOGICAL_OPERATORS.STRING.IS_EMPTY,
                          LOGICAL_OPERATORS.STRING.DOES_NOT_EMPTY
                        ].includes(rule.logicalOperator) &&
                        !expression.group_values &&
                        expression.options
                    "
                    v-model="rule.selectedValues"
                    @input="onRuleSelectInput(expression, rule)"
                    :multiple="true"
                    track-by="id"
                    label="label"
                    :options="getExpressionOptions(expression, rule)"
                    :show-labels="false"
                  />
                </span>
              </b-col>
              <b-col sm="1" md="1" lg="2">
                <b-button-group>
                  <b-button
                    size="sm"
                    variant="outline-dark"
                    title="Delete rule"
                    @click="deleteRule(expression, index)"
                  >
                    <font-awesome-icon icon="minus" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </span>
    <hr />
    <b-row>
      <b-col lg="12">
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            :disabled="saveInProgress || !changed"
            @click="save()"
          >
            <b-spinner v-if="saveInProgress" small type="grow" />
            <font-awesome-icon v-if="!saveInProgress" icon="save" /> Save
          </b-button>

          <b-button variant="outline-dark" class="m-1" @click="close()">
            <font-awesome-icon icon="ban" /> Close
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import {
  FORM_MODE,
  LOGICAL_OPERATORS,
  DATETIME_TEMPLATES
} from "@/shared/constants.js";

import { getExpressionTranscription } from "./helpers";

export default {
  components: {
    Multiselect
  },
  props: {
    districts: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    dimensions: {
      type: Object,
      default: undefined
    },
    measures: {
      type: Object,
      default: undefined
    },
    datasetName: {
      type: String,
      default: "none"
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      mode: undefined,
      changed: false,
      FORM_MODE: FORM_MODE,
      LOGICAL_OPERATORS: LOGICAL_OPERATORS,
      presets: [],
      data: {
        id: "",
        name: "",
        shared: false,
        default: false
      },
      //filterName: '',
      accountsFilterOptions: [],
      selectedField: {},
      allExpressions: [
        {
          fieldName: "",
          fieldTitle: "",
          fieldType: "string",
          matchingLogic: "",
          rules: [
            {
              selectedValues: [],
              selectedValue: "",
              logicalOperator: "",
              values: []
            }
          ]
        }
      ],
      matchingOptions: ["All", "Any"],
      logicalOperatorOptions: {
        territory: [
          LOGICAL_OPERATORS.STRING.EQUALS,
          LOGICAL_OPERATORS.STRING.DOES_NOT_EQUAL
        ],

        string: [
          LOGICAL_OPERATORS.STRING.EQUALS,
          LOGICAL_OPERATORS.STRING.DOES_NOT_EQUAL,
          LOGICAL_OPERATORS.STRING.CONTAINS,
          LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN,
          LOGICAL_OPERATORS.STRING.IS_EMPTY,
          LOGICAL_OPERATORS.STRING.DOES_NOT_EMPTY
        ],
        number: [
          LOGICAL_OPERATORS.NUMBER.LESS_THAN,
          LOGICAL_OPERATORS.NUMBER.LESS_THAN_OR_EQUAL_TO,
          LOGICAL_OPERATORS.NUMBER.GREATER_THAN,
          LOGICAL_OPERATORS.NUMBER.GREATER_THAN_OR_EQUAL_TO,
          LOGICAL_OPERATORS.NUMBER.EQUALS,
          LOGICAL_OPERATORS.NUMBER.DOES_NOT_EQUAL
        ],
        datetime: [
          LOGICAL_OPERATORS.DATETIME.LESS_THAN,
          LOGICAL_OPERATORS.DATETIME.LESS_THAN_OR_EQUAL_TO,
          LOGICAL_OPERATORS.DATETIME.GREATER_THAN,
          LOGICAL_OPERATORS.DATETIME.GREATER_THAN_OR_EQUAL_TO,
          LOGICAL_OPERATORS.DATETIME.EQUALS,
          LOGICAL_OPERATORS.DATETIME.DOES_NOT_EQUAL
        ]
      },
      predefinedDateTimeValues: [
        DATETIME_TEMPLATES.TODAY,
        DATETIME_TEMPLATES.TODAY_1Y_AGO,
        DATETIME_TEMPLATES.TODAY_2Y_AGO,
        DATETIME_TEMPLATES.SEVEN_DAYS_BEFORE_TODAY,
        DATETIME_TEMPLATES.SEVEN_DAYS_AFTER_TODAY,
        DATETIME_TEMPLATES.DAYS_BEFORE_TODAY_45,
        DATETIME_TEMPLATES.START_OF_WEEK,
        DATETIME_TEMPLATES.START_OF_MONTH,
        DATETIME_TEMPLATES.START_OF_QUARTER,
        DATETIME_TEMPLATES.START_OF_YEAR,
        DATETIME_TEMPLATES.START_OF_LAST_WEEK,
        DATETIME_TEMPLATES.START_OF_LAST_MONTH,
        DATETIME_TEMPLATES.START_OF_LAST_QUARTER,
        DATETIME_TEMPLATES.START_OF_LAST_YEAR,
        DATETIME_TEMPLATES.END_OF_WEEK,
        DATETIME_TEMPLATES.END_OF_MONTH,
        DATETIME_TEMPLATES.END_OF_QUARTER,
        DATETIME_TEMPLATES.END_OF_YEAR,
        DATETIME_TEMPLATES.END_OF_LAST_WEEK,
        DATETIME_TEMPLATES.END_OF_LAST_MONTH,
        DATETIME_TEMPLATES.END_OF_LAST_QUARTER,
        DATETIME_TEMPLATES.END_OF_LAST_YEAR,
        DATETIME_TEMPLATES.FIVE_YEARS_BEFORE,
        DATETIME_TEMPLATES.TWO_YEARS_AFTER
      ],
      saveInProgress: false
    };
  },
  computed: {},
  async mounted() {
    this.allExpressions = [];
  },
  methods: {
    onSearchChange(query, filter) {
      let self = this;


      if (typeof this.debouncedSearchTimerId !== "undefined") {
        clearTimeout(this.debouncedSearchTimerId);
      }

      this.debouncedSearchTimerId = setTimeout(() => {
        if (query.trim() === "") return;

        self.$emit("async-search-exb", {
          query: query,
          filterName: filter.fieldName,
          startsWith: false,
          exbSelectRef: this.$refs[filter.fieldName]
        });
      }, 1000);
    },
    setExpressionOptions(payload) {


      //this.$refs[payload.filterName].options = payload.options;
      this.accountsFilterOptions = payload.options;
    },
    getExpressionOptions(e) {
      if (e.fieldName === "state" && this.districts) {
        let country = this.allExpressions.find(e => e.fieldName === "country");
        if (country && country.rules) {
          let options = this.$helpers.getDistinctArray(
            this.districts.filter(s =>
              country.rules[0].selectedValues
                .map(l => l.label)
                .includes(s.country)
            ),
            "state",
            "state",
            "id",
            "label"
          );

          return options;
        }
      }

      if (e.fieldName === "city" && this.districts) {
        let state = this.allExpressions.find(e => e.fieldName === "state");
        if (state && state.rules) {
          let options = this.$helpers.getDistinctArray(
            this.districts.filter(s =>
              state.rules[0].selectedValues.map(l => l.label).includes(s.state)
            ),
            "city",
            "city",
            "id",
            "label"
          );

          return options;
        }
      }


      let f = this.fields.find(f => f.name === e.fieldName);
      if (f) return f.options;
      else return [];
    },
    setValue(value) {
      this.data = value;

      if (JSON.stringify(value) === "{}") {
        this.data = {
          id: "",
          name: "",
          shared: false,
          default: false
        };
        this.selectedField = {};
        this.allExpressions = [];
        return;
      }

      this.allExpressions = JSON.parse(value.data);

      if (!this.allExpressions.length) this.allExpressions = [];

      //disable used fields
      this.disableFields(
        this.allExpressions.map(e => e.fieldName),
        true
      );
    },
    onSelectInput() {
      this.changed = true;
    },
    onRuleSelectInput() {
      this.changed = true;

      this.updateCountryFieldsState();
    },

    onFilterChange() {
      this.changed = true;
    },
    disableFields(_fields, _isDisabled) {
      this.fields.forEach(f => {
        f.$isDisabled = false;
      });

      this.fields
        .filter(f => _fields.includes(f.name))
        .forEach(f => {
          f.$isDisabled = _isDisabled;
        });

      this.updateCountryFieldsState();
    },
    updateCountryFieldsState() {
      //disable state if country is not selected
      let stateItem = this.fields.find(f => f.name === "state");
      let cityItem = this.fields.find(f => f.name === "city");

      stateItem
        ? !this.allExpressions.find(e => e.fieldName === "country")
          ? (stateItem.$isDisabled = true)
          : (stateItem.$isDisabled = false)
        : false;

      //disable city if state is not selected
      cityItem
        ? !this.allExpressions.find(e => e.fieldName === "state")
          ? (cityItem.$isDisabled = true)
          : (cityItem.$isDisabled = false)
        : false;
    },
    addField() {
      //disable selected option
      this.disableFields([this.selectedField.name], true);

      this.changed = true;
      let f = {
        fieldTitle: this.selectedField.title,
        fieldName: this.selectedField.name,
        fieldType: this.selectedField.dataType,
        group_values: this.selectedField.group_values,
        group_label: this.selectedField.group_label,
        options: this.selectedField.options,
        matchingLogic: "All",
        rules: [
          {
            logicalOperator: "",
            selectedValues: [],
            selectedValue: ""
          }
        ]
      };

      this.allExpressions.push(f);
    },
    addRule(expression) {
      console.log("addRule");
      this.changed = true;
      let rule = {
        logicalOperator: "",
        selectedValues: [],
        selectedValue: ""
      };

      expression.rules.push(rule);
    },
    deleteRule(expression, ruleIndex) {
      console.log("deleteRule");

      this.changed = true;
      expression.rules.splice(ruleIndex, 1);
    },
    deleteExpression(exp) {
      console.log("deleteExpression");
      this.changed = true;
      //this.allExpressions.splice(index, 1)
      this.allExpressions = this.allExpressions.filter(
        e => e.fieldName !== exp.fieldName
      );

      this.disableFields([exp.fieldName], false);
    },
    close() {
      this.mode = FORM_MODE.EDIT;
      this.changed = false;

      this.$emit("close");
    },
    save() {
      if (!this.data.name || this.data.name.trim() === "") {
        this.$form.makeToastError("Please specify filter name");
        return;
      }
      if (this.dimensions && this.measures) {
        //let d = this.$refs['dimensions']
        if (
          this.allExpressions.find(e => e.fieldName === "dimensions").rules[0]
            .selectedValues.length === 0 ||
          this.allExpressions.find(e => e.fieldName === "measures").rules[0]
            .selectedValues.length === 0
        ) {
          this.$form.makeToastError(
            "Please specify at least one dimension and measure"
          );
          return;
        }
      }

      let result = "";
      let self = this;

      //remove options to save data size
      this.allExpressions.forEach(e => {
        e.options = [];
      });
      result = JSON.stringify(this.allExpressions);

      let payload = {
        id: this.data.id,
        name: this.data.name,
        dataset: this.datasetName,
        data: result,
        shared: this.data.shared ? 1 : 0
      };

      //update local variable before sending in the event
      this.data.name = payload.name;
      this.data.data = payload.data;
      this.data.transcription = getExpressionTranscription(result, this);
      //if new filter then mark it as own
      this.data.isOwner = !this.data.id ? true : this.data.isOwner;
      this.data.visible = true;
      this.data.disabled = false;
      this.data.isUnfolded = false;

      let method = "",
        url = "";
      if (this.data.id) {
        method = "put";
        url = `custom-filters/${this.data.id}`;
      } else {
        method = "post";
        url = "custom-filters";
      }

      self.saveInProgress = true;

      this.$api[method](url, payload)
        .then(response => {
          self.changed = false;
          self.saveInProgress = false;

          self.data.id = response.id;

          self.$form.makeToastInfo(response.message);

          self.$emit("save", self.data);
        })
        .catch(e => {
          self.$form.makeToastError(e.message);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
