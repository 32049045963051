var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": _vm.model.name,
      "load-dictionaries": _vm.loadDictionaries,
      "show-labels": true,
      "hide-custom-filters": false,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dataTable.visible,
      expression: "dataTable.visible"
    }]
  }, [_c('b-col', [_c('table-wrapper', {
    ref: _vm.model.name,
    attrs: {
      "model": _vm.model,
      "columns": _vm.tableColumns,
      "cell-classes": _vm.cellClasses,
      "read-only": _vm.readOnly,
      "slots": _vm.slots
    },
    scopedSlots: _vm._u([_vm._l(_vm.slots, function (slotName) {
      return {
        key: slotName,
        fn: function fn(props) {
          return _vm._t(slotName, null, {
            "row": props.row
          });
        }
      };
    })], null, true)
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }